import React, { useEffect, useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { useAddOrUpdateAdminMutation } from "../../../../../utils/api/admin/admin.api";
import { Color } from "../../../../../utils/theme";
import { getAvatar } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import ChangePasswordModal from "../../../../modal/admin/ChangePasswordModal";
import UseCrudAdmin from "../CrudAdministrateur/useForm/UseCrudAdmin";
import AccountInfo from "../../../Agence/OptionAgence/OptionsStep/AccountInfo";

function MonCompteAdmin() {
	const [avatar, setAvatar] = useState<any>(null);
	const admin = useAppSelector((s) => s?.user?.user);
	// console.log({ admin });

	const { register, onSubmit, errors, isLoading } = UseCrudAdmin(admin);
	const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
		useAddOrUpdateAdminMutation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (done) {
			console.log("data", data);
			dispatch(onSetUser(data));
			setAvatar(null);
			Swal.fire({
				icon: "success",
				title: "Photo de profil modifiée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [done, isError]);
	const changeImage = (e: any) => {
		const file = e.target.files[0];
		setAvatar(URL.createObjectURL(file));
		let fd = new FormData();
		fd.append("avatar", file);
		sendData({ slug: admin?.slug, user_type: admin?.user_type, data: fd });
	};
	return (
		<div className="landing-page-container">
			<div className="admin-page-title-header-container">
				<div className="kanimmo-dash-locataire-param-main-page-container py-4">
					<section className="mb-3">
						<div className="row">
							<div className="col-md-12">
								<div className="d-flex justify-content-center align-items-center py-4 mb-4 kanimmo-user-avatar-container">
									<label
										htmlFor="input-test"
										className="input-testt"
									>
										<img
											src={
												avatar ||
												getAvatar(admin?.avatar)
											}
											alt="user avatar"
											className="kanimmo-dash-locataire-param-img-avatar"
										/>
										<div className="kanimmo-user-avatar-edit-hover">
											<BsCameraFill className="edit-avatar-camera-icon" />
										</div>
									</label>
									<input
										type="file"
										className="input-add-avatar"
										id="input-test"
										onChange={(e) => changeImage(e)}
									/>
								</div>
							</div>
						</div>
						{/* <div className="row">
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="nom"
										className="form-label form-label-modal-custom"
									>
										Nom
									</label>
									<input
										type="text"
										placeholder="Nom"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="nom"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="prenom"
										className="form-label form-label-modal-custom"
									>
										Prénom
									</label>
									<input
										type="text"
										placeholder="Prénom"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="prenom"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="email"
										className="form-label form-label-modal-custom"
									>
										Email
									</label>
									<input
										type="email"
										placeholder="test@gmail.com"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="email"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="tel"
										className="form-label form-label-modal-custom"
									>
										Téléphone
									</label>
									<input
										type="text"
										placeholder="77 000 00 00"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="tel"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="adress"
										className="form-label form-label-modal-custom"
									>
										Adresse
									</label>
									<input
										type="text"
										placeholder="Petit Mbao"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="adress"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label
										htmlFor="boite"
										className="form-label form-label-modal-custom"
									>
										Code postale
									</label>
									<input
										type="text"
										placeholder="17500"
										className="form-control form-control-modal-custom form-control-params-custom"
										id="boite"
									/>
								</div>
							</div>
						</div> */}
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-6">
									<Input
										type="text"
										label="Nom"
										id="nom"
										placeholder="Nom"
										required
										{...register("nom")}
										error={errors?.nom}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Prénom"
										id="prenom"
										placeholder="Prénom"
										required
										{...register("prenom")}
										error={errors?.prenom}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="email"
										label="Email"
										id="email"
										placeholder="Email"
										required
										{...register("email")}
										error={errors?.email}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Téléphone"
										id="phone"
										placeholder="Téléphone"
										required
										{...register("telephone")}
										error={errors?.telephone}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Adresse"
										id="adresse"
										placeholder="Adresse"
										{...register("adresse")}
										error={errors?.adresse}
									/>
								</div>
								<div className="col-md-6">
									<Input
										type="text"
										label="Code postale"
										id="boite_postale"
										placeholder="Code postale"
										{...register("code_postal")}
										error={errors?.code_postal}
									/>
								</div>
							</div>
							<div className="container-btn-modal row my-4">
								<div className="col-md-6 offset-md-6">
									<div className="flex-r gap-4">
										<BtnSubmit
											label="Enregistrer"
											isLoading={isLoading}
										/>
									</div>
								</div>
							</div>
						</form>
					</section>
				</div>
				<AccountInfo />
				<div className="kanimmo-dash-locataire-param-main-page-container py-4">
					<div className="d-flex justify-content-between">
						<div className="kanimmo-dash-locataire-param-password-title">
							Mot de passe
						</div>
						<button
							className="btn kanimmo-dash-locataire-parametre-header-button"
							data-bs-toggle="modal"
							data-bs-target="#ChangePasswordModal"
						>
							<FiEdit className="kanimmo-dash-locataire-parametre-header-icon" />{" "}
							Changer de mot de passe
						</button>
					</div>
					<ChangePasswordModal />
				</div>
			</div>
		</div>
	);
}

export default MonCompteAdmin;
