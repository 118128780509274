import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, getImage, onHide } from "../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  IRappel,
  RappelFormData,
} from "../../../../../utils/api/rappel/rappel.type";
import {
  useAddOrUpdateRappelMutation,
  useDeleteRappelMutation,
} from "../../../../../utils/api/rappel/rappel.api";

function UseAddOrUpdateRappel(modalId: string, rappel?: IRappel) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
    message: yup.string().label("Message"),
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<RappelFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [addOrUpdateRappel, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateRappelMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: rappel
          ? "Rappel modifiée avec succès !"
          : "Rappel ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!rappel) {
          reset();
          //   setPreviewImage(null);
          setMessage("");
        }
        onHide(modalId);
        // navigate("/agence/locataires");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

  const onSubmit = (data: any) => {
    if (!rappel) {
        data["created_by"] = user?.agence_id?.slug
          ? user?.agence_id?.id
          : user?.id;
    }
    
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data submitted", data);
    addOrUpdateRappel({ slug: rappel?.slug, data: fd });
  };

  const handleReset = () => {
    if (!rappel) {
      reset();
    }
  };

  useEffect(() => {
    if (rappel?.id) {
      const fields: (keyof RappelFormData)[] = [
        "titre",
        "message",
        // "image",
      ];

      for (let field of fields) {
        if (field === "message") {
          setMessage(rappel[field] || "");
        }
      }
    }
  }, [rappel]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
    setValue("message", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    // handelImageChange,
    // previewImage,
  };
}

export default UseAddOrUpdateRappel;

export function UseDeleteRappel(
  slug: string
  // modalID: string
) {
  const [deleteData] = useDeleteRappelMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce rappel ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: `Rappel supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          onHide("EnvoieSuppressionNoteModal");
        });
      }
    });
  };
  return onDelete;
}
