import React from "react";
import "./FrontFooter.css";
import ImgFoter from "../../../assets/appImages/kanimmofooterlogo.png";
import { NavLink } from "react-router-dom";
import Facebook from "../../../assets/icons/facebook-footer.png";
import Twitter from "../../../assets/icons/twitter-footer.png";
import Instagram from "../../../assets/icons/instagram-footer.png";
import Linkedin from "../../../assets/icons/linkedin-footer.png";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import LogoCB2 from "../../../assets/appImages/LogoCB2.png";
import NewsLetterForm from "./NewsLetterForm";
import { useGetRSociauxByVisiteurQuery, useGetRSociauxQuery } from "../../../utils/api/reseauSociaux/reseauSociaux.api";
import SignalerProbleme from "../../modal/user/SignalerProbleme";
import VersionChecker from "../../../versionChecker/VersionChecker";

const FrontFooter = () => {
  const { data: reseau, isLoading  } = useGetRSociauxByVisiteurQuery();
// console.log("Reseau", reseau);
  return (
    <footer className="component-front-footer">
      <div className="container-kan-immo-footer">
        <div className="body-footer">
          <div className="row">
            <div className="col-xl-3 col-lg-3 mb-5 col-md-6 footer-grid">
              <img src={ImgFoter} alt="image logo footer "  
               className="newlogofooter"
               style={{ width: '150px', height: '150px' }}
              />
              <div className="d-flex align-items-center my-4 media-container-mobile social-media-container">
                {!isLoading && reseau?.length
                  ? reseau?.map((itm) => (
                      <>
                        {itm?.type_reseau === "facebook" && (
                          <a
                            className="me-3 trans-0-2"
                            href={"https://" + itm?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={Facebook}
                              alt="Facebook"
                              className="icon-social-footer"
                            />
                          </a>
                        )}
                        {itm?.type_reseau === "linkedin" && (
                          <a
                            className="me-3 trans-0-2"
                            href={"https://" + itm?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={Linkedin}
                              alt="Instagram"
                              className="icon-social-footer"
                            />
                          </a>
                        )}
                        {itm?.type_reseau === "twitter" && (
                          <a
                            className="me-3 trans-0-2"
                            href={"https://" + itm?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={Twitter}
                              alt="Twitter"
                              className="icon-social-footer"
                            />
                          </a>
                        )}
                        {itm?.type_reseau === "instagram" && (
                          <a
                            className="me-3 trans-0-2"
                            href={"https://" + itm?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={Instagram}
                              alt="Facebook"
                              className="icon-social-footer"
                            />
                          </a>
                        )}
                      </>
                    ))
                  : null}
              </div>
              <p className="link-footer trans-0-2 d-block mb-2 mt-3">
                <span className="pe-2">
                  <MdOutlinePhone />
                </span>{" "}
                <a className="no-link" href={`tel:+221 77 185 25 25`}>
                  
                  +221 77 185 25 25
                </a>
              </p>
              <p className="link-footer trans-0-2 d-block mb-2">
                <span className="pe-2">
                  <MdOutlineEmail />
                </span>
                <a className="no-link" href={`mailto:contact@kanimmo.com`}>
                  contact@kanimmo.com
                </a>
              </p>
              <div className="version-view-container">
                {"v" + VersionChecker()}
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 mb-5 col-md-6 footer-grid">
              <p className="link-footer-navigation-title trans-0-2 d-block mb-3">
                À Propos
              </p>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/a-propos"
              >
                À propos
              </NavLink>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/nous-contacter"
              >
                Nous contacter
              </NavLink>
            </div>
            <div className="col-xl-2 col-lg-2 mb-5 col-md-6 footer-grid">
              <p className="link-footer-navigation-title trans-0-2 d-block mb-3">
                Services
              </p>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/services"
              >
                Nos services
              </NavLink>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/offres"
              >
                Nos offres
              </NavLink>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to={"/conditions-generales"}
              >
                Conditions générales
              </NavLink>
              {/* <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/"
              >
                Promotions et Offres
              </NavLink> */}
            </div>
            <div className="col-xl-2 col-lg-2 mb-5 col-md-6 footer-grid">
              <NavLink
                className="link-footer-navigation-title trans-0-2 d-block mb-3"
                to={"/aides"}
              >
                Customer Care
              </NavLink>
              <button
                data-bs-toggle="modal"
                data-bs-target="#SignalerProbleme"
                className="btn link-footer-navigation trans-0-2 d-block mb-3 p-0"
              >
                Signaler un problème
              </button>
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to={"/locataire/mon-loyer"}
              >
                Payer mon loyer
              </NavLink>
              {/* <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to="/faq"
              >
                FAQ
              </NavLink> */}
            </div>
            <div className="col-xl-3 col-lg-3 mb-5 col-md-6  footer-grid">
              <div className="d-flex flex-column">
                <p className="link-footer-navigation-title mb-4">Newsletter</p>
                <p className="link-footer-navigation mb-2">
                  Abonnez-vous à notre newsletter hebdomadaire et recevez des
                  mises à jour par e-mail.
                </p>
                <div className="form-newsletter mb-3">
                  <NewsLetterForm />
                </div>
                <p className="link-footer-navigation">Nous acceptons</p>
                <div className="bloc-carte-bleu mb-4">
                  <div>
                    <img
                      src={LogoCB2}
                      alt="Carte bleu"
                      className="carte-bleu-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="SignalerProbleme"
        aria-labelledby="SignalerProbleme"
        aria-hidden="true"
      >
        <SignalerProbleme />
      </div>
    </footer>
  );
};
export default FrontFooter;
