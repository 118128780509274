import React from "react";
import { NavLink } from "react-router-dom";
import Check from "../../../assets/icons/check.png";
import Modal from "react-modal";
import { IProprietaire } from "../../../utils/api/proprietaire/proprietaire.type";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
		border: "none",
		textAlign: "center",
	},
};

function ConfirmAddProprietaire({
	modalIsOpen,
	setIsOpen,
	proprietaire,
	item,
	redirectPath,
}: {
	modalIsOpen: boolean;
	setIsOpen: any;
	proprietaire?: string;
	item?: IProprietaire;
	redirectPath: string;
}) {
	const closeModal = () => {
		setIsOpen(false);
	};
	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="body-modal">
				<div className="container-img-icon-check-modal">
					<img src={Check} alt="Icon check" className="icon-check-modal" />
				</div>
				<div className="container-text-message-modal py-4">
					<p className="custom-text-message-modal">
						{proprietaire} {item ? "modifé" : "ajouté"} !!!
					</p>
					{!item && (
						<p className="custom-text-message-modal">
							Email envoyé avec succés
						</p>
					)}
				</div>
				<div className="container-btn-modal">
					<NavLink to={redirectPath} className="btn custom-btn-success-add">
						OK
					</NavLink>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmAddProprietaire;
