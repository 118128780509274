import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useAppSelector } from "../../../../redux/hooks";
import { IImmeubles } from "../../../../utils/api/immeubles/immeubles.type";
import {
  useGetBiensByProprietaireQNGPSBQuery,
  useGetImmeublesByProprietaireQNGPSBQuery,
  useGetProprietesByProprietaireQuery,
} from "../../../../utils/api/propriete/propriete.api";
import {
  IPropriete,
  TypeGerance,
  TypePropriete,
} from "../../../../utils/api/propriete/propriete.type";
import { IUser } from "../../../../utils/api/user/user.type";
import {
  formatMontant,
  formatPlurial,
  getImage,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import { DeletePropriete } from "../../Proprietaire/ProprietaireGererParLeProprietaire/Proprietes/ProprietesListe";
// import ProprieteItem from "../ProprietesAgence/ProprieteItem";
import { ProprieteCardSkeleton } from "../ProprietesAgence/ProprietesAgence";

const ListProprietesByProrietaire = ({ slug }: { slug: string }) => {
	const [pageImmo, setPageImmo] = useState(1);
	const [page, setPage] = useState(1);
	const [typePropriete, setTypePropriete] = useState<TypePropriete | null>();
	const [typeGerance, setTypeGerance] = useState<TypeGerance | undefined | string | null>();
	const handleFilterByTypeGerance = (e: React.FormEvent<HTMLSelectElement>): void => {
		if (e.currentTarget.value) {
			setTypeGerance(e.currentTarget.value);
		} else {
			setTypeGerance(null);
		}
	};
	const { data = { results: [], count: 0 }, isLoading } = useGetBiensByProprietaireQNGPSBQuery({
		slug,
		type_propriete: typePropriete,
		type_gerance: typeGerance,
	});
	const { data: immeubles = { results: [], count: 0 }, isLoading: loading } =
		useGetImmeublesByProprietaireQNGPSBQuery({
			slug,
			type_propriete: typePropriete,
			type_gerance: typeGerance,
		});

	useEffect(() => {
		console.log("data", immeubles);
	}, [loading]);
	return (
		<div className="col-right-detail-proprietaire">
			<div className="content-title-col-right gap-3">
				<h4 className="title-col-right-detail-proprietaire">Biens</h4>
				{/* <div className="container-filter-proprietes col-md-4">
					<select
						id={""}
						className="form-select filtre-select-propriete"
						onChange={handleFilterByTypeGerance}
					>
						<option value="" selected disabled>
							Type de Gérance
						</option>
						<option value={TypeGerance.partiel}>Gérés partiellement</option>
						<option value={TypeGerance.total}>Gérés totalement</option>
					</select>
				</div> */}
			</div>
			<div className="content-tabs-detail-propriete mt-4">
				<ul
					className="nav nav-tabs nav-tabs-detail-proprietaire d-flex gap-3"
					id="myTab"
					role="tablist"
				>
					<li className="nav-item" role="presentation">
						<button
							className="nav-link active navlink-tabs-register"
							id="villa-tab"
							data-bs-toggle="tab"
							data-bs-target="#villa"
							type="button"
							role="tab"
							aria-controls="villa"
							aria-selected="true"
							onClick={() => setTypePropriete(null)}
						>
							Villas et Appartements ({data?.count})
						</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className="nav-link navlink-tabs-register"
							id="appart-tab"
							data-bs-toggle="tab"
							data-bs-target="#appart"
							type="button"
							role="tab"
							aria-controls="appart"
							aria-selected="false"
							onClick={() => setTypePropriete(TypePropriete.immeuble)}
						>
							Immeubles ({immeubles?.count})
						</button>
					</li>
				</ul>
				<div className="tab-content mt-5" id="myTabContent">
					<div
						className="tab-pane fade show active"
						id="villa"
						role="tabpanel"
						aria-labelledby="villa-tab"
					>
						{!isLoading && (
							<div className="row">
								<ProprietesLists proprietes={data?.results} />
							</div>
						)}
						<div className="row">
							{isLoading &&
								[...Array(6)]?.map((item, i) => (
									<CardSkeleton key={i} />
								))}
							{!isLoading &&
								(data?.results?.length === 0 ||
									data?.results == undefined) && (
									<AlertInfo message="Aucune propriété disponible pour ce propriétaire" />
								)}
						</div>
						{data?.results && data?.results?.length > 0 && (
							<div className="flex-r mb-t">
								<Pagination
									page={page}
									total={data?.count}
									perPage={10}
									onPageChange={(page: number) => setPage(page)}
								/>
							</div>
						)}
					</div>
					<div
						className="tab-pane fade"
						id="appart"
						role="tabpanel"
						aria-labelledby="appart-tab"
					>
						{/* {!isLoading && (
              <div className="row">
                <ProprietesLists proprietes={data?.results} />
              </div>
            )} */}
						<div className="row">
							{!loading &&
								immeubles?.results?.map((item: IPropriete) => (
									<ProprieteItem item={item} />
								))}
							{loading &&
								[...Array(6)]?.map((item, i) => (
									<CardSkeleton key={i} />
								))}
							{!loading &&
								// proprietes &&
								(immeubles?.results?.length === 0 ||
									immeubles?.results == undefined) && (
									<AlertInfo message="Aucun immeuble disponible pour ce propriétaire" />
								)}
						</div>
						{immeubles?.results && immeubles?.results?.length > 0 && (
							<div className="flex-r mb-t">
								<Pagination
									page={page}
									total={immeubles?.count}
									perPage={10}
									onPageChange={(page: number) => setPage(page)}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const ProprietesLists = ({ proprietes }: { proprietes?: IPropriete[] }) => {
  return (
    <div className="row">
      {proprietes?.map((item) => (
        <ProprieteCardItem item={item} />
      ))}
    </div>
  );
};

const ProprieteCardItem = ({
  item,
}: {
  item: IPropriete | IImmeubles | any;
}) => {
  const { user } = useAppSelector((s) => s?.user);
  return (
    <div className="col-md-6 d-flex mb-3">
      <div className="detail-proprietes-card-item card">
        <div className="position-relative">
          <img
            src={getImage(item?.pictures[0]?.image)}
            className="card-img-top card-img-top-propriete"
            alt={item?.nom}
          />
        </div>
        <div className="content-prix-propriete-detail">
          <p className="text-prix-propriete-detail mb-0">
            {formatMontant(item?.prix)}
          </p>
        </div>
        <div className="card-body-detail-propriete">
          <div className="p-3 content-info-propriete-card">
            <p className="text-nom-propriete mb-1">{item?.nom}</p>
            <p className="card-text text-adresse-propriete mb-0">
              {item?.type_propriete + " - " + item?.adresse}
            </p>
          </div>
          <div className="flex-sb mt-3 b-t-1 align-items-center">
            <div className="b-rh-1 p-3-custom">
              <p className="text-description-propriete mb-0">
                {formatPlurial(item.nbre_chambres, "chambre")}
              </p>
            </div>
            <div className="b-rh-1 p-3-custom">
              <p className="text-description-propriete mb-0">
                {formatPlurial(item?.nbre_cuisines, "cuisine")}
              </p>
            </div>
            <div className="p-3-custom">
              <p className="text-description-propriete mb-0">
                {item?.nbre_salle_de_bains}
                {item?.nbre_salle_de_bains <= 1
                  ? " salle de bain"
                  : " salles de bains"}
              </p>
            </div>
            <div className="p-1">
              <NavLink
                to={getRoute(item, user)}
                className="inherit-link"
                state={item}
              >
                <AiFillEye />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListProprietesByProrietaire;

function getRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/proprietes/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/proprietes/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/proprietes/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/immeuble/${item?.slug}`;
    }
    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/immeuble/${item?.slug}`;
    }
    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/immeuble/${item?.slug}`;
    }
  }

  return route;
}

function ProprieteItem({ item }: { item: IPropriete }) {
  const user = useAppSelector((s) => s.user.user as IUser);
  return (
    <div className="col-md-6 d-flex mb-3" key={item?.slug}>
      <div className="proprietes-card-item card">
        <Link to={getRoute(item, user)} className="inherit-link" state={item}>
          <div className="card-img-top-propriete-container">
            <img
              src={getImage(item?.pictures[0]?.image)}
              className="card-img-top card-img-top-propriete"
              alt={item?.nom}
            />
          </div>
          <div className="card-body pb-0 mb-0">
            <p className="card-text text-adresse-propriete mb-1">
              {item?.type_propriete + " - " + item?.adresse}
            </p>
            <p className="text-nom-propriete mb-0">{item?.nom}</p>
            {item?.type_propriete !== "immeuble" ? (
              <div className="flex-sb mt-2">
                <div>
                  <p className="text-description-propriete">
                    {formatPlurial(item.nbre_chambres, "chambre")}
                  </p>
                </div>
                <div>
                  <p className="text-description-propriete">
                    {formatPlurial(item?.nbre_cuisines, "cuisine")}
                  </p>
                </div>
                <div>
                  <p className="text-description-propriete">
                    {formatPlurial(item?.nbre_salle_de_bains, "salle de bain")}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex-sb mt-2">
                <div>
                  <p className="text-description-propriete">
                    {formatPlurial(item?.nbre_appart, "appartement")}
                  </p>
                </div>
              </div>
            )}
            {item?.type_propriete !== "immeuble" && (
              <div className="flex-sb">
                <div className="content-prix-propriete">
                  <p className="text-prix-propriete">
                    {formatMontant(item?.prix)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Link>
        <div className="content-edit-propriete d-flex gap-3 flex-r pb-2 pe-2">
          <Link
            to={getEditRoute(item, user)}
            className="btn btn-edit-propriete"
            state={item}
          >
            <MdModeEditOutline />
          </Link>
          <DeletePropriete item={item} />
        </div>
      </div>
    </div>
  );
}

function getEditRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireOwner(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireOwner(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete-immeuble/${item?.slug}`;
    }
  }

  return route;
}

const CardSkeleton = () => {
  return (
    <div className="col-md-6 d-flex mb-3">
      <div className="proprietes-card-item card">
        <VolkenoSkeleton variant="rect" height="316px" width="100%" />
        <div className="card-body pb-0 mb-0">
          <VolkenoSkeleton variant="rect" height={10} width="100%" />
          <VolkenoSkeleton variant="rect" height={13} width="100%" />
          <div className="flex-sb mt-2">
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={45} />
            </div>
          </div>
          <VolkenoSkeleton variant="rect" height={12} width="30%" />
        </div>
        <div className="flex-r pe-2">
          <VolkenoSkeleton variant="circle" height="20px" width="20px" />
        </div>
      </div>
    </div>
  );
};
