import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useAppSelector } from "../../../../redux/hooks";
import { useDeleteImmeublesMutation, useGetImmeublesByUserQuery, useGetImmeublesQuery } from "../../../../utils/api/immeubles/immeubles.api";
import { IImmeubles, ImmeublesFormData } from "../../../../utils/api/immeubles/immeubles.type";
import { useDelete } from "../../../../utils/helpers";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import AjouterImmeubleModal from "../../../modal/agence/AjouterImmeubleModal";
import ImmeubleAgenceSkeleton from "./ImmeubleAgenceSkeleton";

function ImmeubleAgenceTable({name}: {name?: string | null}) {
    const { user } = useAppSelector((s) => s?.user);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { data = { immeubles: [] }, isLoading } = useGetImmeublesByUserQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
      page: page,
      limit: perPage,
      name,
    });
  // console.log('immeuble list', data)
  
    const actionFormatter: any = (cell: any, row: any) => {
        return (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#AjouterImmeubleModal${row?.slug}`}
            >
              <MdEdit />
            </button>
            <DeleteImmeubles item={row} />
    
            <div
              className="modal fade"
              id={`AjouterImmeubleModal${row?.slug}`}
              aria-labelledby={`#AjouterImmeubleModal${row?.slug}Label`}
              aria-hidden="true"
            >
              <AjouterImmeubleModal immeuble={row} modalId={`AjouterImmeubleModal${row?.slug}`} />
            </div>
          </div>
        );
      };


      const columns = [
        {
          dataField: "nom",
          text: "Nom",
        },
        {
          dataField: "adresse",
          text: "Adresse",
          style: { textAlign: "left" },
        },
        {
          dataField: "nb_appart",
          text: "Nombre d'appartement",
          style: { textAlign: "left" },
        },
        {
            dataField: "pays",
            text: "Pays",
            style: { textAlign: "left" },
        },
        {
            dataField: "region",
            text: "Région",
            style: { textAlign: "left" },
        },
        {
          dataField: "slug",
          text: "Actions",
          style: { textAlign: "center" },
          formatter: (cell: any, row: any) => actionFormatter(cell, row),
          headerStyle: () => {
            return { width: "80px", whiteSpace: "normal" };
          },
        },
      ];
    
      return (
        <>
          {isLoading && <ImmeubleAgenceSkeleton />}
          {!isLoading && data?.immeubles && (
            <>
              <BootstrapTable
                keyField="id"
                data={data?.immeubles}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive
                wrapperClasses="table-responsive locataire-table"
                noDataIndication={() => <AlertInfo message="Pas de données" />}
                rowStyle={{ cursor: "pointer" }}
              />
              <CustomPagination
                nbPages={data?.immeubles?.length}
                page={page}
                perPage={perPage}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
              />
            </>
          )}
        </>
      );
}

export default ImmeubleAgenceTable

export function DeleteImmeubles({ item }: { item: IImmeubles }) {
    const [deleteItem, { isSuccess, isError, error }] =
      useDeleteImmeublesMutation();
    const onDelete = useDelete<ImmeublesFormData>({
      item,
      deleteItem,
      isSuccess,
      isError,
      error,
      question: "cet immeuble?",
      successMessage: "Immeuble supprimé",
    });
    return (
      <button
        className="btn btn-action-administratif-proprietaire with-tooltip"
        data-tooltip-content="Supprimer"
        onClick={onDelete}
      >
        <IoMdTrash />
      </button>
    );
  }