/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillEye } from "react-icons/ai";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetComptabiliteByAgenceBySlugQuery,
} from "../../../../utils/api/agence/agence.api";
import {
  formatMontant,
  formattedDate,
  getName,
} from "../../../../utils/Utils";
import EntreeComptabiliteTableSkeleton from "./EntreeComptabiliteTableSkeleton";
import paginationFactory from "react-bootstrap-table2-paginator";
import DetailsEntreeModal from "../../../modal/agence/DetailsEntreeModal";
import { MdEdit } from "react-icons/md";
import AddEntreeModal from "../../../TableauDebord/Agence/ComptabiliteAgence/AddEntreeModal";
import { IoMdTrash } from "react-icons/io";
import { useDelete } from "../../../../utils/helpers";
import { IEntree } from "../../../../utils/api/entree/entree.type";
import {
  useDeleteEntreeMutation,
} from "../../../../utils/api/entree/entree.api";



function EntreeComptabiliteTable() {

  const { user } = useAppSelector((s) => s?.user);

  const { data: compta = { entrees: [] }, isLoading } =
    useGetComptabiliteByAgenceBySlugQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    });

  useEffect(() => {
    // console.log(compta);
  }, [isLoading]);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          {!user?.a_un_comptable && (
            <div className="container-btn-action-icon-modal">
              <button
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Modifier"
                data-bs-toggle="modal"
                data-bs-target={`#EditEntreeModal${row?.slug}`}
              >
                <MdEdit />
              </button>
            </div>
          )}

          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#DetailsEntreeModal${row?.slug}`}
            >
              <AiFillEye />
            </button>
            <DeleteEntree item={row} />
          </div>
        </div>
        <div
          className="modal fade"
          id={`EditEntreeModal${row?.slug}`}
          aria-labelledby="AddEntreeModalLabel"
          aria-hidden="true"
        >
          <AddEntreeModal
            modalId={`EditEntreeModal${row?.slug}`}
            entree={row}
          />
        </div>
        <div
          className="modal fade"
          id={`DetailsEntreeModal${row?.slug}`}
          aria-labelledby={`DetailsEntreeModal${row?.slug}Label`}
          aria-hidden="true"
        >
          <DetailsEntreeModal
            item={row}
            modalId={`DetailsEntreeModal${row?.slug}`}
            entree
          />
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "numero",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => formattedDate(cell),
    },
    {
      dataField: "fullname",
      text: "Client",
      formatter: (cell: any, row: any) => getName(row?.locataire || row?.occupant),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: any, row: any) => formatMontant(cell),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <EntreeComptabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={compta?.entrees?.slice()?.sort((a, b) => b?.id - a?.id)}
            data={compta?.entrees?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée disponible"}
            pagination={paginationFactory()}
          />
        </>
      )}
    </>
  );
}

export default EntreeComptabiliteTable;

export function DeleteEntree({ item }: { item: IEntree }) {
  const [deleteItem, { isSuccess, isError, error }] = useDeleteEntreeMutation();
  const onDelete = useDelete<IEntree>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette dépense?",
    successMessage: "dépense supprimée",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}