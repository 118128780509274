import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetBiensByProprietaireQuery } from "../../../../../utils/api/propriete/propriete.api";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { IUser } from "../../../../../utils/api/user/user.type";
import {
  formatMontant,
  formatPlurial,
  getImage,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import { ProprieteCardSkeleton } from "../../../Agence/ProprietesAgence/ProprietesAgence";
import { UseDeletePropriete } from "./requestPropriete/UseCrudPropriete";

function ProprietesListe({
  name,
  type_propriete,
}: {
  name?: any;
  type_propriete?: any;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const { data, isLoading } = useGetBiensByProprietaireQuery({
    slug: user?.proprietaire_id?.slug
      ? user?.proprietaire_id?.slug
      : user?.slug,
    name,
    type_propriete,
    page: page,
    limit: perPage,
  });

  return (
    <div className="row">
      {!isLoading &&
        data?.results?.map((item) => (
          <div className="col-lg-4 col-md-4 mb-3 d-flex" key={item.slug}>
            <div className="proprietes-card-item card">
              <div className="card-img-top-propriete-container">
                <Link
                  to={getRoute(item, user)}
                  className="inherit-link"
                  state={item}
                >
                  <img
                    src={getImage(item?.pictures[0]?.image)}
                    className="card-img-top card-img-top-propriete"
                    alt={item.nom}
                  />
                </Link>
              </div>
              <div className="card-body pb-0 mb-0">
                <p className="card-text text-adresse-propriete mb-1">
                  {item.type_propriete + " - " + item.adresse}
                </p>
                <p className="text-nom-propriete mb-0">{item.nom}</p>
                <div className="flex-sb mt-2">
                  <div>
                    <p className="text-description-propriete">
                      {formatPlurial(item.nbre_chambres, "chambre")}
                    </p>
                  </div>
                  <div>
                    <p className="text-description-propriete">
                      {formatPlurial(item?.nbre_cuisines, "cuisine")}
                    </p>
                  </div>
                  <div>
                    <p className="text-description-propriete">
                      {item.nbre_salle_de_bains}
                      {item?.nbre_salle_de_bains <= 1
                        ? " salle de bain"
                        : " salles de bains"}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="content-prix-propriete">
                    <p className="text-prix-propriete">
                      {formatMontant(item.prix)}
                    </p>
                  </div>
                  <div className="d-flex gap-3">
                    <Link
                      to={getEditRoute(item, user)}
                      className="btn kanimmo-proprietes-list-btn-edit"
                      state={item}
                    >
                      <MdEdit />
                    </Link>
                    <DeletePropriete item={item} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {isLoading &&
        [...Array(6)]?.map((item, i) => <ProprieteCardSkeleton key={i} />)}
      {!isLoading && data?.results && data?.results?.length === 0 && (
        <AlertInfo message="Aucune propriété trouvée" />
      )}

      <div className="flex-r">
        <Pagination
          page={page}
          total={data?.count}
          perPage={perPage}
          onPageChange={(page: number) => setPage(page)}
        />
      </div>
    </div>
  );
}

export default ProprietesListe;

export function DeletePropriete({ item }: { item: IPropriete }) {
  const onDelete = UseDeletePropriete(item?.slug, item);
  return (
    <button
      className="btn kanimmo-proprietes-list-btn-supprimer"
      onClick={onDelete}
    >
      <FiTrash2 />
    </button>
  );
}

function getRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/proprietes/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/proprietes/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/proprietes/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (
      isAgence(user) ||
      isAgenceAgent(user) ||
      isAgenceJuriste(user) ||
      isAgenceMarketeur(user)
    ) {
      route = `/agence/immeuble/${item?.slug}`;
    }
    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/immeuble/${item?.slug}`;
    }
    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/immeuble/${item?.slug}`;
    }
  }

  return route;
}

function getEditRoute(item: IPropriete, user: IUser) {
  let route = "/";
  if (
    item?.type_propriete === "villa" ||
    item?.type_propriete === "appartement"
  ) {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete/${item?.slug}`;
    }
  }

  if (item?.type_propriete === "immeuble") {
    if (user?.user_type === "agence") {
      route = `/agence/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireOwner(user) || isProprioJuriste(user)) {
      route = `/proprietaire/gerer-par-le-proprietaire/modifier-propriete-immeuble/${item?.slug}`;
    }

    if (isProprietaireAgence(user)) {
      route = `/proprietes/gerer-par-agence/modifier-propriete-immeuble/${item?.slug}`;
    }
  }

  return route;
}
