import React from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import AjouterFactureModal from "../../../../modal/agence/AjouterFactureModal";
import CompteRenduTable from "../../../../tables/agence/Administratif/ConteRendu/CompteRenduTable";
import { useGetCompteRenduAgenceQuery} from "../../../../../utils/api/comteRendu/conterendu.api"
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";





function CompteRenduDeGestion(){

  const { user } = useAppSelector((s) => s?.user);
  console.log("this user",user);
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data = { results: [], count: 0 }, isLoading } = useGetCompteRenduAgenceQuery(user.slug);

  console.log("le user slug", user?.slug);
	const word = searchParams.get("name");
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				name: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
	return (
    <div className="bg-white p-3">
      <div className="locataire-requete-filtre-container flex-sb my-4">
        <form className="row g-3 locataire-requete-filtre-form">
          <div className="col-auto">
            <label
              htmlFor="filtreLogement"
              className="locataire-requete-filtre-label"
            >
              Filtrer 
            </label>
          </div>
          <div className="col-auto">
            <input
              type="text"
              className="form-control locataire-requete-filtre-input"
              id="filtreLogement"
              placeholder="Entrer un nom"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleFilterByName(e)
              }
            />
          </div>
        </form>
        {/* <button
          className="btn btn-see-more"
          data-bs-toggle="modal"
          data-bs-target="#AjouterFactureModal"
        >
          <AiFillPlusSquare style={{ fontSize: 22 }} />
          <span className="ps-2">Envoyer une facture</span>
        </button> */}
      </div>
      <div>
        <CompteRenduTable
          data={data}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
        />
      </div>
      <AjouterFactureModal  />
    </div>
  );
}

export default CompteRenduDeGestion; 