import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoIosSend, IoIosTrash } from "react-icons/io";
import { MdOutlineModeEdit } from "react-icons/md";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import {
  useDeleteAnnonceMutation,
  useGetAnnoncesByUserBySlugQuery,
} from "../../../../../utils/api/annonces/annonce.api";
import { IAnnonce } from "../../../../../utils/api/annonces/annonce.type";
import { useDelete } from "../../../../../utils/helpers";
import {
  createMarkup,
  getImage,
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
} from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import AjouterAnnonceModal from "../../../../modal/admin/AjouterAnnonceModal";
import AjouterNoteModal from "../../../../modal/admin/AjouterNoteModal";
import EnvoieSuppressionNoteModal from "../../../../modal/agence/EnvoieSuppressionNoteModal";
import NoteSkeleton from "../../../../Skeleton/NoteSkeleton";

function NoteAnnonce({
  AgenceSlug,
  is_Agence,
}: {
  AgenceSlug: string;
  is_Agence: boolean;
}) {
  const {
    annonces,
    handleFilterDataByType,
    item,
    setItem,
    data,
    isLoading,
    perPage,
    setPage,
    page,
    cancelFilter,
    type,
  } = useAnnonces();  

  return (
    <div className="bg-white py-3 px-1">
      <div className="locataire-requete-filtre-container my-4">
        <div className="custom-btn-filter-container my-4">
          <button
            className={`custom-btn-filter ${type === null && "active"}`}
            onClick={cancelFilter}
          >
            Tout
          </button>
          <button
            className={`custom-btn-filter ${type === "note" && "active"}`}
            onClick={() => handleFilterDataByType("note")}
          >
            <div className="custom-card-type-note"></div>
            Note
          </button>
          <button
            className={`custom-btn-filter ${type === "annonce" && "active"}`}
            onClick={() => handleFilterDataByType("annonce")}
          >
            <div className="custom-card-type-annonce"></div>
            Annonce
          </button>
        </div>
        <div className="row">
          {!!isLoading && <NoteSkeleton />}
          {!isLoading &&
            (annonces && annonces?.length > 0 ? (
              annonces?.map((item: IAnnonce) => (
                <div className="col-md-3 mb-3" key={item?.id}>
                  <div style={{ position: "relative" }}>
                    <div
                      className="card custom-card-container"
                      data-bs-toggle="modal"
                      data-bs-target="#EnvoieSuppressionNoteModal"
                      onClick={() => setItem(item)}
                    >
                      <div className="custom-card-date">
                        {moment(item?.created_at).format("LL")}
                      </div>
                      <div className="custom-card-titre-container mb-3">
                        {item?.type === "note" ? (
                          <div className="custom-card-type-note"></div>
                        ) : (
                          <div className="custom-card-type-annonce"></div>
                        )}
                        <div className="custom-card-titre">{item?.titre}</div>
                      </div>
                      {item?.type === "note" && item?.content && (
                        <div
                          className="mb-3 custom-card-description custom-envoie-note-description"
                          dangerouslySetInnerHTML={createMarkup(item?.content)}
                        />
                      )}
                      {item?.type === "annonce" && item?.image && (
                        <img
                          src={getImage(item?.image)}
                          alt="image annonce"
                          className="custom-img-annonce"
                        />
                      )}
                    </div>
                    <div className="custom-btn-action-container">
                      <button
                        className="custom-btn-action-send-note"
                        data-bs-toggle="modal"
                        data-bs-target={
                          item?.type === "note"
                            ? `#ModifNoteModal${item?.id}`
                            : `#ModifAnnonceModal${item?.id}`
                        }
                      >
                        <MdOutlineModeEdit className="custom-icon-action" />
                      </button>
                      <DeleteAnnonce item={item} />
                      <button className="custom-btn-action-send-note">
                        <IoIosSend className="custom-icon-action" />
                      </button>
                    </div>
                    <div
                      className="modal fade"
                      id={`ModifNoteModal${item?.id}`}
                      aria-labelledby="ModifNoteModalLabel"
                      aria-hidden="true"
                    >
                      <AjouterNoteModal
                        modalId={`ModifNoteModal${item?.id}`}
                        note={item}
                      />
                    </div>
                    <div
                      className="modal fade"
                      id={`ModifAnnonceModal${item?.id}`}
                      aria-labelledby="ModifAnnonceModalLabel"
                      aria-hidden="true"
                    >
                      <AjouterAnnonceModal
                        modalId={`ModifAnnonceModal${item?.id}`}
                        annonce={item}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune élement trouvé" />
            ))}
          <div
            className="modal fade"
            id="EnvoieSuppressionNoteModal"
            aria-labelledby="EnvoieSuppressionNoteModalLabel"
            aria-hidden="true"
          >
            <EnvoieSuppressionNoteModal
              item={item}
              modalId={`EnvoieSuppressionNoteModal${item?.id}`}
            />
          </div>
        </div>
        {annonces && annonces?.length > perPage && (
          <div className="flex-r">
            <Pagination
              page={page}
              total={data?.count}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const useAnnonces = () => {
  const [item, setItem] = useState<IAnnonce>();
  const [type, setType] = useState<IAnnonce["type"] | null>(null);
  const [annonces, setAnnonces] = useState<IAnnonce[]>();
  const [page, setPage] = useState(1);
  const perPage = 12;

  const { user } = useAppSelector((s) => s?.user);
  const { data: ListeAgence } = useGetAgencesQuery({});
  const AgenceSlug = ListeAgence?.results
    ?.filter((item) => item?.id === user?.agence_id)
    ?.map((itm) => itm?.slug);
  const is_Agence = useAppSelector((s) => isAgence(s.user?.user));

  const agence = useAppSelector((s) => s.user.user);
  const { data = { results: [] }, isLoading } = useGetAnnoncesByUserBySlugQuery(
    {
      slug: is_Agence
        ? agence?.slug
        : isAgenceAgent(user) ||
          isAgenceJuriste(user) ||
          isAgenceMarketeur(user)
        ? user?.agence_id?.slug
        : AgenceSlug && AgenceSlug[0],
      page,
      limit: perPage,
    }
  );
  useEffect(() => {
    if (data?.results) {
      setAnnonces(data?.results);
    }
  }, [data]);
  
  const handleFilterDataByType = (type: IAnnonce["type"] | null) => {
    const filteredAnnonces = data?.results?.filter(
      (item: IAnnonce) => item?.type === type
    );
    setAnnonces(filteredAnnonces);
    setType(type);
  };
  const cancelFilter = () => {
    setAnnonces(data?.results);
    setType(null);
  };

  return {
    item,
    setItem,
    annonces,
    handleFilterDataByType,
    setPage,
    isLoading,
    data,
    perPage,
    page,
    cancelFilter,
    type,
  };
};

export function DeleteAnnonce({ item }: { item: IAnnonce }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteAnnonceMutation();
  const onDelete = useDelete<IAnnonce>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: item?.type === "annonce" ? "cette annonce" : "cette note",
    successMessage:
      item?.type === "annonce" ? "Annonce supprimé" : "Note supprimé",
  });
  return (
    <button className="custom-btn-action-trash-note" onClick={onDelete}>
      <IoIosTrash className="custom-icon-action" />
    </button>
  );
}

export default NoteAnnonce;
