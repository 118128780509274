import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useLocataireFromLocation } from "../../../../../../utils/api/locataire/locataire.api";
import { useGetProprieteProprietaireWithoutImmeubleQuery } from "../../../../../../utils/api/propriete/propriete.api";
import { BtnSubmit } from "../../../../../common/Button";
import ErrorMessage from "../../../../../common/ErrorMessage";
import AgenceDocumentContratModal from "../../../../../modal/agence/AgenceDocumentContratModal";
import useAddOrUpdateLocataire from "./useCrudLocataire";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function AddOrUpdateLocataire() {
  const [itemState, findBySlug] = useLocataireFromLocation();

  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handelAvatarChange,
    control,
    handleChangePropriete,
    option,
    isChecked,
    handleChecked,
    isCheckedCaution,
    handleCheckedCaution,
  } = useAddOrUpdateLocataire(itemState);

  const animatedComponents = makeAnimated();

  const { user } = useAppSelector((s) => s.user);
  const { data = [] } = useGetProprieteProprietaireWithoutImmeubleQuery({
    slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
  });

  const navigate = useNavigate();

  const [showAutre, setShowAutre] = useState(false);
  const handleChangeCaution = (e: any) => {
    if (e.target.value) {
      if (e.target.value === "true") {
        setShowAutre(true);
      } else {
        setShowAutre(false);
      }
    }
  };
  useEffect(() => {
    if (itemState) {
      setShowAutre(true);
    }
  }, [itemState]);
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            <AiOutlineArrowLeft
              className=" no-link linkable"
              onClick={() => navigate(-1)}
            />
            {itemState ? "Modification de locataire" : "Ajout de locataire"}
          </h4>
        </div>
        <div className="kanimmo-tabs-container container-form-add-locataire mt-2">
          <div className="tabs-component-container locataire-requete-filtre-container">
            <div className="pt-4 pb-5">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Selectionner bien
                        <span style={{ color: "red", fontSize: "20px" }}>
                          *
                        </span>
                      </label>
                      <Controller
                        name="propriete"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              placeholder="Sélectionner le bien"
                              components={animatedComponents}
                              options={data?.map((item) => {
                                return {
                                  value: item?.id,
                                  label: item?.nom,
                                };
                              })}
                              inputClass="form-select form-select-modal-custom mb-2"
                              classNamePrefix="select-comodite"
                              isLoading={isLoading}
                              onChange={handleChangePropriete}
                              value={option}
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.propriete && (
                      <ErrorMessage message={errors?.propriete?.message} />
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="tel"
                        className="form-label form-label-modal-custom"
                      >
                        Contrat de bail (pdf/word)
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-modal-custom"
                        id="contrat_de_bail"
                        accept="application/msword, application/pdf"
                        onChange={(e) => handelAvatarChange(e)}
                      />
                      {itemState?.contrat ? (
                        <button
                          className="custom-btn-voir-contrat btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#DocumentContratModal${itemState?.slug}`}
                        >
                          Afficher le contrat
                        </button>
                      ) : null}
                      <div
                        className="modal fade"
                        id={`DocumentContratModal${itemState?.slug}`}
                        aria-labelledby="DocumentContratModalLabel"
                        aria-hidden="true"
                      >
                        <AgenceDocumentContratModal item={itemState} />
                      </div>
                      {errors?.contrat && (
                        <ErrorMessage message={errors?.contrat?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="debut_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Début du bail
                      </label>
                      <input
                        type="date"
                        placeholder="Début du bail"
                        className="form-control form-control-modal-custom"
                        id="date_debut"
                        {...register("date_debut")}
                      />
                      {errors?.date_debut && (
                        <ErrorMessage message={errors?.date_debut?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="fin_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Fin du bail
                      </label>
                      <input
                        type="date"
                        placeholder="Fin du bail"
                        className="form-control form-control-modal-custom"
                        id="date_fin"
                        {...register("date_fin")}
                      />
                      {errors?.date_fin && (
                        <ErrorMessage message={errors?.date_fin?.message} />
                      )}
                    </div>
                  </div>
                  {/* MODIFICATIO */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="fin_bail"
                        className="form-label form-label-modal-custom"
                      >
                        Date du prochain paiement{" "}
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        placeholder="I"
                        className="form-control form-control-modal-custom"
                        id="date_prochaine_paiement"
                        // min={dateDebut}
                        {...register("date_prochaine_paiement")}
                      />
                      {errors?.date_prochaine_paiement && (
                        <ErrorMessage
                          message={errors?.date_prochaine_paiement?.message}
                        />
                      )}
                    </div>
                  </div>
                  {/* MODIFICATIO FIN */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="nom"
                        className="form-label form-label-modal-custom"
                      >
                        Nom du locataire
                        <span style={{ color: "red", fontSize: "20px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Nom"
                        className="form-control form-control-modal-custom"
                        id="nom"
                        {...register("nom")}
                      />
                      {errors?.nom && (
                        <ErrorMessage message={errors?.nom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="prenom"
                        className="form-label form-label-modal-custom"
                      >
                        Prénom(s) du locataire
                        <span style={{ color: "red", fontSize: "20px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Prénom"
                        className="form-control form-control-modal-custom"
                        id="prenom"
                        {...register("prenom")}
                      />
                      {errors?.prenom && (
                        <ErrorMessage message={errors?.prenom?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="tel"
                        className="form-label form-label-modal-custom"
                      >
                        Téléphone du locataire
                        <span style={{ color: "red", fontSize: "20px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Téléphone"
                        className="form-control form-control-modal-custom"
                        id="telephone"
                        {...register("telephone")}
                      />
                      {errors?.telephone && (
                        <ErrorMessage message={errors?.telephone?.message} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label form-label-modal-custom"
                      >
                        Email du locataire
                        <span style={{ color: "red", fontSize: "20px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-modal-custom"
                        id="email"
                        placeholder="Email"
                        {...register("email")}
                      />
                      {errors?.email && (
                        <ErrorMessage message={errors?.email?.message} />
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Date de naissance
                      </label>
                      <input
                        type="date"
                        placeholder="Date de naissance"
                        className="form-control form-control-modal-custom"
                        id="date_de_naissance"
                        {...register("date_de_naissance")}
                      />
                      {errors?.date_de_naissance && (
                        <ErrorMessage
                          message={errors?.date_de_naissance?.message}
                        />
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Doit-il payer une caution?
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="true"
                            {...register("cautionnement")}
                            id="flexCheckDefaultoui"
                            onClick={handleChangeCaution}
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultoui"
                          >
                            OUI
                          </label>
                        </div>
                        <div className="checkbox ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="false"
                            id="flexCheckDefaultnon"
                            {...register("cautionnement")}
                            onClick={handleChangeCaution}
                            defaultChecked
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultnon"
                          >
                            NON
                          </label>
                        </div>
                      </div>
                      {errors?.cautionnement && (
                        <ErrorMessage
                          message={errors?.cautionnement?.message}
                        />
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Doit-il payer une caution?
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${true}`}
                            // id="flexCheckDefaultnon"
                            name="cautionnement"
                            onClick={(e) => handleCheckedCaution(e)}
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultoui"
                          >
                            OUI
                          </label>
                        </div>
                        <div className="checkbox ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${false}`}
                            // id="flexCheckDefaultnon"
                            name="cautionnement"
                            onClick={(e) => handleCheckedCaution(e)}
                            defaultChecked
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultnon"
                          >
                            NON
                          </label>
                        </div>
                      </div>
                      {errors?.cautionnement && (
                        <ErrorMessage
                          message={errors?.cautionnement?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="date_naissance"
                        className="form-label form-label-modal-custom"
                      >
                        Doit-il payer un prorata?
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${true}`}
                            name="pay_proratat"
                            // id="flexCheckDefaultoui"
                            onClick={(e) => handleChecked(e)}
                            // checked={isChecked === true}
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultoui"
                          >
                            OUI
                          </label>
                        </div>
                        <div className="checkbox ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={`${false}`}
                            // id="flexCheckDefaultnon"
                            name="pay_proratat"
                            onClick={(e) => handleChecked(e)}
                            // checked={isChecked === false}
                            defaultChecked
                          />
                          <label
                            className="form-check-label form-label-login ms-2"
                            htmlFor="flexCheckDefaultnon"
                          >
                            NON
                          </label>
                        </div>
                      </div>
                      {errors?.cautionnement && (
                        <ErrorMessage
                          message={errors?.cautionnement?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={isCheckedCaution ? "d-block" : "d-none"}>
                      <div className="col-md-12 auth-input-col">
                        <div className="form-group auth-form-group">
                          <label className="form-label form-label-login">
                            Montant de la caution
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            min={0}
                            className="form-control form-control-modal-custom"
                            id="nombre"
                            placeholder="Montant de la caution"
                            {...register("montant_caution")}
                          />
                          {errors?.montant_caution && (
                            <ErrorMessage
                              message={errors?.montant_caution?.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={isChecked ? "d-block" : "d-none"}>
                      <div className="col-md-12 auth-input-col">
                        <div className="form-group auth-form-group">
                          <label className="form-label form-label-login">
                            Montant du prorata
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            min={0}
                            step="any"
                            className="form-control form-control-modal-custom"
                            id="nombre"
                            placeholder="Montant du prorata"
                            {...register("proratat")}
                          />
                          {errors?.proratat && (
                            <ErrorMessage message={errors?.proratat?.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-btn-modal row my-4">
                  <div className="col-md-4 offset-md-8">
                    <div className="d-flex justify-content-end">
                      <BtnSubmit
                        label={itemState ? "Modifer" : "Envoyer"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateLocataire;
