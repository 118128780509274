/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";

import { useNavigate } from "react-router-dom";
import {
  IPropriete,
  ProprieteFormData,
  TypePropriete,
} from "../../../../../utils/api/propriete/propriete.type";
import { useAddOrUpdateProprieteMutation } from "../../../../../utils/api/propriete/propriete.api";
import {
  clean,
  cleannerError,
  isAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetAgencesQuery } from "../../../../../utils/api/agence/agence.api";
import { countries } from "../../../../../utils/Countries";
import { useGetCommoditesQuery } from "../../../../../utils/api/commodites/commodites.api";

type SelectOptionType = { label: string; value: number };

function UseCrudAppartImmeuble(
  navigation: any,
  proprieteItem?: IPropriete | any
) {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    hasChambre: yup.boolean().default(false),
    step: yup.number().default(1),
    isOwner: yup.boolean(),
    adresse: yup.string().when("step", {
      is: 1,
      then: yup.string().label("L'adresse"),
    }),
    numero: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le numero"),
    }),
    nom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le nom"),
    }),
    rue: yup.string().when("step", {
      is: 1,
      then: yup.string().label("La rue"),
    }),
    pays: yup.string().when("step", {
      is: 1,
      then: yup.string().label("Le pays"),
    }),
    ville: yup.string().when("step", {
      is: 1,
      then: yup.string().label("La ville"),
    }),
    code_postale: yup
      .string()
      .when("step", {
        is: 1,
        then: yup.string().label("Le code postal").nullable(),
      })
      .nullable(),
    // prix: yup.string().when("step", {
    //   is: 1,
    //   then: yup.string().label("Le prix"),
    // }),
    commission_agence: yup.string().when("isOwner", {
      is: false,
      then: yup
        .string()
        .when("step", {
          is: 1,
          then: yup.string().required().label("Le taux de rémunération"),
        })
        .nullable(),
    }),
    surface: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("La surface de la propriété")
          .required()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    surface_climatiseur: yup.string().when("step", {
      is: 2,
      then: yup.string().label("La surface de la climatiseur"),
    }),
    description: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("La description"),
    }),
    pictures: yup.array().when("step", {
      is: 2,
      then: yup.array().max(4).required("Veuillez uploader au moins une image"),
    }),
    url_youtube: yup.string().when("step", {
      is: 2,
      then: yup.string().label("Lien vers la video"),
    }),
    // gerance: yup.mixed().when("step", {
    //   is: 3,
    //   then: yup.mixed().label("Type de gerance"),
    // }),
    mandat: yup.mixed().label("Le mandat"),
    nbre_chambres: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de chambres")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_cuisines: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de cuisines")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salle_de_bains: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salles de bain")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salon: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salons")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_climatiseurs: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("Le nombre de climatisseurs")
          .nullable()
          .transform((value) => (isNaN(value) ? 0 : value))
          .default(0),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? 0 : value))
      .default(0),
    prix_base: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .required()
          .label("Le Loyer Hors Charges")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    charges: yup
      .array()
      .of(
        yup.object().shape(
          {
            libelle: yup.string().when(["type", "montant"], {
              is: (a: string, b: number) => {
                console.log(a, b, "test");
                return a || b;
              },
              then: yup.string().required().nullable().label("Le libellé"),
            }),
            type: yup.string().when(["libelle", "montant"], {
              is: (a: string, b: number) => a || b,
              then: yup.string().required().nullable().label("Le type"),
            }),
            montant: yup
              .number()
              .when(["libelle", "type"], {
                is: (a: string, b: string) => a || b,
                then: yup
                  .number()
                  .nullable()
                  .required()
                  .label("La valeur")
                  .transform((value) => (isNaN(value) ? null : value))
                  .nullable(),
              })
              .transform((value) => (isNaN(value) ? null : value))
              .nullable(),
          },
          [
            ["libelle", "type"],
            ["type", "montant"],
            ["libelle", "montant"],
          ]
        )
      )
      .nullable(),
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
  } = useForm<ProprieteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [formData, setFormData] = useState<ProprieteFormData>({
    step: 1,
    slug: "",
    numero: "",
    adresse: "",
    rue: "",
    pays: "",
    ville: "",
    region: "",
    code_postale: "",
    type_propriete: TypePropriete.appartement,
    nb_chambre: 0,
    nb_cuisine: 0,
    nb_salle_de_bain: 0,
    prix: 0,
    proprietaire: undefined,
    agence: undefined,
    description: undefined,
    pictures: undefined,
    chambres: [],
  });
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState<string>("");
  const [pictures, setPictures] = useState<File[]>();
  const [mandatPicture, setMandatPicture] = useState<File>();
  const [commodites, setCommodites] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const { user } = useAppSelector((s) => s.user);
  const [addOrUpdatePropriete, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateProprieteMutation();
  useEffect(() => {
    if (pictures?.length) {
      setValue(
        "pictures",
        pictures?.filter((el: any) => !el?.slug)
      );
    }
  }, [pictures]);
  useEffect(() => {
    // console.log(errors);
    cleannerError(errors, clearErrors, 5000);
  }, [errors]);

  useEffect(() => {
    if (isProprietaireOwner(user) || proprieteItem?.immo?.agence_owner) {
      setValue("isOwner", true);
      // console.log("here owner");
    } else {
      setValue("isOwner", false);
    }
  }, [proprieteItem, user]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Propriété ${
          !proprieteItem?.slug ? "ajoutée" : "modifée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (user?.user_type === "agence") {
          navigate(
            `/agence/immeuble/${
              proprieteItem?.immo?.slug || proprieteItem?.immeuble?.slug
            }`,
            {
              replace: true,
              state: proprieteItem?.immo || proprieteItem?.immeuble,
            }
          );
        } else if (user?.user_type === "proprietaire") {
          if (isProprietaireOwner(user)) {
            navigate(
              `/proprietaire/gerer-par-le-proprietaire/immeuble/${
                proprieteItem?.immo?.slug || proprieteItem?.immeuble?.slug
              }`,
              {
                replace: true,
                state: proprieteItem?.immo || proprieteItem?.immeuble,
              }
            );
          } else {
            navigate(
              `/proprietes/gerer-par-agence/immeuble/${
                proprieteItem?.immo?.slug || proprieteItem?.immeuble?.slug
              }`,
              {
                replace: true,
                state: proprieteItem?.immo || proprieteItem?.immeuble,
              }
            );
          }
        }
      });
    }

    if (isError) {
      const err = error as any;
      // console.log("error", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (proprieteItem?.id) {
      const fields: (keyof ProprieteFormData | any)[] = [
        "nom",
        "numero",
        "adresse",
        "rue",
        "pays",
        "ville",
        "region",
        "code_postale",
        "nb_chambre",
        "nb_cuisine",
        "nb_salle_de_bain",
        "prix",
        "proprietaire",
        "agence",
        "description",
        "immeuble",
        "nbre_chambres",
        "nbre_cuisines",
        "nbre_salle_de_bains",
        "nbre_salon",
        "nbre_climatiseurs",
        "prix_base",
      ];
      // setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        if (
          field === "proprietaire" ||
          field === "agence" ||
          field === "immeuble"
        ) {
          if (proprieteItem[field]) {
            setValue(field, proprieteItem[field]?.id);
          }
        } else {
          setValue(field, proprieteItem[field]);
        }
      }
      setPictures(proprieteItem?.pictures);
    } else if (proprieteItem?.immo?.id) {
      const fields: (keyof ProprieteFormData | any)[] = [
        "adresse",
        "rue",
        "pays",
        "ville",
        "region",
        "code_postale",
        "proprietaire",
        "agence",
        "immeuble",
      ];
      // setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        if (field === "proprietaire" || field === "agence") {
          if (proprieteItem?.immo[field]) {
            // console.log(typeof proprieteItem?.immo[field],field);
            if (typeof proprieteItem?.immo[field] === "number") {
              setValue(field, proprieteItem?.immo[field]);
            } else {
              setValue(field, proprieteItem?.immo[field]?.id);
            }
          }
        } else if (field === "immeuble") {
          setValue(field, proprieteItem?.immo?.id);
        } else {
          setValue(field, proprieteItem?.immo[field]);
        }
      }
    }
  }, [proprieteItem]);

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  const handleChangeMandatPicture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files !== null) {
      setValue("mandat", e.currentTarget.files[0]);
      setMandatPicture(e.currentTarget.files[0]);
    }
  };

  const onChangeAddress = (val: { rue: string }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  let pays: any = countries?.find((el) => el?.name === proprieteItem?.pays);
  const [country, setCountry] = useState<any>(
    pays
      ? {
          value: pays?.iso2,
          label: (
            <div className="_3sXmFundefined">
              <img
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${pays?.iso2}.svg`}
                alt="flag"
                className="_2z69Y"
              />
              <span className="_1qEzs">{pays?.name}</span>
            </div>
          ),
          ...pays,
        }
      : null
  );
  const [state, setState] = useState<any>(
    proprieteItem?.region ? proprieteItem?.region : null
  );
  const [city, setCity] = useState<any>(
    proprieteItem?.ville ? proprieteItem?.ville : null
  );

  const handleCountrySelect = (option: any) => {
    setCountry(option);
    setValue("pays", option.name);
  };

  const handleStateSelect = (option: any) => {
    if (option.label) {
      setState(option);
      setValue("region", option.label);
    }
  };
  const handleCitySelect = (option: any) => {
    setCity(option);
    setValue("ville", option.label);
  };

  const onChangeCommodite = (selected: SelectOptionType[]) => {
    setCommodites(selected);
    setValue(
      "commodites",
      selected?.map((item: any) => item?.value)
    );
  };

  const onSubmit = async (data: ProprieteFormData) => {
    if (!proprieteItem?.slug) {
      if (user?.agence_id || user?.proprietaire_id) {
        if (user?.agence_id) {
          data["agence"] = user?.agence_id?.id;
        }

        if (user?.proprietaire_id) {
          data["proprietaire"] = user?.proprietaire_id?.id;
        }
      } else {
        if (user?.user_type === "agence") {
          data["agence"] = user?.id;
        }
        if (user?.user_type === "proprietaire") {
          data["proprietaire"] = user?.id;
        }
      }
    }

    setFormData(data);
    if (data?.step === 1) {
      // console.log(data);
      navigation.go("details-appartement");
    } else if (
      data?.step === 2 &&
      !isProprietaireOwner(user) &&
      !isProprioJuriste(user)
    ) {
      // console.log(data);
      navigation.go("gestion-appartement");
    } else {
      let dataSend = clean(data);
      dataSend["type_propriete"] = "appartement";
      // console.log("data submitted", dataSend);
      const fd = new FormData();
      if (
        pictures?.length &&
        pictures?.filter((el: any) => !el?.slug)?.length
      ) {
        for (const picture of pictures) {
          fd.append("pictures", picture);
        }
      }
      if (dataSend?.charges?.length) {
        dataSend.charges = dataSend?.charges?.filter((el: any) => el?.libelle);
      }

      for (let key of Object.keys(dataSend)) {
        const val = dataSend[key];
        if (key !== "pictures") {
          if (key === "proprietaire") {
            if (dataSend[key] && dataSend[key] === "agence") {
              // data["agence_owner"] = true;
              let val: any = true;
              fd.append("agence_owner", val);
            } else {
              fd.append(key, dataSend[key]);
              let val: any = false;
              fd.append("agence_owner", val);
            }
          } else if (key === "charges") {
            if (dataSend?.charges?.length) {
              fd.append("charges", JSON.stringify(dataSend[key]));
            }
          } else {
            fd.append(key, val);
          }
        }
      }

      // console.log(dataSend);
      await addOrUpdatePropriete({ slug: data?.slug, data: fd });
    }
  };

  const { data: commoditedatas } = useGetCommoditesQuery({});
  // console.log("proprieteItem", proprieteItem);
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    formData,
    description,
    pictures,
    mandatPicture,
    address,
    onChangeAddress,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    proprieteItem,
    control,
    Controller,
    commoditesList: commoditedatas?.results,
    commodites,
    onChangeCommodite,
    unregister,
  };
}

export default UseCrudAppartImmeuble;
