import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { IAnnonce } from "../../../utils/api/annonces/annonce.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useNoteForm from "../../TableauDebord/Agence/Administratif/requestForm/useNoteForm";
import { useAppSelector } from "../../../redux/hooks";
import {
  useGetLocataireByAgenceBySlugQuery,
  useGetLocataireByProprieteSlugQuery,
} from "../../../utils/api/locataire/locataire.api";
// import { useGetLocataireByAgenceBySlugQuery } from "../../../utils/api/locataire/locataire.api";
import { isProprietaireOwner } from "../../../utils/Utils";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import {
  useGetLocataireByProprietaireBySlugQuery,
  useGetProprietairesByAgenceQuery,
} from "../../../utils/api/proprietaire/proprietaire.api";

function AjouterNoteModal({
  note,
  modalId,
}: {
  note?: IAnnonce;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    locataires,
    setLocataires,
    envoiType,
    setEnvoiType,
    proprietaires,
    setProprietaires,
    // handelImageChange,
    handleChangeContent,
    // previewImage,
    content,
  } = useNoteForm(modalId, note);
  useEffect(() => {
    if (note) {
      setValue("slug", note?.slug);
      setValue("titre", note?.titre);
      setValue("content", note?.content);
      setValue("envoiType", note?.envoiType);
    }
  }, [note]);

  const [selectAllLocataires, setSelectAllLocataires] = useState(false);
  const { user } = useAppSelector((s) => s?.user);
  // console.log(user);

  const { data: locatairesData = { results: [] } } =
    useGetLocataireByAgenceBySlugQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    });
  const { data: locatairesPQGSPData = { results: [] } } =
    useGetLocataireByProprietaireBySlugQuery({
      slug: user?.proprietaire_id?.slug
        ? user?.proprietaire_id?.slug
        : user?.slug,
    });

  // console.log({ locatairesPQGSPData });

  const { data: proprietairesData = { results: [] } } =
    useGetProprietairesByAgenceQuery({
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    });

  const selectAllLocatairesHandler = () => {
    const anySelectedLocataire =  locataires.some(
      (locataire) => locataire?.user_type === "locataire"
    );
    if (!anySelectedLocataire) {
      const selectedLocataires =  locataires.concat(
        locatairesData.results.filter(
          (locataire) => locataire.user_type === "locataire"
        )
      );
      setLocataires(selectedLocataires);
    } else {
      const deselectedLocataires = locataires?.filter(
        (locataire) => locataire?.user_type !== "locataire"
      );
      setLocataires(deselectedLocataires);
    }
    setSelectAllLocataires(locataires.length === 0);
  };

  const selectAllProprietairesHandler = () => {
    const anySelectedProprietaire = locataires.some(
      (locataire) => locataire?.user_type === "proprietaire"
    );
    if (!anySelectedProprietaire) {
      const selectedProprietaire = locataires.concat(
        proprietairesData.results.filter(
          (locataire) => locataire.user_type === "proprietaire"
        )
      );
      setLocataires(selectedProprietaire);
    } else {
      const deselectedProprietaire = locataires?.filter(
        (locataire) => locataire?.user_type !== "proprietaire"
      );
      setLocataires(deselectedProprietaire);
    }
    setSelectAllLocataires(locataires.length === 0);
  };

  const handleDestinataireChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const locataire = locatairesData.results.find((loc) => loc.email === value);
    // const proprietaire = proprietairesData.results.find(
    //   (loc) => loc.email === value
    // );
    const proprietaire = proprietairesData.results.find(
      (loc: any) => loc.email === value
    );

    if (checked) {
      if (
        locataire &&
        !locataires.some((selected) => selected.email === locataire.email)
      ) {
        setLocataires([...locataires, locataire]);
      }
      if (
        proprietaire &&
        !locataires.some((selected) => selected.email === proprietaire.email)
      ) {
        setLocataires([...locataires, proprietaire]);
      }
    } else {
      setLocataires(locataires.filter((loc) => loc.email !== value));
    }
    setSelectAllLocataires(locataires.length === locatairesData.results.length);
  };

  const selectHandle = () => {
    if (envoiType === "tous") {
      const alreadySelected =
        locataires.some((locataire) => locataire.user_type === "locataire") ||
        locataires.some(
          (proprietaire) => proprietaire.user_type === "proprietaire"
        );

      if (!alreadySelected) {
        if (isProprietaireOwner(user)) {
          const selectedLocataires = locatairesPQGSPData.results;
          setLocataires(selectedLocataires);
        } else {
          const selectedDestinataires = locataires.concat(
            locatairesData.results,
            proprietairesData.results
          );
          setLocataires(selectedDestinataires);
        }
      }
    }
  };

  useEffect(() => {
    selectHandle();
  }, [envoiType]);

  const handleDestinataireClick = (email: string) => {
    const isAlreadySelected = locataires.some(
      (selected) => selected.email === email
    );

    if (isAlreadySelected) {
      const updatedLocataires = locataires.filter(
        (selected) => selected.email !== email
      );
      setLocataires(updatedLocataires);
    } else {
      const locataireToAdd = locatairesData.results.find(
        (item) => item.email === email
      );
      if (locataireToAdd) {
        setLocataires([...locataires, locataireToAdd]);
      }
    }
  };

  const handleProprietaireClick = (email: string) => {
    const isAlreadySelected = locataires.some(
      (selected) => selected.email === email
    );

    if (isAlreadySelected) {
      const updatedLocataires = locataires.filter(
        (selected) => selected.email !== email
      );
      setLocataires(updatedLocataires);
    } else {
      const proprietaireToAdd = proprietairesData.results.find(
        (item: any) => item.email === email
      );
      if (proprietaireToAdd) {
        setLocataires([...locataires, proprietaireToAdd]);
      }
    }
  };

  const handleSelection = (email: string) => {
  // Vérifier si le propriétaire est déjà dans la liste des locataires
  const isSelected = locataires.some((loc) => loc.email === email);

  // Si le propriétaire est déjà sélectionné, le retirer de la liste des locataires
  if (isSelected) {
    const updatedLocataires = locataires.filter((loc) => loc.email !== email);
    setLocataires(updatedLocataires);
  } else {
    // Si le propriétaire n'est pas déjà sélectionné, l'ajouter à la liste des locataires
    const proprietaireToAdd = locatairesPQGSPData.results.find(
      (item: any) => item.email === email
    );
    if (proprietaireToAdd) {
      setLocataires([...locataires, proprietaireToAdd]);
    }
  }
};

// Dans votre code JSX où vous affichez les propriétaires
// {proprietairesData.results.map((proprietaire) => (
//   <div key={proprietaire.id} onClick={() => handleSelection(proprietaire)}>
//     {proprietaire.nom} {/* Affichez les détails du propriétaire */}
//   </div>
// ))}


  console.log("locataires", locataires);
  // console.log("proprietaire", proprietairesData);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="envoiType" className="form-label">
                    Type d'envoi
                  </label>
                  <select
                    id="envoiType"
                    className="form-select form-control-modal-custom"
                    value={envoiType}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setEnvoiType(selectedValue);
                      setLocataires([]);
                      setSelectAllLocataires(false);
                    }}
                  >
                    <option value="">Choisir un destinataire</option>
                    <option value="tous">Tous</option>
                    <option value="tousLocataires">Locataires</option>
                    {!!isProprietaireOwner(user) ? (
                      ""
                    ) : (
                      <option value="tousProprietaires">Propriétaires</option>
                    )}
                  </select>
                  {errors?.envoiType && (
                    <FormError error={errors?.envoiType?.message} />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      Titre de la note
                    </label>
                    <input
                      type="text"
                      placeholder="Titre"
                      className="form-control form-control-modal-custom"
                      id="titre"
                      {...register("titre")}
                    />
                    {errors?.titre && (
                      <FormError error={errors?.titre?.message} />
                    )}
                  </div>
                </div>

                {envoiType === "tousLocataires" && (
                  <div className="mb-2">
                    {locataires.length > 0 ? (
                      <FaRegCheckSquare onClick={selectAllLocatairesHandler} />
                    ) : (
                      <FaRegSquare onClick={selectAllLocatairesHandler} />
                    )}
                    <input
                      type="checkbox"
                      className="d-none"
                      checked={selectAllLocataires}
                      onChange={selectAllLocatairesHandler}
                    />
                    <label
                      onClick={selectAllLocatairesHandler}
                      htmlFor="selectAllLocataires"
                      className="ps-2"
                    >
                      Tous
                    </label>
                  </div>
                )}

                {envoiType === "tousLocataires" && (
                  <div className="mb-3">
                    {locatairesData &&
                      locatairesData.results.map((item) => (
                        <div
                          key={item.id}
                          className={`text-primary-emphasis   ${
                            locataires.some(
                              (selected) => selected.email === item.email
                            )
                              ? "selected"
                              : "bg-light selectedHover"
                          } border border-primary-subtle p-1`}
                          onClick={() => handleDestinataireClick(item.email)}
                        >
                          {locataires.some(
                            (selected) => selected.email === item.email
                          ) ? (
                            <FaRegCheckSquare />
                          ) : (
                            <FaRegSquare />
                          )}
                          <input
                            type="checkbox"
                            id={item.email}
                            name={item.email}
                            value={item.email}
                            className="d-none"
                            checked={locataires.some(
                              (selected) => selected.email === item.email
                            )}
                            onChange={handleDestinataireChange}
                          />
                          <label
                            onClick={(e) => e.stopPropagation()}
                            className="ps-2"
                            htmlFor={item.email}
                          >
                            {item.prenom} {item.nom}
                          </label>
                        </div>
                      ))}

                    {locatairesPQGSPData &&
                      locatairesPQGSPData.results.map((item) => (
                        <div
                          key={item.id}
                          className={`text-primary-emphasis   ${
                            locataires.some(
                              (selected) => selected.email === item.email
                            )
                              ? "selected"
                              : "bg-light selectedHover"
                          } border border-primary-subtle p-1`}
                          onClick={() => handleSelection(item.email)}
                        >
                          {locataires.some(
                            (selected) => selected.email === item.email
                          ) ? (
                            <FaRegCheckSquare />
                          ) : (
                            <FaRegSquare />
                          )}
                          <input
                            type="checkbox"
                            id={item.email}
                            name={item.email}
                            value={item.email}
                            className="d-none"
                            checked={locataires.some(
                              (selected) => selected.email === item.email
                            )}
                            onChange={handleDestinataireChange}
                          />
                          <label
                            onClick={(e) => e.stopPropagation()}
                            className="ps-2"
                            htmlFor={item.email}
                          >
                            {item.prenom} {item.nom}
                          </label>
                        </div>
                      ))}
                  </div>
                )}

                {envoiType === "tousProprietaires" && (
                  <div className="mb-2">
                    <div>
                      {locataires.length ===
                      proprietairesData.results.length ? (
                        <FaRegCheckSquare
                          onClick={selectAllProprietairesHandler}
                        />
                      ) : (
                        <FaRegSquare onClick={selectAllProprietairesHandler} />
                      )}
                      <input
                        type="checkbox"
                        className="d-none"
                        checked={selectAllLocataires}
                        onChange={selectAllProprietairesHandler}
                      />
                      <label
                        onClick={selectAllProprietairesHandler}
                        htmlFor="selectAllLocataires"
                        className="ps-2"
                      >
                        Tous
                      </label>
                    </div>
                  </div>
                )}

                {envoiType === "tousProprietaires" && (
                  <div className="mb-3">
                    {proprietairesData &&
                      proprietairesData.results.map((item: any) => (
                        <div
                          key={item.id}
                          className={`text-primary-emphasis   ${
                            locataires.some(
                              (selected) => selected.email === item.email
                            )
                              ? "selected"
                              : "bg-light selectedHover"
                          } border border-primary-subtle p-1`}
                          onClick={() => handleProprietaireClick(item.email)}
                        >
                          {locataires.some(
                            (selected) => selected.email === item.email
                          ) ? (
                            <FaRegCheckSquare />
                          ) : (
                            <FaRegSquare />
                          )}
                          <input
                            type="checkbox"
                            id={item.email}
                            name={item.email}
                            value={item.email}
                            className="d-none"
                            checked={locataires.some(
                              (selected) => selected.email === item.email
                            )}
                            onChange={handleDestinataireChange}
                          />
                          <label
                            onClick={(e) => e.stopPropagation()}
                            className="ps-2"
                            htmlFor={item.email}
                          >
                            {item.prenom} {item.nom}
                          </label>
                        </div>
                      ))}
                  </div>
                )}

                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="note"
                      className="form-label form-label-modal-custom"
                    >
                      Note
                    </label>
                    <ReactQuill
                      className="editor-cgu"
                      value={content}
                      id="content"
                      onChange={(value) => handleChangeContent(value)}
                    />
                    {errors?.content && (
                      <FormError error={errors?.content?.message} />
                    )}
                    {/* <textarea
                      className="form-control form-control-modal-custom"
                      id="note"
                      rows={4}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row">
                <div className="col w-100 d-flex justify-content-between align-items-center">
                  <div className="btn-cancelled-container">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="btn-submit-container">
                    {/* <button className="btn auth-submit-btn">Envoyer</button> */}
                    <BtnSubmit
                      label={note ? "Modifier" : "Envoyer"}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterNoteModal;
