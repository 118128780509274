import React, { useEffect, useState, useRef } from "react";
import { BiArrowBack } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useGetPersonnelQuery,
  usePersonnelFromLocation,
} from "../../../../utils/api/agence/agence.api";
import { isProprietaireOwner } from "../../../../utils/Utils";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputCheckbox";
import ErrorMessage from "../../../common/ErrorMessage";
import GoogleInput from "../../../common/GoogleInput";
import ConfirmAddPersonnel from "../../../modal/agence/ConfirmAddPersonnel";
import ConfirmAddProprietaire from "../../../modal/agence/ConfirmAddProprietaire";
import UseAddOrUpdatePersonnel from "./requestForm/UseAddOrUpdateUserAgent";

function AjouterUtilisateurAgence() {
  const [itemState, findBySlug] = usePersonnelFromLocation();

  // console.log("itemState", itemState);
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    modalIsOpen,
    setIsOpen,
    personne,
    onChangeAddress,
    address,
    phone,
    setPhone,
    userType,
  } = UseAddOrUpdatePersonnel(itemState);
  const { user } = useAppSelector((u) => u?.user);
  const navigate = useNavigate();
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(e: any) {
    e.preventDefault();
    setIsOpen(true);
  }

  const ref = useRef<any>();

  console.log(userType);

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <button className="kanimmo-dash-locataire-requetes-header-titre no-link bg-transparent">
            <BiArrowBack onClick={() => navigate(-1)} />
            <span className="ps-3">
              {" "}
              {itemState ? "Modifier" : "Ajouter"} un collaborateur
            </span>
          </button>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-ajouter-proprietaire locataire-requete-filtre-container py-3">
              <div className="pt-4 pb-5">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    {userType !== "DEFAULT" && (
                      <>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="prenom"
                              className="form-label form-label-modal-custom"
                            >
                              Prénom
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: 24,
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Prénom"
                              className="form-control form-control-modal-custom"
                              id="prenom"
                              {...register("prenom")}
                            />
                            {errors?.prenom && (
                              <ErrorMessage message={errors?.prenom?.message} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="nom"
                              className="form-label form-label-modal-custom"
                            >
                              Nom
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: 24,
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Nom"
                              className="form-control form-control-modal-custom"
                              id="nom"
                              {...register("nom")}
                            />
                            {errors?.nom && (
                              <ErrorMessage message={errors?.nom?.message} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="email"
                              className="form-label form-label-modal-custom"
                            >
                              Adresse mail
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: 24,
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="email"
                              placeholder="Email"
                              className="form-control form-control-modal-custom"
                              id="email"
                              {...register("email")}
                            />
                            {errors?.email && (
                              <ErrorMessage message={errors?.email?.message} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="phone"
                              className="form-label form-label-modal-custom"
                            >
                              Téléphone
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: 24,
                                }}
                                ref={ref}
                              >
                                *
                              </span>
                            </label>
                            <PhoneInput
                              defaultMask={".. ... .. .."}
                              containerClass="form-control auth-form-control mb-3 p-0"
                              inputClass="form-control form-control-modal-custom"
                              country={"sn"}
                              placeholder=""
                              inputProps={{
                                name: "telephone",
                                required: true,
                                autoFocus: true,
                              }}
                              countryCodeEditable={false}
                              enableAreaCodes={true}
                              prefix="+"
                              value={phone}
                              onChange={(phone, country: any) => {
                                setPhone(phone);

                                if (
                                  country?.format?.match(/\./g)?.length ===
                                  phone?.length
                                ) {
                                  setValue("telephone", phone);
                                }
                              }}
                              inputStyle={{
                                width: "100%",
                                paddingBottom: "22px",
                                borderRadius: "10px",
                              }}
                            />
                            {errors?.telephone && (
                              <ErrorMessage
                                message={errors?.telephone?.message}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {userType !== undefined &&
                      userType !== "gerant" &&
                      userType !== "DEFAULT" && (
                        <>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="profession"
                                className="form-label form-label-modal-custom"
                              >
                                Profession
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-modal-custom"
                                id="profession"
                                placeholder="Profession"
                                {...register("profession")}
                              />
                              {errors?.profession && (
                                <ErrorMessage
                                  message={errors?.profession?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="adresse"
                                className="form-label form-label-modal-custom"
                              >
                                Adresse
                              </label>
                              <GoogleInput
                                className="form-control form-control-modal-custom form-control-params-custom"
                                value={address}
                                onChange={onChangeAddress}
                              />
                              <FormError error={errors?.adresse?.message} />
                            </div>
                          </div>
                        </>
                      )}

                    <div className="col-md-6 auth-input-col">
                      <div className="mb-3">
                        <label
                          htmlFor="nombre_stock"
                          className="form-label form-label-modal-custom"
                          aria-labelledby="propriete"
                        >
                          Rôle
                        </label>
                        <select
                          id="propriete"
                          className="form-select form-select-modal-custom mb-2"
                          {...register("user_type")}
                          defaultValue={"DEFAULT"}
                        >
                          <option value="DEFAULT" disabled>
                            Role
                          </option>
                          <option value="comptable">Comptable</option>
                          <option value="gerant">Gérant</option>
                          <option value="juriste">Juriste</option>
                          {/* <option value="marketeur">Marketeur</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="permission"
                          className="form-label form-label-modal-custom mt-5 mb-4"
                        >
                          Permission
                        </label>
                        <div className="container-checkbox-input">
                          <div className="form-checkbox-input checkbox-input">
                            <div className="custom-permission-container">
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("dashboard")}
                                  id={`dashboard`}
                                  defaultChecked={false}
                                />

                                <span>Dashboard</span>
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("bien")}
                                  id={`bien`}
                                  defaultChecked={false}
                                />
                                <span>Biens</span>
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("locatair")}
                                  id={`locatair`}
                                  defaultChecked={false}
                                />
                                <span>Locataires</span>
                              </label>
                              {!isProprietaireOwner(user) && (
                                <label>
                                  <input
                                    type="checkbox"
                                    {...register("location")}
                                    id={`location`}
                                    defaultChecked={false}
                                  />
                                  <span>Propriétaires</span>
                                </label>
                              )}

                              <label>
                                <input
                                  type="checkbox"
                                  {...register("paiement")}
                                  id={`paiement`}
                                  defaultChecked={false}
                                />
                                <span>Comptabilité</span>
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("parametre")}
                                  id={`parametre`}
                                  defaultChecked={false}
                                />
                                <span>Paramètres</span>
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("message")}
                                  id={`message`}
                                  defaultChecked={false}
                                />
                                <span>Messagerie</span>
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  {...register("administratif")}
                                  id={`administratif`}
                                  defaultChecked={false}
                                />
                                <span>Administratif</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-btn-modal row my-4">
                    <div className="col-md-4 offset-md-4">
                      <button
                        className="btn auth-submit-annuler w-100"
                        onClick={() => navigate(-1)}
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col-md-4">
                      <BtnSubmit
                        label={itemState ? "Modifier" : "Ajouter"}
                        isLoading={isLoading}
                        style={{ width: "100%" }}
                        disabled={userType === "DEFAULT" || userType === undefined}
                      />
                      <ConfirmAddPersonnel
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        item={itemState}
                        personne={personne}
                        from="add_user"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterUtilisateurAgence;
