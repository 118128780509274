/* eslint-disable @typescript-eslint/no-use-before-define */
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { AppRoutes } from "./routes/router";
import { useMeQuery } from "./utils/api/user/user.api";
import { useChatListener } from "./utils/pusher";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { onlogout, onSetToken, onSetUser } from "./redux/slice/User.slice";
import {
  isAgence,
  isProprietaireAgence,
  isProprietaireOwner,
  onShow,
} from "./utils/Utils";
import { UserType } from "./utils/api/user/user.type";
import { useGetAccountInfoByUserQuery } from "./utils/api/accountInfo/accountInfo.api";
import ChangePasswordModal from "./components/modal/admin/ChangePasswordModal";
import InfosPaiementModal from "./components/modal/user/InfosPaiementModal";
import CheckUserAbonnement from "./components/modal/user/CheckUserAbonnement";
import { useRefreshTokenMutation } from "./utils/api/auth/auth.api";

function App() {
  const routes = useRoutes(AppRoutes);
  const isConnected = useAppSelector((s) => !!s.user?.token);
  useChatListener();

  // if (token && user?.id) {
  //   let decoded: JwtPayload = jwtDecode(token);
  //   let { exp } = decoded;

  //   if (exp) {
  //     if (exp < moment().unix()) {
  //       console.log("expired", exp);
  //       dispatch(onlogout());
  //     }
  //   }
  // }
  return (
    <div className="app-routing-container">
      <ScrollToTop />

      {isConnected && <CompleteProfil />}
      {isConnected && <FetchUser />}
      {routes}
      <ToastContainer />
    </div>
  );

  function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname, location.state]);
    return null;
  }
}

export default App;

function FetchUser() {
  const { token } = useAppSelector((s) => s?.user);
  const { isSuccess, data, error, isError } = useMeQuery();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = window.location.pathname;
  const [refreshToken] = useRefreshTokenMutation();
  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
      dispatch(onSetUser(data));
    }
  }, [isSuccess, data, error]);

  useEffect(() => {
    if (isError && error && "status" in error && error?.status === 401) {
      if (token) {
        refreshToken(token).then((res: any) => {
          if ("data" in res) {
            if ("token" in res?.data) {
              const { token } = res.data;
              // console.log("token", token);
              dispatch(onSetToken(token));
            } else {
              dispatch(onlogout());
            }
          } else {
            dispatch(onlogout());
          }
        });
      }
    }
  }, [error, isError, pathname, location]);

  return null;
}

function CompleteProfil() {
  const { user } = useAppSelector((s) => s?.user);
  const { data = { results: [], count: 0 }, isSuccess } =
    useGetAccountInfoByUserQuery({
      slug: user?.slug,
      type: user?.user_type,
    });

  const location = useLocation();

  // useEffect(() => {
  //   console.log("verifie moi ça", data);
  //   const timer = setTimeout(() => {
  //     if (
  //       isProprietaireAgence(user) &&
  //       data.results &&
  //       data.results.length === 0
  //     ) {
  //       onShow("AccountInfoModal");
  //     }
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, [user, data.results]);

  useEffect(() => {
    console.log("verifie moi ça", data);
    const timer = setTimeout(() => {
      if (
        isProprietaireAgence(user)
      ) {
        if(user?.first_connexion) {
          onShow("ChangePasswordModal");
        }

        if (!user?.first_connexion &&
          data.results &&
          data.results.length === 0) {
            onShow("AccountInfoModal");
        }
        
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [user, data.results]);

  useEffect(() => {
    // console.log("user",user)
    if (user && user?.offre) {
      if (user?.first_connexion) {
        if (
          user?.user_type !== UserType.superAdmin &&
          user?.user_type !== UserType.admin
        ) {
          onShow("ChangePasswordModal");
        }
      } else if (isSuccess && data?.results?.length === 0) {
        if (
          isProprietaireAgence(user) ||
          isProprietaireOwner(user) ||
          isAgence(user)
        ) {
          onShow("AccountInfoModal");
          // Swal.fire({
          //   icon: "info",
          //   title:
          //     "Veuillez renseigner avec quel moyen de paiement souhaitez-vous recevoir vos paiement!",
          //   iconColor: Color.bleu,
          //   showConfirmButton: true,
          //   showCancelButton: false,
          //   confirmButtonText: "D'accord",
          //   allowOutsideClick: false,
          //   confirmButtonColor: Color.bleu,
          // }).then((result) => {
          //   if (result?.isConfirmed) {
          //     if (
          //       isProprietaireOwner(user) &&
          //       location?.pathname !==
          //         "/proprietaire/gerer-par-le-proprietaire/parametres"
          //     ) {
          //       navigate("/proprietaire/gerer-par-le-proprietaire/parametres");
          //     }

          //      if (
          //        isProprietaireAgence(user) &&
          //        location?.pathname !==
          //          "/proprietes/gerer-par-agence/parametres"
          //      ) {
          //        navigate("/proprietes/gerer-par-agence/parametres");
          //      }

          //     if (isAgence(user) && location?.pathname !== "/agence/option") {
          //       navigate("/agence/option");
          //     }
          //   }
          // });
        }
      }
    }
  }, [user, location]);

  return (
    <div>
      {(isAgence(user) || isProprietaireOwner(user)) && <CheckUserAbonnement />}

      <InfosPaiementModal />
      <ChangePasswordModal />
    </div>
  );
}
