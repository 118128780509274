/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Swal from "sweetalert2";
import { DepenseFormData } from "../../../../../utils/api/depense/depense.type";
import { useAddOrUpdateDepenseMutation } from "../../../../../utils/api/depense/depense.api";
import { Color } from "../../../../../utils/theme";
import {
  cleannerError,
  isProprietaireOwner,
  onHide,
} from "../../../../../utils/Utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLazyGetLocataireByProprieteSlugQuery } from "../../../../../utils/api/locataire/locataire.api";
import {
  useGetLocataireByAgenceBySlugQuery,
  useGetProprieteComptaByAgenceBySlugQuery,
} from "../../../../../utils/api/agence/agence.api";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { log } from "console";

function UseCrudSortieForm(modalId: string, depense?: DepenseFormData) {
  const validationSchema = yup.object().shape({
    montant: yup.string().required().label("Montant").nullable(),
    propriete: yup.string().required().label("Villa").nullable(),
    categorie: yup.string().label("Catégorie").nullable(),
    locataire: yup.string().label("Client").nullable(),
    justificatif: yup.mixed().label("Justificatif").nullable(),
    charge: yup.string().label("Charge").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<DepenseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [message, setMessage] = useState<string>("");
  const [options, setOptions] = useState<any>([]);
  const [option, setOption] = useState<any>();

  const [addOrUpdateDepense, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateDepenseMutation();

  const [getLocataires, { data: locataire = { results: [] } }] =
    useLazyGetLocataireByProprieteSlugQuery();
  const { user } = useAppSelector((s) => s?.user);

  const [locataires, setLocataires] = useState<
    { label: string; value: number }[] | undefined
  >([]);

  const { data: locatAgence } = useGetLocataireByAgenceBySlugQuery({
    slug: user?.slug,
  });

  const { data: proprieteAgence } = useGetProprieteComptaByAgenceBySlugQuery({
    slug: user?.slug,
    limit: 10000,
  });
  const [locataireItem, setLocataireItem] = useState<any>();

  useEffect(() => {
    if (user?.user_type === "agence") {
      console.log({ locatAgence });

      // console.log(locataire, "agence");
      if (locatAgence?.results?.length) {
        setLocataires(
          locatAgence?.results
            ?.filter((item: any) => item?.propriete?.id === option?.id)
            ?.map((el: any) => {
              return {
                label: el?.prenom + " " + el?.nom,
                value: el?.id,
                ...el,
              };
            })
        );

        let locataireEl: any = locatAgence?.results?.find(
          (item: any) => item?.propriete?.id === option?.id
        );
        if (locataireEl) {
          setValue("locataire", locataireEl?.id);
          setLocataireItem({
            label: locataireEl?.prenom + " " + locataireEl?.nom,
            value: locataireEl?.id,
            ...locataireEl,
          });
        } else {
          setValue("locataire", "");
          setLocataireItem("");
        }
      } else {
        setValue("locataire", "");
        setLocataires([]);
        setLocataireItem("");
      }
    }
    console.log("locataire:" + locataireItem);
  }, [option, locataire?.results?.length]);

  useEffect(() => {
    if (!isProprietaireOwner(user)) {
      const propriete = proprieteAgence?.results?.filter(
        (item) => item?.type_propriete !== "immeuble"
      );
      setOptions(
        propriete?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
            ...item,
          };
        })
      );
      //   setFilterProperties(propriete);
    }
    // else {
    //   const propriete = biens?.results?.filter(
    //     (item) => item?.type_propriete !== "immeuble"
    //   );
    //   setOptions(
    //     propriete?.map((item) => {
    //       return {
    //         label: item?.nom,
    //         value: item?.id,
    //         ...item,
    //       };
    //     })
    //   );
    //   setFilterProperties(propriete);
    // }
  }, [proprieteAgence?.results]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: depense
          ? "Dépense modifiée avec succès !"
          : "Dépense ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!depense) {
          reset();
          setMessage("");
          setOption("");
          setLocataireItem("");
        }
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handleChangePropriete = (e: any) => {
    setOption(e);
    setValue("propriete", e?.value);
    if (e?.value) {
      getLocataires({ slug: e?.slug });
    }
  };

  const handleChangeLocataire = (e: any) => {
    setValue("locataire", e?.value);
  };

  const handleJustificatif = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif" &&
      file?.type !== "application/pdf"
    ) {
      return setError("justificatif", {
        message: "Image format is incorrect.",
      });
    }
    if (file) {
      register("justificatif");
      setValue("justificatif", file);
    }
  }, []);
  const onSubmit = (data: any) => {
    if (!depense) {
      data["agence"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
      data["charge"] = "agence";
    }
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data submitted", data);
    addOrUpdateDepense({ slug: depense?.slug, data: fd });
  };

  const handleReset = () => {
    if (!depense) {
      reset();
    }
  };

  useEffect(() => {
    if (depense?.id) {
      const fields: (keyof DepenseFormData)[] = [
        "montant",
        "categorie",
        "propriete",
        "locataire",
        "specification",
        "numero",
        "charge",
      ];
      // console.log("fields");
      for (let field of fields) {
        register(field);
        if (
          field === "categorie" ||
          field === "locataire" ||
          field === "propriete" ||
          field === "charge"
        ) {
          setValue(field, depense[field]?.id);
        } else {
          setValue(field, depense[field]);
        }
      }
      if (depense?.propriete?.id) {
        getLocataires({ slug: depense?.propriete?.slug });
        setOption({
          label: depense?.propriete?.nom,
          value: depense?.propriete?.id,
          ...depense?.propriete,
        });
      }
    }
  }, [depense]);

  const handleChangeMessage = useCallback((value: string) => {
    setMessage(value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    message,
    handleChangeMessage,
    handleReset,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes: proprieteAgence?.results,
    Controller,
    control,
    option,
    options,
    locataireItem,
    handleChangeLocataire,
  };
}

export default UseCrudSortieForm;
