import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai';
import EntreeComptabiliteTableData from '../../../agence/EntreeComptabiliteTable/EntreeComptabiliteTableData';
import EntreeComptabiliteTableSkeleton from '../../../agence/EntreeComptabiliteTable/EntreeComptabiliteTableSkeleton';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdEdit } from 'react-icons/md';
import { useGetComptabiliteByProprietaireBySlugQuery } from '../../../../../utils/api/proprietaire/proprietaire.api';
import { useAppSelector } from '../../../../../redux/hooks';
import { formatMontant, formattedDate, getName } from '../../../../../utils/Utils';
import { AlertInfo } from '../../../../common/Alert';
import AjoutEntreePQGSBModal from '../../../../modal/proprietaireQGSB/AjoutEntreePQGSBModal';
import DetailsEntreeModal from '../../../../modal/agence/DetailsEntreeModal';
// import DetailEntreeModal from '../../../../modal/comptabilite/DetailEntreeModal';

function EntComptabiliteTablePQGSB() {
    const [page, setPage] = useState(EntreeComptabiliteTableData);

	const user = useAppSelector((s) => s.user.user);
	const { data: compta, isLoading } =
    useGetComptabiliteByProprietaireBySlugQuery({
      slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
    });

	const actionFormatter = (cell: any, row: any) => {
		return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#AjoutEntreePQGSBModal${row?.slug}`}
            >
              <MdEdit />
            </button>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#detailsEntreeModal${row.slug}`}
            >
              <AiFillEye />
            </button>
            <div
              className="modal fade"
              id={`AjoutEntreePQGSBModal${row?.slug}`}
              aria-labelledby="AjoutEntreePQGSBModalLabel"
              aria-hidden="true"
            >
              <AjoutEntreePQGSBModal
                modalId={`AjoutEntreePQGSBModal${row?.slug}`}
                entree={row}
              />
            </div>
            <div>
              {/* <DetailsEntreeModal entree={row} /> */}
              <div
                className="modal fade"
                id={`detailsEntreeModal${row?.slug}`}
                aria-labelledby={`detailsEntreeModal${row?.slug}Label`}
                aria-hidden="true"
              >
                <DetailsEntreeModal
                  item={row}
                  modalId={`detailsEntreeModal${row?.slug}`}
                  entree
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
	};
	const columns = [
		{
			dataField: "id",
			text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
		},
		{
			dataField: "created_at",
			text: "Date",
			formatter: (cell: any, row: any) => formattedDate(cell),
		},
		{
			dataField: "fullname",
			text: "Client",
			formatter: (cell: any, row: any) => getName(row?.locataire),
		},
        {
			dataField: "montant",
			text: "Montant",
			formatter: (cell: any, row: any) => formatMontant(cell),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			// headerStyle: () => {
			// 	return { width: "130px" };
			// },
		},
	];

	return (
		<>
			{isLoading && <EntreeComptabiliteTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={compta?.entrees || []}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message='Pas de données' />}
					/>
				</>
			)}
		</>
	);
}

export default EntComptabiliteTablePQGSB