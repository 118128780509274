import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import DepenseComptabiliteTableData from "../../../agence/DepenseComptabiliteTable/DepenseComptabiliteTableData";
import DepenseComptabiliteTableSkeleton from "../../../agence/DepenseComptabiliteTable/DepenseComptabiliteTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdEdit } from "react-icons/md";
import { useGetComptabiliteByProprietaireBySlugQuery } from "../../../../../utils/api/proprietaire/proprietaire.api";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  formatMontant,
  formattedDate,
  getName,
} from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import AjouterDepenseModal from "../../../../modal/comptabilite/AjouterDepenseModal";
import DetailDepenseModal from "../../../../modal/comptabilite/DetailDepenseModal";
import AjoutDepensePQGSBModal from "../../../../modal/proprietaireQGSB/AjoutDepensePQGSBModal";

function DepComptabiliteTablePQGSB() {
  const [page, setPage] = useState(DepenseComptabiliteTableData);
  const user = useAppSelector((s) => s.user.user);
  const { data: compta, isLoading } =
    useGetComptabiliteByProprietaireBySlugQuery({
      slug: user?.proprietaire_id ? user?.proprietaire_id?.slug : user?.slug,
    });

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal">
            {user?.a_un_comptable === false && (
              <button
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Modifier"
                data-bs-toggle="modal"
                data-bs-target={`#editDepensePQGSBModal${row.slug}`}
              >
                <MdEdit />
              </button>
            )}
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#DetailDepenseModal${row.slug}`}
            >
              <AiFillEye />
            </button>
            <div
              className="modal fade"
              id={`editDepensePQGSBModal${row?.slug}`}
              aria-labelledby="editDepensePQGSBModalLabel"
              aria-hidden="true"
            >
              <AjoutDepensePQGSBModal
                modalId={`editDepensePQGSBModal${row?.slug}`}
                depense={row}
              />
            </div>
            <div>
              <DetailDepenseModal depense={row} />
            </div>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => formattedDate(cell),
    },
    {
      dataField: "fullname",
      text: "Client",
      formatter: (cell: any, row: any) => getName(row?.locataire),
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: any, row: any) => formatMontant(cell),
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <DepenseComptabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={compta?.depenses || []}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => <AlertInfo message="Pas de données" />}
          />
        </>
      )}
    </>
  );
}

export default DepComptabiliteTablePQGSB;
