import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiMenuAlt1 } from "react-icons/hi";
import { RiArrowDropDownFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/kanimmoLogoNoir.png";
import logoHeader from "../../assets/logoHeader.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { onlogout } from "../../redux/slice/User.slice";
import { UserType } from "../../utils/api/user/user.type";
import {
  getAvatar,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isProprietaireOwner,
} from "../../utils/Utils";
import { useGetNewsForVisitorsQuery } from "../../utils/api/new/news.api";
import { isAdminAgent } from "../../routes/router";
import RegisterModal from "../modal/user/RegisterModal";
import { useMarkUserOflineMutation } from "../../utils/api/auth/auth.api"



var EmptyHeader = () => {
  const isConnected = useAppSelector((s) => !!s.user?.user?.id);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const usertype = user?.user_type;
  const { data, isLoading } = useGetNewsForVisitorsQuery({});

  const [markUserOfline] = useMarkUserOflineMutation();


  const handleLogout = async () => {
    try {
      await markUserOfline({ slug: user?.slug });
      dispatch(onlogout());
      window.location.pathname = "/connexion";
    } catch (error) {
      console.error("Error marking user offline:", error);
    }
  };
  
  return (
    <div className="empty-fluidy">
      <div className="empty-headerlp landing-page-header">
        <div className="logo-item">
          <div className="logo-container dashboard-header-logo">
            <NavLink className="navbar-brand" to="/">
              <img
                src={Logo}
                alt="data-gouvernance"
                className="app-logo-admin m-0"
                style={{ width: '150px', height: '150px'  }}
              />
            </NavLink>
          </div>
        </div>
        <div className="menu-landingpage-container me-auto ms-auto">
          <ul className="navbar-nav navbar-nav-header-landing-page me-auto mb-2 mb-lg-0">
            <li className="nav-item nav-item-li">
              <NavLink to="/services" className="nav-link nav-link-link">
                Services
              </NavLink>
            </li>
            {data?.results?.length && data?.results?.length > 0 ? (
              <li className="nav-item nav-item-li">
                <NavLink className="nav-link nav-link-link" to="/news">
                  News
                </NavLink>
              </li>
            ) : (
              ""
            )}
             {/* <li className="nav-item nav-item-li">
              <NavLink to="/offres" className="nav-link nav-link-link">
                Offres
              </NavLink>
            </li> */}
            {/* <li className="nav-item nav-item-li">
                <NavLink className="nav-link nav-link-link" to="/a-propos">
                  À propos
                </NavLink>
              </li> */}
          </ul>
        </div>
        {!isConnected ? (
          <>
            <div className="auth-btn-container no-view-mobile">
              <div className="login-btn-container">
                <NavLink
                  to="/connexion"
                  className="btn btn-default ldpg-login-btn auth-btns"
                >
                  Connexion
                </NavLink>
              </div>
              <div className="login-btn-container">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#RegisterModal"
                  className="btn btn-default ldpg-register-btn auth-btns"
                >
                  Inscription
                </button>
              </div>
            </div>

            <div className="connected-user-container no-view-desktop">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="login-btn-container dropdown-toggle custom-login-btn-container"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display">
                      <span className="navbar-toggler-icon">
                        <HiMenuAlt1 />
                      </span>

                      <FiChevronDown className="no-view-mobile" />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="login-btn-container">
                      <NavLink
                        to="/connexion"
                        className="btn btn-default ldpg-login-btn auth-btns"
                      >
                        Connexion
                      </NavLink>
                    </div>
                    <div className="login-btn-container">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#RegisterModal"
                        className="btn btn-default ldpg-register-btn auth-btns"
                      >
                        Inscription
                      </button>
                    </div>
                    {/* <div className="dropdown-divider"></div>
                                        <button
                                            type="button"
                                            className="dropdown-item "
                                            onClick={() => {
                                                dispatch(onlogout());
                                                window.location.pathname = "/login";
                                            }}
                                        >
                                            {" "}
                                            Deconnexion
                                        </button> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="connected-user-container">
            <div className="connected-user-content ml-auto">
              <div className="dropdown dis-flex">
                <button
                  className="user-acount-dashboard-header dropdown-toggle btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-acount-dashboard-header-display g-2">
                    <img
                      src={getAvatar(user?.avatar)}
                      className="user-avatar"
                      alt="User Avatar"
                    />
                    <span className="user-name-connect px-2 no-view-mobile">
                      {`${user?.prenom}`}
                    </span>
                    <RiArrowDropDownFill />
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-custom"
                  aria-labelledby="dropdownMenuButton"
                >
                  {usertype !== UserType.admin &&
                  usertype !== UserType.superAdmin ? (
                    <>
                      <NavLink
                        to={
                          usertype === UserType.agence ||
                          isAgenceAgent(user) ||
                          isAgenceJuriste(user) ||
                          isAgenceMarketeur(user)
                            ? "/agence/dashboard"
                            : usertype === UserType.locataire
                            ? "/locataire/mon-loyer"
                            : usertype === UserType.comptable
                            ? "/comptable/dashboard"
                            : isProprietaireOwner(user)
                            ? "/proprietaire/gerer-par-le-proprietaire/dashboard"
                            : isAdminAgent(user)
                            ? "/admin/dashboard"
                            : "/proprietes/gerer-par-agence/dashboard"
                        }
                        className="dropdown-item "
                      >
                        Profil
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item "
                        onClick={handleLogout}
                      >
                        Deconnexion
                      </button>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to={"/admin/dashboard"}
                        className="dropdown-item "
                      >
                        Profil
                      </NavLink>
                      <div className="dropdown-divider"></div>
                      <button
                        type="button"
                        className="dropdown-item "
                        onClick={handleLogout}
                      >
                        Deconnexion
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="RegisterModal"
        aria-labelledby="RegisterModalLabel"
        aria-hidden="true"
      >
        <RegisterModal />
      </div>
    </div>
  );
};

export default EmptyHeader;
