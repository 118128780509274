import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ListeLocatairePQGSBSkeleton from "./ListeLocatairePQGSBSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdEdit } from "react-icons/md";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetLocataireByProprietaireBySlugQuery } from "../../../../utils/api/proprietaire/proprietaire.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import { isProprioJuriste } from "../../../../utils/Utils";
import { GeneratePassword } from "../../agence/ProprietaireAgenceTable/ProprietaireAgenceTable";

function ListeLocataireTablePQGSB({
  name,
  uptodate,
}: {
  name?: string;
  uptodate?: string;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data = { results: [] }, isLoading } =
    useGetLocataireByProprietaireBySlugQuery({
      page,
      limit: perPage,
      slug: user?.proprietaire_id?.slug
        ? user?.proprietaire_id?.slug
        : user?.slug,
      name,
      uptodate,
    });
  // console.log("donnée locataire", data?.results)

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <NavLink
              to={`/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/${row?.slug}`}
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
            >
              <MdEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <GeneratePassword slug={row?.slug} />
          </div>
        </div>
      </>
    );
  };

  const profilFormatter = (cell: any, row: any) => {
    return (
      <>
        <NavLink
          to={`/proprietaire/gerer-par-le-proprietaire/locataire/${row?.slug}`}
          state={row}
          className="no-link"
          style={{ color: "#1AA981", textAlign: "left", fontWeight: "600" }}
        >
          Voir le profil
        </NavLink>
      </>
    );
  };

  const litigeFormatter = (cell: any, row: any) => {
    return (
      <>
        {cell === "expulsion" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-expulsion"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Expulsion </option>
                <option value="1">Jugement</option>
                <option value="2">Commandement de payer</option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "jugement" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-jugement"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Jugement</option>
                <option value="1">Expulsion </option>
                <option value="2">Commandement de payer</option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "commandement_payer" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-commandement_payer"
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Commandement de payer
                </option>
                <option value="1">Jugement</option>
                <option value="2">Expulsion </option>
                <option value="3">Aucun contentieux</option>
              </select>
            </form>
          </div>
        )}
        {cell === "aucun_litige" && (
          <div className="table-actions-btn-container-commandes d-flex">
            <form>
              <select
                className="form-select form-select-table-aucun_litige"
                aria-label="Default select example"
              >
                <option value="" selected disabled>
                  Sélectionner un contentieux
                </option>
                <option selected>Aucun contentieux</option>
                <option value="1">Commandement de payer</option>
                <option value="2">Jugement</option>
                <option value="3">Expulsion </option>
              </select>
            </form>
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
    },
    {
      dataField: "nom",
      text: "Nom",
      formatter: (cell: any, row: any) => row?.prenom + " " + row?.nom,
    },
    {
      dataField: "adresse",
      text: "Adresse",
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell: any, row: any) => (
        <a className="no-link" href={`mailto:${cell}`}>
          {cell}
        </a>
      ),
      // formatter: (cell: any, row: any) => responsabiliteFormatter(cell, row),
      // style: { textAlign: "center" },
    },
    {
      dataField: "profil",
      text: "Profil",
      formatter: (cell: any, row: any) => profilFormatter(cell, row),
      style: { color: "#1AA981", textAlign: "left" },
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      hidden: isProprioJuriste(user) ? true : false,
    },
  ];

  return (
    <>
      {isLoading && <ListeLocatairePQGSBSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
            nbPages={data.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ListeLocataireTablePQGSB;
