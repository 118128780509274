import React from 'react'
import { AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { BsGlobe, BsTelephone } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { RiSendPlaneFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/hooks';
import { useGetBailleurInfosBySlugQuery } from '../../../../../utils/api/locataire/locataire.api';
import { IProprietaire } from '../../../../../utils/api/proprietaire/proprietaire.type';
import { getAvatar, getImage, useLocationState } from '../../../../../utils/Utils';
import './MonAgenceOuProprietaire.css'

function MonProprietaire() {

    const itemState = useLocationState<IProprietaire>(undefined);
    // console.log("agence", itemState)
    const locataire = useAppSelector((s) => s.user.user)
    const { data: bailleur, isLoading } = useGetBailleurInfosBySlugQuery(locataire?.slug);
    // console.log("bailleur proprio", bailleur)
  return (
    <div className="kanimmo-dash-locataire-param-main-page-container py-3">
      <div className="container-img-option-agence position-relative mb-4 custom-info-bailleur-container">
        <div className="container-img-couverture-option-agence">
          {/* <div className="content-btn-edit-img-couverture custom-content-btn-edit-img-couverture">
            <NavLink
              className="btn-message-img-option-agence custom-btn-message-img-option-agence"
              to={'/locataire/messages'}
            >
              Message Instantané
            </NavLink>
          </div> */}
        <div className="container-logo-option-agence custom-container-logo-option-agence">
          <div className="content-logo">
            <img
              src={getAvatar(locataire?.agence_id  ? bailleur?.logo : bailleur?.avatar)}
              alt="pp couverture"
              className="logo-agence-custome custom-logo-agence-custome"
            />
          </div>
        </div>
        </div>
        
      </div>
      <div className="container-infos-bailleur">
          <div className="kanimmo-infos-bailleur-titre-description-container">
            <h3 className='kanimmo-infos-bailleur-titre'>{bailleur?.nom_agence}</h3>
            {bailleur?.description && <p className='kanimmo-infos-bailleur-description'>{bailleur?.description}</p>}
            <div className="row pt-3">
              {bailleur?.prenom && <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Nom</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <AiOutlineUser className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'>{bailleur?.prenom + " " + bailleur?.nom}</span>
                  </div>
                </div>
              </div>}
              {bailleur?.adresse && <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Adresse</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <IoLocationOutline className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'>{bailleur?.adresse}</span>
                  </div>
                </div>
              </div>}
              {bailleur?.telephone && <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Téléphone</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <BsTelephone className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'><a className='no-link' href={`tel:${bailleur?.telephone}`}>{bailleur?.telephone}</a></span>
                  </div>
                </div>
              </div>}
              {bailleur?.email && <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Email</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <AiOutlineMail className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'><a className='no-link' href={`mailto:${bailleur?.email}`}>{bailleur?.email}</a></span>
                  </div>
                </div>
              </div>}
              {bailleur?.site && <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Site web</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <BsGlobe className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'><a className='no-link' href={`${bailleur?.site}`}  target="_blank">{bailleur?.site}</a></span>
                  </div>
                </div>
              </div>}
              <div className="col-md-4">
                <div className='adress-info-container mb-3'>
                  <div className='adress-infos-title mb-2'>Message Instantané</div>
                  <div className='adress-infos-icon-container'>
                    <div className='adress-infos-icon'>
                      <RiSendPlaneFill className='kanimmo-adress-infos-icon' />
                    </div>
                    <span className='kanimmo-adress-infos-span'><NavLink className="no-link" to={'/locataire/messages'}>Envoyer un message</NavLink></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MonProprietaire
