import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { RappelPaiementFormData } from "../../../../utils/api/rappelPaiement/rappelPaiement.type";
import { useAddRappelPaiementProprietaireMutation } from "../../../../utils/api/rappelPaiement/rappelPaiement.api";
import { cleannerError, onHide } from "../../../../utils/Utils";
import { useAppSelector } from "../../../../redux/hooks";
import { Color } from "../../../../utils/theme";

function useRappelPaiementProprietaire(modalId: string, locataireData: any) {
  const validationSchema = yup.object().shape({
    url_paiement: yup.string().label("Url de paiement"),
    message: yup.string().required().label("Message"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<RappelPaiementFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<string>("");

  const [addRappelPaiement, { isLoading, isSuccess, error, isError }] =
    useAddRappelPaiementProprietaireMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Rappel envoyé avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        onHide(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

  const onSubmit = (data: any) => {
    data["locataire"] = locataireData?.id || locataireData.id_locataire;
    data["proprietaire"] = userID;
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    // console.log("data submitted", data);
    addRappelPaiement(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
  };
}

export default useRappelPaiementProprietaire;
