import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import ConfirmAddProprietaire from "../../../modal/agence/ConfirmAddProprietaire";
import { useCrudProprietaire } from "./useForm/useCrudProprietaire";
import { Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import GoogleInput from "../../../common/GoogleInput";
import {
  CountrySelector,
  StateSelector,
} from "volkeno-react-country-state-city";
import ErrorMessage from "../../../common/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import { FormError } from "../../../common/CustomInputCheckbox";
import AgenceDocumentContratModal from "../../../modal/agence/AgenceDocumentContratModal";
import { useAppSelector } from "../../../../redux/hooks";

function AjouterProprietaire() {
  const { item } = useCrudProprietaire();

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <NavLink
            to="/agence/proprietaires"
            className="kanimmo-dash-locataire-requetes-header-titre no-link"
          >
            <BiArrowBack />
            <span className="ps-3">
              {item ? "Modifier" : "Ajouter"} un propriétaire
            </span>
          </NavLink>
        </div>
        <div className="kanimmo-proprietes-container">
          <div className="proprietaire-component-container">
            <div className="content-ajouter-proprietaire locataire-requete-filtre-container py-3">
              <div className="content-title-ajouter-proprietaire">
                <h4 className="title-ajouter-proprietaire">
                  Informations générales
                </h4>
              </div>
              <FormAjoutProprietaire redirectPath="/agence/proprietaires" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export const FormAjoutProprietaire = ({
  redirectPath,
}: {
  redirectPath: string;
}) => {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    modalIsOpen,
    setIsOpen,
    proprietaire,
    item,
    address,
    onChangeAddress,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    setValue,
    setPhone,
    phone,
    type,
    handleChangeType,
  } = useCrudProprietaire();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const userType = user?.user_type;

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);
  const est_gerer_par_proprio_agence = item?.est_gerer_par_proprio_agence;

  return (
    <div className="pt-4 pb-5">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12 ">
            <div className="mb-3">
              <label className="form-label form-label-modal-custom">
                Êtes-vous une personne physique ou morale ?
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="agence_id"
                className="form-select form-select-modal-custom mb-2"
                value={type}
                onChange={(e) => handleChangeType(e)}
              >
                <option value="">Choisir</option>

                <option value="physique">Personne physique</option>
                <option value="morale">Personne morale</option>
              </select>
              {errors?.type_proprietaire && (
                <ErrorMessage message={errors?.type_proprietaire?.message} />
              )}
            </div>
          </div>
          {!!type && (
            <>
              {type !== "morale" ? (
                ""
              ) : (
                <>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Nom de la structure"
                      id="nom_structure"
                      placeholder="Nom de la structure"
                      required
                      {...register("nom_structure")}
                      error={errors?.nom_structure}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Ninea"
                      id="ninea"
                      placeholder="Ninea"
                      required
                      {...register("ninea")}
                      error={errors?.ninea}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Registre de commerce"
                      id="registre"
                      placeholder="Registre de commerce"
                      required
                      {...register("registre")}
                      error={errors?.registre}
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="tel"
                        className="form-label form-label-modal-custom"
                      >
                        Mandat de location (pdf/word/excel)
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-modal-custom"
                        id="contrat_de_bail"
                        onChange={(e) => {
                          let file = e?.target?.files && e?.target?.files[0];
                          if (file) {
                            setValue("contrat", file);
                          }
                        }}
                        // {...register("contrat")}
                        accept="application/msword, application/vnd.ms-excel, application/pdf"
                      />
                      {item?.contrat ? (
                        <button
                          className="custom-btn-voir-contrat btn"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#DocumentContratModal${item?.slug}`}
                        >
                          Afficher le contrat
                        </button>
                      ) : null}
                      <div
                        className="modal fade"
                        id={`DocumentContratModal${item?.slug}`}
                        aria-labelledby="DocumentContratModalLabel"
                        aria-hidden="true"
                      >
                        <AgenceDocumentContratModal item={item} />
                      </div>
                      {errors?.contrat && (
                        <ErrorMessage message={errors?.contrat?.message} />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Prénom"
                  id="prenom"
                  placeholder="Prénom"
                  required
                  {...register("prenom")}
                  error={errors?.prenom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  placeholder="Nom"
                  required
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="Email"
                  required
                  {...register("email")}
                  error={errors?.email}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="phone"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="phone"
                  >
                    Téléphone
                    <span
                      ref={ref}
                      className="text-danger"
                      style={{ fontSize: 24 }}
                    >
                      *
                    </span>
                  </label>
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control form-control-modal-custom mb-3 p-0"
                    inputClass="form-control form-control-modal-custom"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      setPhone(phone);

                      if (
                        country?.format?.match(/\./g)?.length === phone?.length
                      ) {
                        setValue("telephone", phone);
                      }
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                    }}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="adress"
                    className="form-label form-label-modal-custom"
                  >
                    Adresse
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    value={address}
                    onChange={onChangeAddress}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="pays"
                    className="form-label form-label-modal-custom"
                  >
                    Pays{" "}
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    placeholder="Pays"
                    value={country}
                    types={["country"]}
                    onChange={handleCountrySelect}
                  />

                  {errors?.pays && <FormError error={errors?.pays?.message} />}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="region"
                    className="form-label form-label-modal-custom"
                  >
                    Région{" "}
                  </label>
                  <GoogleInput
                    className="form-control form-control-modal-custom"
                    placeholder="Région"
                    value={state}
                    types={[
                      "administrative_area_level_1",
                      "administrative_area_level_2",
                    ]}
                    onChange={handleStateSelect}
                  />

                  {errors?.region && (
                    <FormError error={errors?.region?.message} />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Code postale"
                  id="boite_postale"
                  placeholder="Code postale"
                  {...register("code_postal")}
                  error={errors?.code_postal}
                />
              </div>
              <div className="col-md-6">
                {(userType === "superadmin" || userType === "admin") &&
                  est_gerer_par_proprio_agence === false && (
                    <Input
                      type="date"
                      label="Date de fin"
                      id="boite_postale"
                      {...register("date_fin")}
                    />
                  )}
              </div>
            </>
          )}
        </div>
        <div className="container-btn-modal row my-4">
          <div className="col-md-4 offset-md-4">
            <button
              className="btn auth-submit-annuler"
              type="button"
              onClick={() => navigate(-1)}
            >
              Annuler
            </button>
          </div>
          <div className="col-md-4">
            <BtnSubmit
              label={item ? "Modifier" : "Ajouter"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
      <ConfirmAddProprietaire
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        proprietaire={proprietaire}
        item={item}
        redirectPath={redirectPath}
      />
    </div>
  );
};
export default AjouterProprietaire;
