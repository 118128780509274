/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IoMdTrash } from "react-icons/io";
import word from "../../../../../../assets/appImages/word.png";
import ContratSkeletonPQGSB from "./ContratSkeletonPQGSB";
import ContratDatasPQGSB from "./ContratDatasPQGSB";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useGetContratByProprietaireQGSBQuery } from "../../../../../../utils/api/contrat/contrat.api";
import { CustomPagination } from "../../../../../common/CustomPagination";
import { IContrat } from "../../../../../../utils/api/contrat/contrat.type";
import { formattedDate, isProprioJuriste } from "../../../../../../utils/Utils";
import ViewDocModal from "../../../../../modal/proprietaireGPA/ViewDocModal";

function ContratTablePQGSB({ name }: { name?: any }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const proprietaire = useAppSelector((s) => s.user.user);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetContratByProprietaireQGSBQuery({
      name,
      page: page,
      limit: perPage,
      slug: proprietaire?.proprietaire_id?.slug
        ? proprietaire?.proprietaire_id?.slug
        : proprietaire?.slug,
    });

  const [item, setItem] = useState<IContrat>();
  // console.log("data", data);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div>
            <button className="btn btn-action-administratif-proprietaire">
              <IoMdTrash />
            </button>
          </div>
        </div>
      </div>
    );
  };
  const documentFormatter = (cell: any, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <div>
            <img
              src={word}
              alt="img type document"
              className="kanimmo-img-type-document"
            />{" "}
            Contrat de location
          </div>
        </div>
      </div>
    );
  };
  const voirContratFormatter = (
    cell:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    row: any
  ) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes gap-2 d-flex">
          <div>
            <div
              className="linkable"
              data-bs-toggle="modal"
              data-bs-target={`#ViewDocModal${row?.slug}`}
              onClick={() => setItem(row)}
            >
              Voir le contrat
            </div>
          </div>
        </div>
        <ViewDocModal item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "numero",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
      style: { textAlign: "left" },
      headerStyle: () => {
        return { width: "50px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "nom_document",
      text: "Nom du document",
      formatter: (cell: any, row: any) => documentFormatter(cell, row),
    },
    {
      dataField: "nom_locataire",
      text: "Nom du locataire",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) =>
        row?.locataire?.prenom + " " + row?.locataire?.nom,
    },
    {
      dataField: "created_at",
      text: "Date de validation",
      style: { textAlign: "left" },
      formatter: (cell: any, row: any) => formattedDate(cell),
    },

    {
      dataField: "decouvrir",
      text: "Découvrir",
      style: { color: "#1AA981", textAlign: "left" },
      formatter: (cell: any, row: any) => voirContratFormatter(cell, row),
    },
    {
      dataField: "actions",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", whiteSpace: "normal" };
      },
      hidden: isProprioJuriste(proprietaire) ? true : false,
    },
  ];

  return (
    <>
      {isLoading && <ContratSkeletonPQGSB />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive
            wrapperClasses="table-responsive locataire-table"
          />

          <CustomPagination
            nbPages={8}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </>
      )}
    </>
  );
}

export default ContratTablePQGSB;
