import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { useStepType } from "../../../../utils/type";
import HistoriquePaiementStep from "../../locataire/Dashboard/Locataire/LocataireSteps/HistoriquePaiementStep";
// import HistoriqueRequetesStep from "../../locataire/Dashboard/Locataire/LocataireSteps/HistoriqueRequetesStep";
import { useStep } from "react-hooks-helper";
import Aside from "../../locataire/Dashboard/Locataire/Aside";
import HistoriqueTappelStep from "../../locataire/Dashboard/Locataire/LocataireSteps/HistoriqueTappelStep";

const steps = [
  { id: "historique-paiement", Component: HistoriquePaiementStep },
  { id: "historique-rappel", Component: HistoriqueTappelStep },
];

const tabs = ["Historique paiements", "Historique des rappels"];
function DetailVillasImmeubles() {
  const navigate = useNavigate();

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4
            className="kanimmo-dash-locataire-requetes-header-titre no-link linkable"
            onClick={() => navigate(-1)}
          >
            <AiOutlineArrowLeft />
            <span className="ps-2">Locataires</span>
          </h4>
        </div>
        <div className="kanimmo-tabs-container mt-2">
          <div className="tabs-component-container">
            <div className="row">
              <div className="col-md-4">
                <Aside />
              </div>
              <div className="col-md-8">
                <div className="kanimmo-tabs-container py-3">
                  <ul className="tabs-container-historique">
                    {tabs.map((tab, key) => (
                      <li
                        className={`tab-item  ${
                          index === key && "tab-active-item"
                        }`}
                        key={key}
                        onClick={() => go(key)}
                        role="button"
                      >
                        {tab}
                      </li>
                    ))}
                  </ul>
                  <div className="tabs-component-container">
                    <Component />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailVillasImmeubles;
