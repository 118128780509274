/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { ApiKey } from "../../../utils/constant";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl, AppBaseUrl, Env } from "../../../utils/http";
import { Offer } from "../../../utils/api/offer/offer.type";
import {
  getInTechPhone,
  getInTechTel,
  onHide,
  onShow,
} from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import { usePayOfferMutation } from "../../../utils/api/user/user.api";
import moment from "moment";

function UsePayAbonnementForm() {
  const { user } = useAppSelector((s) => s?.user);
  const [onLoading, setOnLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [seconds, setSeconds] = useState<any>(20);
  const [infosPayment, setInfosPayment] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<Offer>();
  let withPaiement = true;
  const [mode, setMode] = useState<string>("");
  const [sendData] = usePayOfferMutation();
  const [disabled, setDisabled] = useState(false);


  useEffect(() => {
    if (isFetching) {
      const timer =
        seconds > 0 ? setInterval(() => setSeconds(seconds - 1), 1000) : 0;

      return () => clearInterval(timer);
    }
  }, [seconds, isFetching]);



  const onCreatePayment = async (apiCash: string) => {
    let data = {
      user: user?.id,
      offre: user?.offre?.id,
      montant: user?.offre?.montant,
      date: moment(new Date()).format("YYYY-MM-DD"),
      mode_paiement: apiCash,
    };

    // registerAgence(fd);
    const res = await sendData(data);
    console.log(res, "payment res");
    if ("data" in res) {
      return res.data;
    } else {
      throw res.error;
    }
  };

  const onPressSubmit = async (apiCash: string, data: any) => {
    setMode(apiCash);
    setLoading(true);
    try {
      let externalTransactionId: number = new Date().getTime();
      const payment = await onCreatePayment(apiCash);
      if (withPaiement) {
        console.log("payment", payment);
        externalTransactionId = payment?.slug as unknown as number;
      }
      let amount = Env !== "dev" ? user?.offre?.montant : 5;
      if (amount === 10 && apiCash === "BANK_CARD_API_CASH_OUT") {
        amount = 100;
      }
      const tel = user?.telephone;
      let dataSend = {
        amount: amount,
        phone: "",
        codeService: apiCash,
        externalTransactionId,
        callbackUrl: `${ApiBaseUrl}/api/mensualite_offre/callback/?offre=${user?.offre?.id}&user=${user?.id}`,
        apiKey: ApiKey,
        data: {
          env: "test",
        },
        sender: "Kanimmo",
        merchantName: "Kanimmo",
        operationDescription: "Paiement sur la plateforme Kanimmo avec paytech",
        successRedirectUrl: `${AppBaseUrl}${window.location.pathname}`,
        errorRedirectUrl: `${AppBaseUrl}${window.location.pathname}?status=error`,
        offre: offer?.id,
        ...(data ?? {}),
      };
      if (apiCash !== "BANK_CARD_API_CASH_OUT") {
        dataSend.phone = getInTechPhone(tel)?.toString() ?? "";
      } else {
        dataSend.phone = getInTechTel(tel)?.toString() ?? "";
      }
      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(dataSend),
      };

      const res = await fetch(
        "https://api.intech.sn/api-services/operation",
        requestOptions
      ).then((res) => {
        console.log("res", res);
        if (res.ok) return res.json();
        throw {
          data: { message: `Une erreur est survenue code: ${res.status}` },
        };
      });
      console.log("res", res);
      setLoading(false);
      if (res?.error) {
        let message = "";
        if (res.data) {
          for (let [k, v] of Object.entries(res.data)) {
            if (Array.isArray(v)) {
              message = `${message}\n${k}: ${v.join(", ")}`;
            }
          }
        }
        if (message) {
          // toast(message, true);
          Swal.fire({
            icon: "error",
            title: message,
            showConfirmButton: false,
            timer: 5000,
          });
        } else if (res.msg) {
          // toast(res.msg, true);
          Swal.fire({
            icon: "error",
            title: res.msg,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res?.msg
              ? res?.msg
              : `Erreur de statut code ${res?.code} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
        return false;
      } else {
        const { externalTransactionId } = res.data;
        setInfosPayment(res.data);
        onHide("ChoichePayementMethodeMensualite");
        Swal.fire({
          icon: "success",
          title: res?.msg,
          showConfirmButton: false,
          timer: 5000,
        }).then(() => {
          if (externalTransactionId) {
            onShow("verifStatutPayment");
          }
          // window.location.href = deepLinkUrl;
        });

        return true;
      }
    } catch (error: any) {
      setLoading(false);
      onHide("PaymentMethodModal");
      console.log("error", error);
      const data = error?.data as any;

      let m = data?.data?.message;
      if (!m) m = data?.message;
      if (!m && data) m = data["message "] || null;
      if (m) {
        // toast(m, true);
        Swal.fire({
          icon: "error",
          title: m,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        // toast("Une erreur est survenue", true);
        Swal.fire({
          icon: "error",
          title: `Erreur de statut ${error?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
      return false;
    }
  };

  // const onGetTransactionStatus = () => {
  //   setOnLoading(true);
  //   // 600255dc-b6c0-11ed-91c6-0242ac130003
  //   var raw = {
  //     externalTransactionId: infosPayment?.externalTransactionId,
  //   };

  //   var myHeaders = new Headers();
  //   myHeaders.append("Secretkey", ApiKey);

  //   // var requestOptions: any = {
  //   //   method: "POST",
  //   //   headers: myHeaders,
  //   //   body: raw,
  //   //   redirect: "follow",
  //   // };
  //   const requestOptions: RequestInit = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Secretkey: ApiKey,
  //     },
  //     body: JSON.stringify(raw),
  //   };

  //   fetch(
  //     "https://api.intech.sn/api-services/get-transaction-status",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result: any) => {
  //       setOnLoading(false);
  //       console.log(result);
  //       if (result?.data?.status === "SUCCESS") {
  //         setIsFetching(false);
  //         onHide("statusPaymentModal");
  //         setIsChecked(true);
  //         Swal.fire({
  //           icon: "success",
  //           title: `Paiement effectué avec succès!`,
  //           iconColor: Color.success,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         }).then(() => {
  //           //   navigate("/connexion", { replace: true });
  //         });
  //         console.log("done");
  //       } else if (result?.data?.status === "PENDING") {
  //         setIsFetching(true);
  //         Swal.fire({
  //           icon: "info",
  //           title: `Votre paiement est en attente!`,
  //           iconColor: Color.themeColor,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });
  //       } else if (result?.data?.status === "REFUNDED") {
  //         setIsFetching(false);
  //         onHide("statusPaymentModal");
  //         Swal.fire({
  //           icon: "info",
  //           title: `Votre paiement a été remboursé!`,
  //           iconColor: Color.themeColor,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });
  //       } else if (result?.data?.status === "PROCESSING") {
  //         setIsFetching(true);
  //         Swal.fire({
  //           icon: "info",
  //           title: `Votre paiement est en cours de traitement!`,
  //           iconColor: Color.themeColor,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });
  //       } else {
  //         console.log("no done");
  //         setIsFetching(false);
  //         onHide("statusPaymentModal");
  //         Swal.fire({
  //           icon: "error",
  //           title: result?.data?.errorType?.message
  //             ? result?.data?.errorType?.message
  //             : `${result?.msg}`,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       setIsFetching(false);
  //       setOnLoading(false);
  //       console.log("error", error);
  //       Swal.fire({
  //         icon: "error",
  //         title: error?.data?.message
  //           ? error?.data?.message
  //           : `Erreur de statut ${error?.status} est survenue`,
  //         showConfirmButton: false,
  //         timer: 5000,
  //       });
  //       onHide("statusPaymentModal");
  //     });
  // };

  const onGetTransactionStatus = () => {
    setOnLoading(true);
    // 600255dc-b6c0-11ed-91c6-0242ac130003
    var raw = {
      externalTransactionId: infosPayment?.externalTransactionId,
    };

    var myHeaders = new Headers();
    myHeaders.append("Secretkey", ApiKey);

    // var requestOptions: any = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Secretkey: ApiKey,
      },
      body: JSON.stringify(raw),
    };

    fetch(
      "https://api.intech.sn/api-services/get-transaction-status",
      requestOptions
    )
      .then((response) => response.json())
      .then((result: any) => {
        setOnLoading(false);
        console.log(result);
        if (result?.data?.status === "SUCCESS") {
          setIsFetching(false);
          onHide("verifStatutPayment");
          setIsChecked(true);
          Swal.fire({
            icon: "success",
            title: `Paiement effectué avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            //   navigate("/connexion", { replace: true });
          });
          console.log("done");
        } else if (result?.data?.status === "PENDING") {
          setIsFetching(true);
          Swal.fire({
            icon: "info",
            title: `Votre paiement est en attente!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (result?.data?.status === "REFUNDED") {
          setIsFetching(false);
          onHide("statusPaymentModal");
          Swal.fire({
            icon: "info",
            title: `Votre paiement a été remboursé!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (result?.data?.status === "PROCESSING") {
          setIsFetching(true);
          Swal.fire({
            icon: "info",
            title: `Votre paiement est en cours de traitement!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          console.log("no done");
          setIsFetching(false);
          onHide("statusPaymentModal");
          Swal.fire({
            icon: "error",
            title: result?.data?.errorType?.message
              ? result?.data?.errorType?.message
              : `${result?.msg}`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        setIsFetching(false);
        setOnLoading(false);
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: error?.data?.message
            ? error?.data?.message
            : `Erreur de statut ${error?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
        onHide("statusPaymentModal");
      });
  };
  return {
    isLoading: loading,
    mode,
    onLoading,
    onCheckStatus: onGetTransactionStatus,
    isChecked,
    seconds,
    onPressSubmit,
    setOffer,
    disabled,
  };
}

export default UsePayAbonnementForm;
