import { IAdmin } from "../admin/admin.type";
import { IAgence } from "../agence/agence.type";
import { ILitiges } from "../litiges/litiges.type";
import { IBail } from "../locataire/locataire.type";
import { Offer } from "../offer/offer.type";
import { IProprietaire } from "../proprietaire/proprietaire.type";
import { IPropriete } from "../propriete/propriete.type";

export enum UserType {
  admin = "admin",
  superAdmin = "superadmin",
  locataire = "locataire",
  proprietaire = "proprietaire",
  agence = "agence",
  comptable = "comptable",
  gerant = "gerant",
  juriste = "juriste",
  marketeur = "marketeur",
  visiteur = "visiteur",
  deleted = "deleted",
}

export type CiviliteType = "homme" | "femme";
export interface IUser {
  id: number;
  slug: string | any;
  email: string;
  nom: string;
  prenom: string;
  password: string;
  date_naissance: string;
  user_type: UserType;
  civilite: CiviliteType;
  telephone: string;
  avatar: string;
  adresse: string;
  password_reset_count: number;
  first_connexion: boolean;
  created_at: string;
  last_login: string;
  is_active: boolean;
  is_archive: boolean;
  app_notification: boolean;
  role: string;
  whatsapp: string;
  notification_whatsapp: string;
  username: string;
  agence_id: IAgence;
  proprietaire_id: IProprietaire | any;
  propriete: IPropriete;
  cautionnement: boolean;
  offre: Offer;
  nom_agence: string;
  logo: string;
}

export type UserFormData = Partial<{
  id: number;
  slug: string;
  nom: string;
  prenom: string;
  date_naissance: string;
  user_type: UserType;
  civilite: CiviliteType;
  telephone: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
  whatsapp: string;
  notification_whatsapp: boolean;
  username: string;
  avatar: string;
  nom_agence: string;
  adresse_agence: string;
  adresse: string;
  pays: string;
  region: string;
  code_postal: string;
  logo: string;
  banniere: string;
  total_bien_gerer: number;
  bien_gerer_totalement: number;
  bien_gerer_partiellement: number;
  a_un_comptable: boolean;
  step: number;
  site: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  youtube: string;
  description: string;
  from: string;
  app_notification: boolean;
  propriete: IPropriete;
  first_connexion: boolean;
}>;

export type ChoiceTypeProfilData = {
  user_type: UserType;
};

export interface AuthState {
  user: IUser | IAdmin | any;
  token?: string | null;
}

export type PaginationResults<T> = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  immeubles?: T[];
  count_immeuble?: number;
  count_villa?: number;
};

export type TypeQuery = Partial<{
  page?: number;
  limit?: number;
  word?: string | null;
  slug?: string;
  slug_proprio?: string;
  slug_agence?: string;
  type_propriete?: string | null;
  type_gerance?: string | null;
  titre?: string | null;
  name?: string | null;
  nom?: string | null;
  uptodate?: string | null;
  not_read?: string | null;
  date?: string | null;
  type?: string | null;
  location?: string | null;
  locataire?: string | null;
  propriete?: string | null;
  proprio?: string | null;
  end_date?: string | null;
  start_date?: string | null;
  date_du?: string | null;
  date_au?: string | null;
  data: object | null;
  statut?: string | null;
}>;

export type GraphType = {
  month: string;
  year: string;
  day: string;
  entree: number;
  depense: number;

  depenses_month: number;
  entrees_month: number;
  proprietaires: number;
  agences: number;
  commissionproprietaires: number;
  commissionagences: number;
  total_client: number;
  paiements_month: number;
};

export type GraphLocationType = {
  month: string;
  proprietes_vacantes: number;
  proprietes_habitées: number;
};

export interface IAgenceData {
  total_bien: number;
  total_locataire: number;
  biens_vacants: number;
  total_payer_mois_passer: number;
  total_non_payer_mois_passer: {
    montant_total_impayes: string | number;
    nombre_locataires: string | number;
  };
  requetes_en_attente: [];
  locataires_non_payer: number;
  nbre_litiges_en_cours: number;
  nbre_litiges_regler: number;
  nbre_litiges: number;
  litiges_en_cours: [];
  locataires_non_payant: [];
  locataires_encours_litiges: [];
  graph: GraphType[];

  // Pour PQGSB
  total_biens: number;
  total_locataires: number;
  total_biens_vacants: number;
  total_loyers_recouvres: number;
  loyer_non_recouvrer: {
    total: number;
    nombre_locataires: number;
    montant_total_impayes: number;
  };
  revenus: number;
  entree_depense_graphe: GraphType[];
  locataire_non_payer: [];
  litiges: ILitiges[];
  propriete_graphe: GraphLocationType[];
}
export interface IFiltreAgenceData {
  date_du: string;
  date_au: string;
}

export interface IPQGSBData {
  total_biens: number;
  total_locataires: number;
  total_biens_vacants: number;
  total_loyers_recouvres: number;
  loyer_non_recouvrer: {
    total: number;
  };
  revenus: number;
  requetes_en_attente: [];
  entree_depense_graphe: GraphType[];
  locataire_non_payer: [];
  litiges: ILitiges[];
  propriete_graphe: GraphLocationType[];
  nbre_litiges: number;
  nbre_litiges_en_cours: number;
  nbre_litiges_regler: number;
  litiges_en_cours: ILitiges[];
}

export interface IComptableData {
  total_entrees: number;
  total_depenses: number;
  profit: number;
  total_entrees_week: number;
  total_depenses_week: number;
  graph: GraphType[];
}

export interface IAdminData {
  total_proprietaires: string;
  total_agences: string;
  total_ca: string;
  pourcentage_proprietaires: string;
  pourcentage_agences: string;
  clientsgraphe: GraphType[];
  commissiongraphe: GraphType[];
}
export interface IAdminProprietaireData {
  admin_graphe: GraphType[];
}

export interface SuppressionType {
  id: number;
  slug: string;
  motif: string;
  status: "nouvelle" | "acceptee" | "refusee" | "archivee";
  user: IUser;
  created_at: string;
  is_active: boolean;
}

export type SuppressionTypeFromData = Partial<SuppressionType>;

export type Abonnement = {
  id: number;
  slug: string;
  offre: Offer;
  adherant: number;
  paid: boolean;
  mode_paiement: string;
  pdf: string;
  created_at: Date;
};

export type AbonnementFormData = Partial<{
  offre: number;
  user: number;
  type: string;
}>;

export type Mensuality = {
  id: number;
  slug: string;
  montant: string;
  mode_paiement: string;
  paid: boolean;
  date: string;
  user: number;
  offre: number;
};

export type MensualityFormData = Partial<Mensuality>;
