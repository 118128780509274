import React, { useState } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { DateFilterQuery } from "../../../../utils/api/admin/admin.type";
import { useGetEntreeByComptableBySlugQuery } from "../../../../utils/api/entree/entree.api";
import { ApiBaseUrl } from "../../../../utils/http";
import AjouterEntreeModal from "../../../modal/comptabilite/AjouterEntreeModal";
import EntreesTableComptable from "../../../tables/Comptables/MesEntrees/EntreesTableComptable";
import { DateRange } from "../Depenses/Depenses";
import "./MesEntrees.css";

function MesEntrees() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const comptable = useAppSelector((s) => s.user.user);
  const [query, setQuery] = useState<DateFilterQuery>({
    date_debut: "",
    date_fin: "",
  });
  const { data = { results: [], count: 0 }, isLoading } =
    useGetEntreeByComptableBySlugQuery({
      slug: comptable?.slug,
      page: page,
      limit: perPage,
      ...query,
    });

  const onQueryChange = (
    field: keyof DateFilterQuery,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Mes revenus
          </h4>
          <button
            className="btn btn-see-more"
            title="Ajouter une entrée"
            data-bs-toggle="modal"
            data-bs-target="#AjoutEntreeModal"
          >
            <AiFillPlusSquare style={{ fontSize: 22 }} />
            <span className="ps-2">Ajouter une entrée</span>
          </button>
          <div
            className="modal fade"
            id="AjoutEntreeModal"
            aria-labelledby="AjoutEntreeModalLabel"
            aria-hidden="true"
          >
            <AjouterEntreeModal modalId="AjoutEntreeModal" />
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="locataire-requete-filtre-container my-4">
            <form className="row g-3 locataire-requete-filtre-form">
              <div className="col-auto">
                <label
                  htmlFor="filtreLogement"
                  className="locataire-requete-filtre-label"
                >
                  Filtrer
                </label>
              </div>
              <div className="col-auto">
                <DateRange
                  start={query.date_debut}
                  end={query.date_fin}
                  onChange={(start, end) => {
                    onQueryChange("date_debut", start);
                    onQueryChange("date_fin", end);
                  }}
                />
              </div>
              {data?.count && data?.count > 0 ? (
                <div className="col-auto">
                  <button
                    className="btn btn-see-more dropdown-toggle btn"
                    type="button"
                    onClick={(e) => {
                      e?.preventDefault();
                      if (query?.date_debut != "" && query?.date_fin != "") {
                        window
                          ?.open(
                            `${ApiBaseUrl}/api/agence/${comptable?.agence_id?.slug}/export_recette/?date_debut=${query?.date_debut}&date_fin=${query?.date_fin}`,
                            "_blank"
                          )
                          ?.focus();
                      } else {
                        window
                          ?.open(
                            `${ApiBaseUrl}/api/agence/${comptable?.agence_id?.slug}/export_recette`,
                            "_blank"
                          )
                          ?.focus();
                      }
                    }}
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <BiExport style={{ fontSize: 22 }} />
                      <span className="ps-2">Exporter les revenus</span>
                    </div>
                  </button>
                </div>
              ) : null}
            </form>
          </div>
          <div className="p-0 p-md-4">
            <div className="mb-3 px-4">
              <span className="kannimo-comptable-table-entree-titre">
                Revenus
              </span>
            </div>
            <EntreesTableComptable
              data={data?.results}
              isLoading={isLoading}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
              count={data?.count}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MesEntrees;
