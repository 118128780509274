import React, { useState } from 'react'
import AjoutRappelModal from '../../../../modal/agence/AjoutRappelModal';
import RappelAgenceTable from '../../../../tables/agence/Rappel/RappelAgenceTable';

function Rappels() {
    const [value, setValue] = useState('');
    return (
        <div className='kanimmo-dash-locataire-param-main-page-container py-3'>
            <RappelAgenceTable />
            <div className="modal fade" id="AjouterRappelModal" aria-labelledby="AjouterRappelModalLabel" aria-hidden="true">
                <AjoutRappelModal modalId='AjouterRappelModal' />
            </div>
        </div>
    )
}

export default Rappels;