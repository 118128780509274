import React, { useEffect, useState } from 'react'
import { Pagination } from 'reactstrap';
import word from "../../../../../../assets/appImages/word.png"
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ContratSkeletonPQNGPSB from './ContratSkeletonPQNGPSB';
import ContratDatasPQNGPSB from './ContratDatasPQNGPSB';
import { useGetContratByProprietaireQuery } from '../../../../../../utils/api/contrat/contrat.api'
import { useAppSelector } from "../../../../../../redux/hooks";
import ViewDocModal from "./ViewDocModal";
import { IContrat } from "../../../../../../utils/api/contrat/contrat.type";
import { AlertInfo } from '../../../../../common/Alert';
import { CustomPagination } from '../../../../../common/CustomPagination';




function ContratTablePQNGPSB({nom}: {nom?: any}) {
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [item, setItem] = useState<IContrat>();


    const documentFormatter: any = (cell: any, row: any) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
                    <div>
						<img src={word} alt='img type document' className='kanimmo-img-type-document' /> 
						{" "}{ cell?.substring(12 , cell?.length - 4)}
                    </div>
                </div>
			</div>
		);
	};

	const agenceFormatter: any = (cell: any, row: any) => {
		return (
			<div className="">
				{row?.agence?.nom_agence}
			</div>
		);
	};

	const locataireFormatter: any = (cell: any, row: any) => {
		return (
			<div className="">
				{row?.locataire?.nom} {row?.locataire?.prenom}
			</div>
		);
	};

	const contratFormatter: any = (cell: any, row: any) => {
        return(
            <button
				data-bs-toggle="modal"
				data-bs-target="#ViewDocModal"
				onClick={() => setItem(row)}
                role="button"
                className="btn btn-action-voir-etat-lieux"
            >
                Voir Contrat
            </button>
        )
    }
    
    const columns = [
		{
			dataField: "numero",
			text: "N°",
			formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
			style: { textAlign: "left" },
            headerStyle: () => {
				return { width: "100px", whiteSpace: "normal" };
			},
		},
		{
			dataField: "nom_agence",
			text: "Nom de l'agence",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) =>
            agenceFormatter(cell, row),
		},
        {
			dataField: "document",
			text: "Nom du document",
            formatter: (cell: any, row: any) =>
            documentFormatter(cell, row),
		},
		{
			dataField: "nom_locataire",
			text: "Locataire",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) =>
			locataireFormatter(cell, row),
		},

		{
			dataField: "date_validation",
			text: "Date de validation",
			style: { textAlign: "left" },
		},
		
		{
			dataField: "decouvrir",
			text: "Découvrir",
			style: { color: "#1AA981", textAlign: 'left' },
			formatter: (cell: any, row: any) =>
            contratFormatter(cell, row),
		},
	];

	const proprietaire = useAppSelector((s) => s.user.user);
    
    const { data = { results: [] }, isLoading } = useGetContratByProprietaireQuery({slug: proprietaire?.slug, nom});

	console.log('data', data)

    return (
        <>
			{isLoading && <ContratSkeletonPQNGPSB />}
			{!isLoading && (
				<>
                    <BootstrapTable
                        keyField="id"
						data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
                        columns={columns}
                        bordered={true}
                        condensed={false}
                        responsive
                        wrapperClasses="table-responsive locataire-table"
						noDataIndication={() => (
							<AlertInfo message="Aucune donnée trouvéé" />
						)}
                    />
                    <CustomPagination
                        nbPages={8}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
                </>
			)}
			<ViewDocModal item={item} />
		</>
    )
}

export default ContratTablePQNGPSB