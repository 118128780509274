/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useGetLocataireByAgenceBySlugQuery } from "../../../../utils/api/locataire/locataire.api";
import { useAppSelector } from "../../../../redux/hooks";
import { IoMdTrash } from "react-icons/io";
import { UseDeleteLocataire } from "../../../TableauDebord/Agence/Locataires/CRUDLocataire/useAddOrUpdateLocataire";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import VillasTableSkeleton from "./VillasTableSkeleton";
import { GeneratePassword } from "../ProprietaireAgenceTable/ProprietaireAgenceTable";

function LocataireTable({
  name,
  uptodate,
  type,
}: {
  name?: string | null;
  uptodate?: string | null;
  AgenceSlug?: string;
  isAgence?: boolean;
  type?: string | null;
}) {
  const { user } = useAppSelector((s) => s?.user);
  const userType = user?.user_type;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data = { results: [] }, isLoading } =
    useGetLocataireByAgenceBySlugQuery({
      name,
      uptodate,
      type,
      page,
      limit: perPage,
      slug: user?.agence_id ? user?.agence_id?.slug : user?.slug,
    });

  // console.log("data", data?.results);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        {userType !== "juriste" && (
          <div className="table-actions-btn-container-commandes gap-2 d-flex">
            <div>
              <NavLink
                to={`/agence/modifier-locataire`}
                className="btn btn-action-modal-icon"
                title="Modifier"
                state={row}
              >
                <MdModeEditOutline />
              </NavLink>
            </div>
            <DeleteLocataireBtn slug={row?.slug} />
            <div className="container-btn-action-icon-modal">
              <GeneratePassword slug={row?.slug} />
            </div>
          </div>
        )}
      </>
    );
  };
  const seeDetailFormatter = (cell: any, row: any) => {
    return (
      <>
        {/* {userType !== "juriste" && */}
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <NavLink
              className="btn btn-see-list"
              title="Voir"
              to={`/agence/liste-locataire/${row?.slug}`}
              state={row}
            >
              Voir
            </NavLink>
          </div>
        </div>
        {/* } */}
      </>
    );
  };

  const proprieteFormatter = (cell: any) => <div>{cell?.nom}</div>;

  const fullnameFormatter = (cell: any, row: any) => (
    <div>{row?.prenom + " " + row?.nom}</div>
  );

  const columns = [
    {
      dataField: "nom",
      text: "Nom du locataire",
      formatter: (cell: any, row: any) => fullnameFormatter(cell, row),
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      formatter: (cell: any) => (
        <a className="no-link" href={`tel:${cell}`}>
          {cell}
        </a>
      ),
    },
    {
      dataField: "propriete",
      text: "Propriete",
      formatter: (cell: any) => proprieteFormatter(cell),
    },
    {
      dataField: "",
      text: "Détails",
      formatter: (cell: any, row: any) => seeDetailFormatter(cell, row),
      style: { textAlign: "center" },
    },
    userType !== "juriste" && {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <VillasTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            // data={data?.results}
            data={data?.results?.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <CustomPagination
            nbPages={data?.count}
            page={page}
            perPage={perPage}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
          />
        </>
      )}
    </>
  );
}

export default LocataireTable;

function DeleteLocataireBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteLocataire(slug);

  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <IoMdTrash />
    </button>
  );
}
