/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";
import {
  IPropriete,
  ProprieteFormData,
  TypePropriete,
} from "../../../../../../utils/api/propriete/propriete.type";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
  useAddOrUpdateProprieteMutation,
  useDeleteProprieteMutation,
} from "../../../../../../utils/api/propriete/propriete.api";
import { clean, cleannerError } from "../../../../../../utils/Utils";
import { Color } from "../../../../../../utils/theme";
import { useGetCommoditesQuery } from "../../../../../../utils/api/commodites/commodites.api";
import { Data } from "../../../../../common/GoogleInput";

type SelectOptionType = { label: string; value: number };

function UseCrudPropriete(proprieteItem?: IPropriete) {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    hasChambre: yup.boolean().default(false),
    hasEdit: yup.boolean(),
    step: yup.number().nullable(),
    adresse: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("L'adresse"),
    }),
    numero: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le numero"),
    }),
    nom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le nom"),
    }),
    rue: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("La rue"),
    }),
    pays: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le pays"),
    }),
    ville: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("La ville"),
    }),
    code_postale: yup
      .string()
      .when("step", {
        is: 1,
        then: yup.string().label("Le code postal").nullable(),
      })
      .nullable(),
    type_propriete: yup.string().when("step", {
      is: 2,
      then: yup.string().label("Type de propriété"),
    }),
    surface: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("La surface de la propriété")
          .required()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_chambres: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de chambres")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_cuisines: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de cuisines")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salle_de_bains: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salles de bain")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_salon: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le nombre de salons")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    nbre_climatiseurs: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .label("Le nombre de climatisseurs")
          .nullable()
          .transform((value) => (isNaN(value) ? 0 : value))
          .default(0),
      })
      .nullable()
      .default(0)
      .transform((value) => (isNaN(value) ? 0 : value))
      .default(0),
    description: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("La description"),
    }),
    pictures: yup.mixed().when("hasEdit", {
      is: false,
      then: yup.mixed().when("step", {
        is: 2,
        then: yup
          .mixed()
          .required("Veuillez uploader au moins une image.")
          .nullable(),
      }),
    }),
    immeuble: yup.number().when("step", {
      is: 2,
      then: yup.number().label("L'immeuble"),
    }),
    prix_base: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .required()
          .label("Le Loyer Hors Charges")
          .nullable()
          .transform((value) => (isNaN(value) ? null : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    charges: yup
      .array()
      .of(
        yup.object().shape(
          {
            libelle: yup.string().when(["type", "montant"], {
              is: (a: string, b: number) => {
                console.log(a, b, "test");
                return a || b;
              },
              then: yup.string().required().nullable().label("Le libellé"),
            }),
            type: yup.string().when(["libelle", "montant"], {
              is: (a: string, b: number) => a || b,
              then: yup.string().required().nullable().label("Le type"),
            }),
            montant: yup
              .number()
              .when(["libelle", "type"], {
                is: (a: string, b: string) => a || b,
                then: yup
                  .number()
                  .nullable()
                  .required()
                  .label("La valeur")
                  .transform((value) => (isNaN(value) ? null : value))
                  .nullable(),
              })
              .transform((value) => (isNaN(value) ? null : value))
              .nullable(),
          },
          [
            ["libelle", "type"],
            ["type", "montant"],
            ["libelle", "montant"],
          ]
        )
      )
      .nullable(),
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
    control,
  } = useForm<ProprieteFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [level, setLevel] = React.useState<number>(0);
  const [dataForm, setDataForm] = useState<ProprieteFormData>({
    step: 1,
    slug: "",
    numero: "",
    adresse: "",
    rue: "",
    pays: "",
    ville: "",
    code_postale: "",
    type_propriete: TypePropriete.immeuble,
    nb_chambre: 0,
    nb_cuisine: 0,
    nb_salle_de_bain: 0,
    prix: 0,
    description: undefined,
    pictures: undefined,
    chambres: [],
  });
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [description, setDescription] = useState<string>("");
  const [pictures, setPictures] = useState<File[]>();
  const [mandatPicture, setMandatPicture] = useState<File>();
  const [commodites, setCommodites] = useState<
    { label: string; value: number }[] | undefined
  >([]);
  const [addOrUpdatePropriete, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateProprieteMutation();

  const { user } = useAppSelector((s) => s?.user);
  const [showChangeOffre, setshowChangeOffre] = useState<string>("");


  useEffect(() => {
    if (pictures?.length) {
      setValue(
        "pictures",
        pictures?.filter((el: any) => !el?.slug)
      );
    }
  }, [pictures]);
  useEffect(() => {
    if (proprieteItem?.type_propriete) {
      setValue("type_propriete", proprieteItem?.type_propriete);
    }
  }, [proprieteItem]);
  useEffect(() => {
    console.log(errors);
    cleannerError(errors, clearErrors, 5000);
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Propriété ${
          !proprieteItem?.slug ? "ajoutée" : "modifée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (proprieteItem?.immeuble?.id) {
          navigate(
            `/proprietaire/gerer-par-le-proprietaire/immeuble/${proprieteItem?.immeuble?.slug}`,
            {
              replace: true,
              state: proprieteItem?.immeuble,
            }
          );
        } else {
          navigate("/proprietaire/gerer-par-le-proprietaire/proprietes", {
            replace: true,
          });
        }
      });
    }

    if (isError) {
      const err = error as any;
      // console.log("error", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
      setshowChangeOffre(err?.data?.message)
    }
  }, [isLoading]);

  useEffect(() => {
    // console.log(proprieteItem, "location");
    if (proprieteItem?.id) {
      // console.log(proprieteItem?.adresse, "address");
      setValue("hasEdit", true);
      setValue("slug", proprieteItem?.slug);
      setValue("numero", proprieteItem?.numero);
      setValue("surface", proprieteItem?.surface);
      setValue("url_youtube", proprieteItem["url_youtube"]);
      setValue("ville", proprieteItem?.ville);
      setValue("nom", proprieteItem?.nom);
      setValue("adresse", proprieteItem?.adresse);
      setValue("rue", proprieteItem?.rue);
      setValue("pays", proprieteItem?.pays);
      setValue("code_postale", proprieteItem?.code_postale);
      setValue("type_propriete", proprieteItem?.type_propriete);
      setValue("nbre_salon", proprieteItem["nbre_salon"]);
      setValue("nbre_chambres", proprieteItem["nbre_chambres"]);
      setValue("nbre_cuisines", proprieteItem["nbre_cuisines"]);
      setValue("nbre_salle_de_bains", proprieteItem["nbre_salle_de_bains"]);
      setValue("nbre_climatiseurs", proprieteItem["nbre_climatiseurs"]);
      setValue("prix_base", proprieteItem["prix_base"]);
      setValue("prix", proprieteItem?.prix);
      if (proprieteItem.proprietaire) {
        if (typeof proprieteItem?.proprietaire === "number") {
          setValue("proprietaire", proprieteItem.proprietaire);
        } else {
          setValue("proprietaire", proprieteItem.proprietaire?.id);
        }
      }

      setValue("description", proprieteItem?.description);
      setPictures(proprieteItem.pictures);
      setValue("gerance", proprieteItem.gerance);
      setDescription(proprieteItem.description);

      setValue("climatiseurs", proprieteItem.climatiseurs);

      setAddress(proprieteItem?.adresse);
      setCountry(proprieteItem?.pays);
      setState(proprieteItem?.region);
      setCity(proprieteItem?.ville);

      if (proprieteItem?.commodites?.length) {
        setValue(
          "commodites",
          proprieteItem.commodites?.map((item: any) => item?.id)
        );
        setCommodites(
          proprieteItem?.commodites?.map((item) => {
            return {
              label: item.nom,
              value: item?.id,
            };
          })
        );
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [proprieteItem]);

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  const handleChangeMandatPicture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files !== null) {
      // console.log(e.currentTarget.files[0]);
      setValue("mandat", e.currentTarget.files[0]);
      setMandatPicture(e.currentTarget.files[0]);
    }
  };

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
    console.log("val country", option);
  };

  const handleStateSelect = (option: Data) => {
    console.log("val region", option);
    if (option?.rue) {
      setState(option?.region);
      setValue("region", option?.rue);
    }
  };
  const handleCitySelect = (option: Data) => {
    console.log("val city", option);
    setCity(option?.ville);
    setValue("ville", option.ville);
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }

    if (val?.ville) {
      setValue("ville", val?.ville);
      setCity(val?.ville);
    }
  };

  const onChangeCommodite = (selected: SelectOptionType[]) => {
    setCommodites(selected);
    setValue(
      "commodites",
      selected?.map((item: any) => item?.value)
    );
  };

  const onSubmit = async (data: ProprieteFormData) => {
    
    if (!proprieteItem?.id) {
      data["proprietaire"] = user?.proprietaire_id?.slug
        ? user?.proprietaire_id?.id
        : user?.id;
    }

    setDataForm(data);
    if (data?.step !== 2) {
      return setLevel(data?.step);
    }
    let dataSend = clean(data);
    const fd = new FormData();
    if (pictures?.length && pictures?.filter((el: any) => !el?.slug)?.length) {
      for (const picture of pictures) {
        fd.append("pictures", picture);
      }
    }
   

    if (dataSend?.charges?.length) {
      dataSend.charges = dataSend?.charges?.filter((el: any) => el?.libelle);
    }
    for (let key of Object.keys(dataSend)) {
      const val = dataSend[key];
      // fd.append(key, val);
      if (key !== "pictures") {
        if (
          (key === "chambres" ||
            key === "cuisines" ||
            key === "salons" ||
            key === "salle_de_bains" ||
            key === "climatiseurs" ||
            key === "charges") &&
          dataSend[key]
        ) {
          fd.append(key, JSON.stringify(val));
        } else {
          fd.append(key, val);
        }
      }
    }

    // console.log("dsubmit", dataSend);
    await addOrUpdatePropriete({ slug: dataSend?.slug, data: fd });
  };

  const { data: commoditedatas } = useGetCommoditesQuery({});
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    description,
    pictures,
    mandatPicture,
    handleChangeDescription,
    setPictures,
    handleChangeMandatPicture,
    isLoading,
    level,
    dataForm,

    handleCountrySelect,
    handleStateSelect,
    handleCitySelect,
    country,
    state,
    city,
    proprieteItem,
    control,
    Controller,
    commoditesList: commoditedatas?.results,
    commodites,
    onChangeCommodite,
    onChangeAddress,
    address,
    unregister,
    showChangeOffre
  };
}

export default UseCrudPropriete;

export function UseDeletePropriete(slug: string, item: IPropriete) {
  const [deleteData] = useDeleteProprieteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer la propriété ${item?.nom} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: "Propriété supprimée avec succès!",
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
