import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { FactureFormData, IFacture } from "../../../utils/api/facture/facture.type";
import { useAddOrUpdateFactureMutation } from "../../../utils/api/facture/facture.api";
import { cleannerError, onHide } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import { BtnSubmit } from "../../common/Button";
import { SelectInput } from "../../common/SelectInput";
import { optionsStatusFacture } from "./AjouterFactureModal";

function ChangementStatusFactureModal({ item }: { item?: IFacture }) {
	const validationSchema = yup.object().shape({
		statut: yup.string().required().label("Status"),
	});
	const [changeStatus, { isSuccess, isError, error, isLoading }] =
		useAddOrUpdateFactureMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<FactureFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("statut", item?.statut);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Statut changé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("ChangementStatusFactureModal");
			});
		}
		const err = error as any;
		if (isError) {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: `${
					err
						? err?.data?.message
						: `Une erreur de statut ${
								err?.status || "inconnue"
						  } est survenue`
				}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: FactureFormData) => {
		changeStatus({ slug: data?.slug, data: data });
	};
	return (
		<div
			className="modal fade"
			id="ChangementStatusFactureModal"
			aria-labelledby="ChangementStatusFactureModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id="ChangementStatusFactureModal"
						>
							Changer le Statut
						</h5>
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12">
									<SelectInput
										label="Statut"
										required
										options={optionsStatusFacture}
										{...register("statut")}
										error={errors.statut}
										hiddenFirstOption={true}
									/>
								</div>
							</div>
							<div className="flex-r mb-4">
								<BtnSubmit label="Changer" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangementStatusFactureModal;
