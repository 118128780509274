import React from "react";
import { useSearchParams } from "react-router-dom";
import LocataireTable from "../../../../tables/agence/LocataireTable/LocataireTable";

function Locataires({
  searchParams,
  setSearchParams,
  AgenceSlug,
  is_Agence,
}: {
  searchParams: any;
  setSearchParams: any;
  AgenceSlug: string;
  is_Agence: boolean;
}) {
  const word = searchParams.get("name");
  const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        name: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const uptodate = searchParams.get("uptodate");

  const handleFilterByUpToDate = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        uptodate: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };
  const type = searchParams.get("type");

  const handleFilterByType = (e: React.FormEvent<HTMLSelectElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        type: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  return (
    <div className="bg-white p-3 filter-and-table-container">
      <div className="bloc-filter-locataitaires-agence">
        <div className="locataire-requete-filtre-container">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select"
                onChange={handleFilterByUpToDate}
              >
                {/* <option value="" selected disabled>Sélectionner une option</option> */}
                <option value="" selected>
                  Tout
                </option>
                <option value="a_jour">Locataires à jour</option>
                <option value="non_a_jour">Locataires pas à jour</option>
              </select>
            </div>
          </form>
        </div>
        <div className="locataire-requete-filtre-container">
          <div className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                onChange={handleFilterByName}
              />
            </div>
          </div>
        </div>

        <div className="locataire-requete-filtre-container">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select"
                onChange={handleFilterByType}
              >
                {/* <option value="" selected disabled>Sélectionner une option</option> */}
                <option value="" selected>
                  Tout
                </option>
                <option value="villa">Villa</option>
                <option value="immeuble">Immeubles</option>
                <option value="appartement">Appartement</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div>
        <LocataireTable
          name={word}
          uptodate={uptodate}
          type={type}
          AgenceSlug={AgenceSlug}
          isAgence={is_Agence}
        />
      </div>
    </div>
  );
}

export default Locataires;
