/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { useRegisterAgenceMutation } from "../../../utils/api/auth/auth.api";
import {
  cleannerError,
  getInTechPhone,
  getInTechTel,
  onHide,
  onShow,
  useLocationState,
} from "../../../utils/Utils";
import { Data } from "../../common/GoogleInput";
import { Offer } from "../../../utils/api/offer/offer.type";
import { ApiBaseUrl, AppBaseUrl, Env } from "../../../utils/http";
import { ApiKey } from "../../../utils/constant";
import { useCreateAbonnementMutation } from "../../../utils/api/user/user.api";

export function validatePassword(Yup: any) {
  return Yup.string()
    .required()
    .matches(/[a-z]+/, "Au moins une minuscule.")
    .matches(/[A-Z]+/, "Au moins une majuscule.")
    .matches(/[!@#$%^&*(),;-_+*/.?":{}|<>]+/, "Au moins un caractère spécial")
    .matches(/\d+/, "Au moins un chiffre.")
    .min(8)
    .label("Mot de passe");
}

export type RegisterStepPropsType = {
  navigation: any;
};

const useRegisterAgencePIForm = () => {
  const validationSchema = yup.object().shape({
    step: yup.number().nullable(),
    nom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Nom"),
    }),
    prenom: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Prénom"),
    }),
    civilite: yup.string().when("step", {
      is: 1,
      then: yup.string().label("Genre"),
    }),
    email: yup.string().when("step", {
      is: 1,
      then: yup.string().email().required().label("Email"),
    }),
    telephone: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Téléphone"),
    }),
    // username: yup.string().when("step", {
    //   is: 1,
    //   then: yup.string().required().label("UserName"),
    // }),
    password: yup.string().when("step", {
      is: 1,
      then: validatePassword(yup),
    }),
    role: yup.string().when("step", {
      is: 1,
      then: yup.string().label("Role").required().nullable(),
    }),
    autres: yup.string().when("role", {
      is: (val: string) => val === "other",
      then: yup.string().label("Role").required().nullable(),
    }),
    whatsapp: yup.string().label("Whatsapp").nullable(),
    notification_whatsapp: yup.boolean().nullable(),
    avatar: yup.mixed().nullable().label("Photo"),
    nom_agence: yup.string().when("step", {
      is: 2,
      then: yup.string().required().label("Nom de l'agence"),
    }),
    adresse_agence: yup.string().label("Adresse de l'agence").nullable(),
    pays: yup.string().label("Pays").nullable(),
    region: yup.string().label("Région").nullable(),
    code_postal: yup.string().label("Code postal"),
    logo: yup.mixed().nullable().label("Logo"),
    // total_bien_gerer: yup.string().default("0").nullable(),
    // bien_gerer_totalement: yup.string().default("0").nullable(),
    // bien_gerer_partiellement: yup.string().default("0").nullable(),
    a_un_comptable: yup.boolean().nullable().default(false),
    confirmPassword: yup.string().when("step", {
      is: 1,
      then: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Les mots de passe ne correspond pas"
        ),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    watch,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });
  const watchAllFields = watch();
  const itemState = useLocationState<Offer>(undefined);
  const navigate = useNavigate();
  const [onLoading, setOnLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [seconds, setSeconds] = useState<any>(20);
  const [infosPayment, setInfosPayment] = useState<any>();
  const [loading, setLoading] = useState(false);
  let withPaiement = true;
  const [mode, setMode] = useState<string>("");
  const [offer, setOffer] = useState<Offer>();

  //  Calling the Register Mutation
  const [registerAgence] = useRegisterAgenceMutation();

  const [level, setLevel] = React.useState<number>(0);
  const [dataForm, setDataForm] = useState<any>({});

  // const [address, setAddress] = useState("");
  const [addressAgence, setAddressAgence] = useState("");

  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [sendData, { isSuccess, isError, error, data }] =
    useCreateAbonnementMutation();

  useEffect(() => {
    if (itemState) {
      setOffer(itemState);
    } else {
      onShow("offersModal");
    }
  }, [itemState]);

  const handleCountrySelect = (option: Data) => {
    setCountry(option?.country);
    setValue("pays", option?.country);
  };

  const handleStateSelect = (option: Data) => {
    if (option?.region) {
      setState(option?.region);
      setValue("region", option?.region);
    }
  };

  const onChangeAddressAgence = (val: Data) => {
    setAddressAgence(val?.rue);
    setValue("adresse_agence", val?.rue);
    if (val?.country) {
      setValue("pays", val?.country);
      setCountry(val?.country);
    }

    if (val?.region) {
      setValue("region", val?.region);
      setState(val?.region);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription réussie!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/connexion", { replace: true });
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : "Une erreur est survenue lors de l'inscription",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (isFetching) {
      const timer =
        seconds > 0 ? setInterval(() => setSeconds(seconds - 1), 1000) : 0;

      return () => clearInterval(timer);
    }
  }, [seconds, isFetching]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const [previewLogo, setPreviewLogo] = useState("");
  const [previewAvatar, setPreviewAvatar] = useState("");

  const handelLogoChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("logo", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewLogo(URL.createObjectURL(file));
      register("logo");
      setValue("logo", file);
    }
  }, []);
  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const onSubmit = (data: any) => {
    // console.log("data", data);
    if (data?.email) {
      data["email"] = (data?.email).toLowerCase();
    }
    if (data?.username) {
      data["username"] = (data?.username).toLowerCase();
    }
    data["user_type"] = "agence";
    setDataForm(data);
    if (data?.step !== 2) {
      return setLevel(data?.step);
    }

    // if (data?.role === "other") {
    //   data.role = ""
    // }
    setDataForm(data);
    onShow("ConditionsModal");
  };
  const onRegister = (e: any) => {
    e?.preventDefault();
    let data = dataForm;
    // console.log("data", watchAllFields);
    data["condition"] = true;
    setDataForm(data);
    //Open Payment Modal
    onShow("choicePaymentMethod");
  };

  const onCreateUser = async () => {
    let data = dataForm;
    // console.log("data", watchAllFields);
    data["a_un_comptable"] = false;
    data["condition"] = true;
    // data["offre"] = offer?.id
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }

    // registerAgence(fd);
    const res = await registerAgence(fd);
    // console.log(res,"user res")
    if ("data" in res) {
      return res.data;
    } else {
      throw res.error;
    }
  };

  const onPressSubmit = async (apiCash: string, data: any) => {
    setMode(apiCash);
    setLoading(true);
    const user = await onCreateUser();
    if (offer?.gratuit) {
      await sendData({ user: user?.id, offre: offer?.id });
    } else {
      try {
        let externalTransactionId: number = new Date().getTime();

        if (withPaiement) {
          console.log("user", data, dataForm);
          externalTransactionId = user?.slug as unknown as number;
        }
        let amount = Env !== "dev" ? offer?.montant : 5;
        if (amount === 10 && apiCash === "BANK_CARD_API_CASH_OUT") {
          amount = 100;
        }
        const tel = dataForm?.telephone;
        let dataSend = {
          amount: amount,
          phone: "",
          codeService: apiCash,
          externalTransactionId,
          callbackUrl: `${ApiBaseUrl}/api/abonnement/callback/?offre=${offer?.id}&user=${user?.id}`,
          apiKey: ApiKey,
          data: {
            env: "test",
          },
          sender: "Kanimmo",
          merchantName: "Kanimmo",
          operationDescription:
            "Paiement sur la plateforme Kanimmo avec paytech",
          successRedirectUrl: `${AppBaseUrl}/connexion`,
          errorRedirectUrl: `${AppBaseUrl}/inscription-agence?status=error`,
          offre: offer?.id,
          mode: apiCash,
          ...(data ?? {}),
        };
        if (apiCash !== "BANK_CARD_API_CASH_OUT") {
          dataSend.phone = getInTechPhone(tel)?.toString() ?? "";
        } else {
          dataSend.phone = getInTechTel(tel)?.toString() ?? "";
        }
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(dataSend),
        };

        const res = await fetch(
          "https://api.intech.sn/api-services/operation",
          requestOptions
        ).then((res) => {
          console.log("res", res);
          if (res.ok) return res.json();
          throw {
            data: { message: `Une erreur est survenue code: ${res.status}` },
          };
        });
        console.log("res", res);
        setLoading(false);
        if (res?.error) {
          let message = "";
          if (res.data) {
            for (let [k, v] of Object.entries(res.data)) {
              if (Array.isArray(v)) {
                message = `${message}\n${k}: ${v.join(", ")}`;
              }
            }
          }
          if (message) {
            // toast(message, true);
            Swal.fire({
              icon: "error",
              title: message,
              showConfirmButton: false,
              timer: 5000,
            });
          } else if (res.msg) {
            // toast(res.msg, true);
            Swal.fire({
              icon: "error",
              title: res.msg,
              showConfirmButton: false,
              timer: 5000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: res?.msg
                ? res?.msg
                : `Erreur de statut code ${res?.code} est survenue!`,
              showConfirmButton: false,
              timer: 5000,
            });
          }
          return false;
        } else {
          const { externalTransactionId } = res.data;
          setInfosPayment(res.data);
          onHide("choicePaymentMethod");
          Swal.fire({
            icon: "success",
            title: res?.msg,
            showConfirmButton: false,
            timer: 5000,
          }).then(() => {
            if (externalTransactionId) {
              onShow("statusPaymentModal");
            }
            // window.location.href = deepLinkUrl;
          });

          return true;
        }
      } catch (error: any) {
        setLoading(false);
        console.log("error", error);
        const data = error?.data as any;

        let m = data?.data?.message;
        if (!m) m = data?.message;
        if (!m && data) m = data["message "] || null;
        if (m) {
          // toast(m, true);
          Swal.fire({
            icon: "error",
            title: m,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          // toast("Une erreur est survenue", true);
          Swal.fire({
            icon: "error",
            title: `Erreur de statut ${error?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
        return false;
      }
    }
  };

  const onGetTransactionStatus = () => {
    setOnLoading(true);
    // 600255dc-b6c0-11ed-91c6-0242ac130003
    var raw = {
      externalTransactionId: infosPayment?.externalTransactionId,
    };

    var myHeaders = new Headers();
    myHeaders.append("Secretkey", ApiKey);

    // var requestOptions: any = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Secretkey: ApiKey,
      },
      body: JSON.stringify(raw),
    };

    fetch(
      "https://api.intech.sn/api-services/get-transaction-status",
      requestOptions
    )
      .then((response) => response.json())
      .then((result: any) => {
        setOnLoading(false);
        console.log(result);
        if (result?.data?.status === "SUCCESS") {
          setIsFetching(false);
          onHide("statusPaymentModal");
          setIsChecked(true);
          Swal.fire({
            icon: "success",
            title: `Paiement effectué avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            navigate("/connexion", { replace: true });
          });
          console.log("done");
        } else if (result?.data?.status === "PENDING") {
          setIsFetching(true);
          Swal.fire({
            icon: "info",
            title: `Votre paiement est en attente!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (result?.data?.status === "REFUNDED") {
          setIsFetching(false);
          onHide("statusPaymentModal");
          Swal.fire({
            icon: "info",
            title: `Votre paiement a été remboursé!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (result?.data?.status === "PROCESSING") {
          setIsFetching(true);
          Swal.fire({
            icon: "info",
            title: `Votre paiement est en cours de traitement!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          console.log("no done");
          setIsFetching(false);
          onHide("statusPaymentModal");
          Swal.fire({
            icon: "error",
            title: result?.data?.errorType?.message
              ? result?.data?.errorType?.message
              : `${result?.msg}`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        setIsFetching(false);
        setOnLoading(false);
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: error?.data?.message
            ? error?.data?.message
            : `Erreur de statut ${error?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
        onHide("statusPaymentModal");
      });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    // isLoading,
    isLoading: loading,
    mode,
    onLoading,
    onCheckStatus: onGetTransactionStatus,
    isChecked,
    seconds,
    level,
    dataForm,
    handelLogoChange,
    handelAvatarChange,
    previewLogo,
    previewAvatar,
    // onChangeAddress,
    // address,
    onChangeAddressAgence,
    addressAgence,
    onRegister,
    handleCountrySelect,
    handleStateSelect,
    country,
    state,
    watchAllFields,
    onPressSubmit,
  };
};

export default useRegisterAgencePIForm;
