/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import RegisterOk from "../../modal/agence/RegisterOk";
import { IoIosArrowBack } from "react-icons/io";
import ErrorMessage from "../../common/ErrorMessage";
import { BtnSubmit } from "../../common/Button";
// import useRegisterAgencePIForm from "../useAuthForm/useRegisterAgence";
import { FormError } from "../../common/CustomInputCheckbox";
import GoogleInput, { Data } from "../../common/GoogleInput";

// import {
//   CountrySelector,
//   StateSelector,
//   CitySelector,
// } from "volkeno-react-country-state-city";
import "volkeno-react-country-state-city/dist/index.css";

export type RegisterStepPropsType = {
  navigation: any;
  register: any;
  onSubmit: any;
  setValue: any;
  errors: any;
  isLoading: boolean;
  handelLogoChange: (e: any) => void;
  previewLogo: any;
  addressAgence: any;
  onChangeAddressAgence: (e: any) => void;
  handleCountrySelect: (val: Data) => void;
  handleStateSelect: (val: Data) => void;
  country: string;
  state: string;
};

const AddInfoAgency = ({
  navigation,
  handelLogoChange,
  previewLogo,
  register,
  errors,
  isLoading,
  addressAgence,
  onChangeAddressAgence,
  handleCountrySelect,
  handleStateSelect,
  country,
  state,
}: RegisterStepPropsType) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(e: any) {
    e.preventDefault();
    window.sessionStorage.setItem("userType", "agence");
    setIsOpen(true);
  }

  // const [country, setCountry] = useState<any>("");
  // const [state, setState] = useState<any>("");

  // const handleCountrySelect = (option: Data) => {
  //   setCountry(option?.country);
  //   setValue("pays", option?.country);
  // };

  // const handleStateSelect = (option: Data) => {
  //   if (option?.region) {
  //     setState(option?.region);
  //     setValue("region", option?.region);
  //   }
  // };

  return (
    <div className="auth-form-container">
      <div>
        <div className="py-4">
          <button
            className="btn btn-go-back-step"
            onClick={() => navigation.previous()}
          >
            <IoIosArrowBack />
            <span className="ps-2">Retour</span>
          </button>
        </div>
        <div id="auth-form">
          <div className="row auth-form-row">
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nom de l’agence <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="prenom"
                  placeholder="Nom de l’agence"
                  {...register("nom_agence")}
                />
                {errors?.nom_agence && (
                  <ErrorMessage message={errors?.nom_agence?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Adresse de l’agence immobilière
                </label>
                <GoogleInput
                  className="form-control auth-form-control"
                  value={addressAgence}
                  onChange={onChangeAddressAgence}
                />
                <FormError error={errors?.adresse_agence?.message} />
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                 <label
                htmlFor="pays"
                className="form-label form-label-login"
              >
                Pays{" "}
              </label>
              <GoogleInput
                className="form-control auth-form-control"
                placeholder="Pays"
                value={country}
                types={["country"]}
                onChange={handleCountrySelect}
              />

              {errors?.pays && <FormError error={errors?.pays?.message} />}
              </div>
             
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label
                htmlFor="region"
                className="form-label form-label-login"
              >
                Région{" "}
              </label>
              <GoogleInput
                className="form-control auth-form-control"
                placeholder="Région"
                value={state}
                types={[
                  "administrative_area_level_1",
                  "administrative_area_level_2",
                ]}
                onChange={handleStateSelect}
              />

              {errors?.region && <FormError error={errors?.region?.message} />}
              </div>
              
            </div>

            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Pays</label>

                <CountrySelector
                  onChange={handleCountrySelect}
                  name="pays"
                  placeholder="Pays"
                  value={country}
                  containerClass="tx country-select-container p-0"
                />

                {errors?.pays && (
                  <ErrorMessage message={errors?.pays?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Région</label>
                <StateSelector
                  country={country}
                  name="region"
                  value={state}
                  placeholder="Region"
                  countryPlaceholder="Region"
                  onChange={handleStateSelect}
                  containerClass="tx country-select-container p-0"
                />
                {errors?.region && (
                  <ErrorMessage message={errors?.region?.message} />
                )}
              </div>
            </div> */}
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Code postal
                </label>
                <input
                  type="text"
                  className="form-control auth-form-control"
                  id="code_postal"
                  placeholder="Code postal"
                  {...register("code_postal")}
                />
                {errors?.code_postal && (
                  <ErrorMessage message={errors?.code_postal?.message} />
                )}
              </div>
            </div>
            <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">Logo</label>
                <input
                  type="file"
                  className="form-control auth-form-control"
                  id="logo"
                  accept="image/*"
                  onChange={(e) => handelLogoChange(e)}
                  name="logo"
                />
                {errors?.logo && (
                  <ErrorMessage message={errors?.logo?.message} />
                )}
                {previewLogo && (
                  <div className="img-preview-container mb-3">
                    <img src={previewLogo} className="type-img-preview" />
                  </div>
                )}
              </div>
            </div>

            {/* ============= CACHER LA PARTIE NBR DE BIEN GÉRÉ ============== */}

            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nombre total de biens gérés{" "}
                </label>
                <input
                  type="number"
                  className="form-control auth-form-control"
                  id="nombre"
                  min={0}
                  placeholder="Nombre total de biens gérés *"
                  {...register("total_bien_gerer")}
                />
                {errors?.total_bien_gerer && (
                  <ErrorMessage message={errors?.total_bien_gerer?.message} />
                )}
              </div>
            </div> */}
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nombre de biens gérés totalement
                </label>
                <input
                  type="number"
                  className="form-control auth-form-control"
                  id="nombre"
                  min={0}
                  placeholder="Nombre de biens gérés totalement"
                  {...register("bien_gerer_totalement")}
                />
                {errors?.bien_gerer_totalement && (
                  <ErrorMessage
                    message={errors?.bien_gerer_totalement?.message}
                  />
                )}
              </div>
            </div> */}
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Nombre de biens gérés partiellement
                </label>
                <input
                  type="number"
                  className="form-control auth-form-control"
                  id="nombre"
                  min={0}
                  placeholder="Nombre de biens gérés partiellement"
                  {...register("bien_gerer_partiellement")}
                />
                {errors?.bien_gerer_partiellement && (
                  <ErrorMessage
                    message={errors?.bien_gerer_partiellement?.message}
                  />
                )}
              </div>
            </div> */}
            {/* <div className="col-md-12 auth-input-col">
              <div className="form-group auth-form-group">
                <label className="form-label form-label-login">
                  Gérez vous votre comptabilité ou est elle gérée par un
                  comptable?
                </label>
                <div className="container-chexkbox-agency">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="true"
                      id="flexCheckDefault1"
                      {...register("a_un_comptable")}
                      checked
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefault"
                    >
                      Je gère ma comptabilité
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="false"
                      id="flexCheckDefault2"
                      {...register("a_un_comptable")}
                    />
                    <label
                      className="form-check-label form-label-login ms-2"
                      htmlFor="flexCheckDefault"
                    >
                      Elle est gérée par un comptable
                    </label>
                  </div>
                  {errors?.a_un_comptable && (
                    <ErrorMessage message={errors?.a_un_comptable?.message} />
                  )}
                </div>
              </div>
            </div> */}
            <div className="py-4 col-md-4 offset-md-4 auth-submit-btn-container">
              <BtnSubmit label="S'inscrire" isLoading={isLoading} />
              {/* <button
                  className="btn auth-submit-btn"
                  onClick={openModal}
                >
                  S’inscrire
                </button> */}
              <RegisterOk modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInfoAgency;
