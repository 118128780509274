import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useGetCategoryEntreeQuery } from "../../../utils/api/category/category.api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { IEntree } from "../../../utils/api/entree/entree.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import { Input } from "../../common/Input";
import { AiFillPlusSquare } from "react-icons/ai";
import useAddOrUpdateEntree from "../../TableauDebord/Comptable/MesEntrees/entreeForm/useEntreeForm";
import AjouterCategorieEntreeModal from "./AjouterCategorieEntreeModal";

function AjouterEntreeModal({
  entree,
  modalId,
}: {
  entree?: IEntree;
  modalId: string;
}) {
  const { data = { results: [] } } = useGetCategoryEntreeQuery({});
  // console.log("cat", data);
  const {
    register,
    errors,
    onSubmit,
    handleJustificatif,
    handleChangePropriete,
    locataires,
    proprietes,
    isLoading,
    Controller,
    control,
    options,
    option,
    handleChangeLocataire,
    locataireItem,
  } = useAddOrUpdateEntree(modalId, entree);
  const animatedComponents = makeAnimated();

  const [ajoutCategorie, setAjoutCategorie] = useState(false);
  const [afficherBoutonAjouterCategorie, setAfficherBoutonAjouterCategorie] =
    useState(false);

  const handleToggle = () => {
    setAjoutCategorie(!ajoutCategorie);
    setAfficherBoutonAjouterCategorie(false)
  };

  const handleSelectClick = () => {
    setAfficherBoutonAjouterCategorie(!afficherBoutonAjouterCategorie);
  };

  return (
    <>
      {ajoutCategorie ? (
        <AjouterCategorieEntreeModal
          isAccountingPage={true}
          handleToggle={handleToggle}
          modalId="AddCategorieEntreeModal"
        />
      ) : (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
              <button
                className="close-modal auth-submit-annuler"
                data-bs-dismiss="modal"
                type="button"
                onClick={() => setAfficherBoutonAjouterCategorie(false)}
              >
                <IoClose />
              </button>
            </div>
            <div className="modal-body text-start">
              <div className="container-form">
                <form onSubmit={onSubmit}>
                  <div className="row">
                  {afficherBoutonAjouterCategorie && (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <button
                            className="btn p-0 btn-see-more"
                            // title="Ajouter une entrée"
                            type="button"
                            // data-bs-toggle="modal"
                            // data-bs-target="#AddCategorieEntreeModal"
                          >
                            <small
                              className="px-0 text- pe-2"
                              style={{ fontSize: 11 }}
                            >
                              Si la catégorie que vous cherchez n'est pas dans
                              la liste, Cliquez-ici pour l'ajouter.
                            </small>
                            <AiFillPlusSquare
                              onClick={handleToggle}
                              style={{ fontSize: 22 }}
                            />
                          </button>
                          {/* <button
              className="btn p-0 btn-see-more"
              type="button"
            >
              <small className="px-0 text- pe-2" style={{ fontSize: 11 }}>
                Si la catégorie que vous cherchez n'est pas dans la liste, Cliquez-ici pour l'ajouter.
              </small>
              <AiFillPlusSquare onClick={handleToggle} style={{ fontSize: 22 }} />
            </button> */}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="categorie"
                          className="form-label form-label-modal-custom"
                        >
                          Catégorie d’entrée
                        </label>
                        <select
                          className="form-select form-control-modal-custom"
                          aria-label=".form-select-sm example"
                          {...register("categorie_entree")}
                          onClick={handleSelectClick}
                        >
                          <option value="" selected disabled>
                            Choisissez une catégorie
                          </option>
                          {data?.results?.map((item: any) => (
                            <option value={item.id} key={item.id}>
                              {item?.titre}
                            </option>
                          ))}
                        </select>
                        {errors?.categorie_entree && (
                          <FormError
                            error={errors?.categorie_entree?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="propriete"
                          className="form-label form-label-modal-custom"
                        >
                          Villa/Appartement loué
                        </label>

                        <Controller
                          name="propriete"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={options}
                                placeholder="Selectionner une propriété"
                                inputClass="form-select form-select-modal-custom mb-2"
                                classNamePrefix="select-comodite"
                                onChange={(e: any) => handleChangePropriete(e)}
                                value={option}
                              />
                            );
                          }}
                        />
                        {errors?.propriete && (
                          <FormError error={errors?.propriete?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          htmlFor="nombre_stock"
                          className="form-label form-label-modal-custom"
                          aria-labelledby="propriete"
                        >
                          Client
                          <span
                            className="text-danger"
                            style={{
                              fontSize: 24,
                            }}
                          >
                            *
                          </span>
                        </label>

                        {/* <select
                      id="propriete"
                      className="form-select form-select-modal-custom mb-2"
                      {...register("locataire")}
                    >
                      <option value="" selected disabled>
                        Sélectionner le locataire
                      </option>
                      {locataires &&
                        locataires?.length >= 0 &&
                        locataires?.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item?.label}
                          </option>
                        ))}
                    </select> */}
                        <Controller
                          name="locataire"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={locataires}
                                placeholder="Selectionner une locataire"
                                inputClass="form-select form-select-modal-custom mb-2"
                                classNamePrefix="select-comodite"
                                onChange={handleChangeLocataire}
                                value={locataireItem}
                              />
                            );
                          }}
                        />
                        {locataires && locataires?.length === 0 && (
                          <small className="badge text-bg-info px-2">
                            Aucun locataire trouvé pour cette propriété!
                          </small>
                        )}
                        {<FormError error={errors.locataire} />}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="montant"
                          className="form-label form-label-modal-custom"
                        >
                          Montant
                        </label>
                        <input
                          type="text"
                          placeholder="250 000 FCFA"
                          className="form-control form-control-modal-custom"
                          id="montant"
                          {...register("montant")}
                        />
                        {errors?.montant && (
                          <FormError error={errors?.montant?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="justificatif"
                          className="form-label form-label-modal-custom"
                        >
                          Upload un justificatif
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-modal-custom"
                          id="justificatif"
                          onChange={(e) => handleJustificatif(e)}
                          accept="image/*,.pdf"
                          name="justificatif"
                        />
                        {errors?.justificatif && (
                          <FormError error={errors?.justificatif?.message} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="container-btn-modal row mt-5">
                    <div className="col-md-3">
                      <button
                        className="btn btn-cancelled"
                        data-bs-dismiss="modal"
                        type="button"
                        // onClick={resetForm}
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col-md-6 offset-md-3 flex-r">
                      <BtnSubmit
                        label={entree ? "Modifier" : "Envoyer"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AjouterEntreeModal;
