/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactElement } from "react";
import Login from "../components/auth/Login";
import LandingPage from "../components/landingPage/LandingPage";
import AdminLayout from "../components/TableauDebord/AdminLayout/AdminLayout";
import DashboardAgence from "../components/TableauDebord/Agence/Dashboard/DashboardAgence";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MonLoyer from "../components/TableauDebord/locataire/Dashboard/MonLoyer/MonLoyer";
import RequetesLocataire from "../components/TableauDebord/locataire/Dashboard/Requetes/RequetesLocataire";
import Administratif from "../components/TableauDebord/locataire/Dashboard/Administratif/Administratif";
import Locataire from "../components/TableauDebord/locataire/Dashboard/Locataire/Locataire";
import ListeLocataires from "../components/TableauDebord/Agence/Locataires/ListeLocataires";
import ParametreLocataire from "../components/TableauDebord/locataire/Dashboard/Parametre/ParametreLocataire";
import AjouterLocataire from "../components/TableauDebord/Agence/Locataires/CRUDLocataire/AjouterLocataire";
import DetailsGestionService from "../components/TableauDebord/Agence/Locataires/DetailsGestionService";
import DetailVillasImmeubles from "../components/TableauDebord/Agence/Locataires/DetailVillasImmeubles";
import Messagerie from "../components/TableauDebord/Agence/Messagerie/Messagerie";
import DashboardPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/DashboardPQGSB/DashboardPQGSB";
import DashboardPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/DashboardPQNGPSB/DashboardPQNGPSB";
import ProprietesPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Proprietes/ProprietesPQGSB";
import LocatairesPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Locataires/LocatairesPQGSB";
import ComptabiliteAgence from "../components/TableauDebord/Agence/ComptabiliteAgence/ComptabiliteAgence";
import ProprietesAgence from "../components/TableauDebord/Agence/ProprietesAgence/ProprietesAgence";
import DetailsGestionServicesPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Locataires/DetailsGestionServicesPQGSB";
import AjoutProprietes from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Proprietes/AjoutProprietes/AjoutProprietes";
import AdministratifPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Administratif/AdministratifPQGSB";
import ProprietaireAgence from "../components/TableauDebord/Agence/ProprietaireAgence/ProprietaireAgence";
import DetailProprietaire from "../components/TableauDebord/Agence/ProprietaireAgence/DetailProprietaire";
import AjouterProprietaire from "../components/TableauDebord/Agence/ProprietaireAgence/AjouterProprietaire";
import OptionAgence from "../components/TableauDebord/Agence/OptionAgence/OptionAgence";
import AjoutPropriete from "../components/TableauDebord/Agence/ProprietesAgence/AjoutPropriete/AjoutPropriete";
import MessageLocataire from "../components/TableauDebord/locataire/Dashboard/Messages/MessageLocataire";
import DashboardComptable from "../components/TableauDebord/Comptable/Dashboard/DashboardComptable";
import MessagesComptable from "../components/TableauDebord/Comptable/Messages/MessagesComptable";
import MesEntrees from "../components/TableauDebord/Comptable/MesEntrees/MesEntrees";
import Depenses from "../components/TableauDebord/Comptable/Depenses/Depenses";
import OptionComptable from "../components/TableauDebord/Comptable/Options/OptionComptable";
import LandingPageV2 from "../components/landingPage/LandingPageV2";
import DashboardAdmin from "../components/TableauDebord/Admin/Dashboard/DashboardAdmin";
import MessagesAdmin from "../components/TableauDebord/Admin/Messages/MessagesAdmin";
import MesClients from "../components/TableauDebord/Admin/MesClients/MesClients";
import AjouterClient from "../components/TableauDebord/Admin/MesClients/CrudClient/AjouterClient";
import AjouterAgence from "../components/TableauDebord/Admin/MesClients/CrudClient/AjouterAgence";
import RecettesAdmin from "../components/TableauDebord/Admin/Recettes/RecettesAdmin";
import AdminRegister from "../components/auth/Admin/AdminRegister";
import ComptableRegister from "../components/auth/Admin/ComptableRegister";
import AjouterUtilisateurAgence from "../components/TableauDebord/Agence/OptionAgence/AjouterUtilisateurAgence";
import AdministratifAgence from "../components/TableauDebord/Agence/Administratif/AdministratifAgence";
import AdministratifAdmin from "../components/TableauDebord/Admin/Administratif/AdministratifAdmin";
import OptionsAdmin from "../components/TableauDebord/Admin/Options/OptionsAdmin";
import AjouterAdministrateur from "../components/TableauDebord/Admin/Options/CrudAdministrateur/AjouterAdministrateur";
import AjouterUtilisateurAdmin from "../components/TableauDebord/Admin/Options/CrudUtilisateur/AjouterUtilisateurAdmin";
import Lead from "../components/TableauDebord/Admin/Lead/Lead";
import DetailsAgence from "../components/TableauDebord/Admin/DetailsAgence/DetailsAgence";
import DetailsAdministrateur from "../components/TableauDebord/Admin/Options/CrudUtilisateur/DetailsAdministrateur";
import ProprietaireGPARegister from "../components/auth/Admin/ProprietaireGPARegister";
import MessagesPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Messages/MessagesPQNGPSB";
import ParametrePQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Parametres/ParametrePQNGPSB";
import ProprietesPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/MesProprietes/ProprietesPQNGPSB";
import AjouterProprietesPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/MesProprietes/AjouterProprietes/AjouterProprietesPQNGPSB";
import AdministratifPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Administratif/AdministratifPQNGPSB";
import AgencesPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Agences/AgencesPQNGPSB";
import DetailsAgencePQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Agences/DetailsAgencePQNGPSB";
import DetailsAppartementPQNGPSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Agences/DetailsAppartementPQNGPSB";
import NewsList from "../components/user/NewsList/NewsList";
import NewsItem from "../components/user/NewsList/NewsItem/NewsItem";
import AjouterUtilisateurPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Administratif/AjouterUtilisateurPQGSB";
import NewsAdmin from "../components/TableauDebord/Admin/News/NewsAdmin";
import AjoutNewsAdmin from "../components/TableauDebord/Admin/News/AjoutNewsAdmin";
import DetailsNewsAdmin from "../components/TableauDebord/Admin/News/DetailsNewsAdmin";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import { IUser, UserType } from "../utils/api/user/user.type";
import { useAppSelector } from "../redux/hooks";
import {
  isAgence,
  isAgenceAgent,
  isAgenceJuriste,
  isAgenceMarketeur,
  isComptable,
  isLocataire,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
} from "../utils/Utils";
import { IAdmin } from "../utils/api/admin/admin.type";
import Policy from "../components/user/PageFooter/Policy/Policy";
import Faq from "../components/user/PageFooter/Faq/Faq";
import ContactUs from "../components/user/PageFooter/ContactUs/ContactUs";
import AboutUs from "../components/user/PageFooter/AboutUs/AboutUs";
import DetailsRequeteLocataire from "../components/TableauDebord/locataire/Dashboard/Requetes/DetailsRequeteLocataire";
import { FieldType } from "../utils/constant";
import EditAdministrateur from "../components/TableauDebord/Admin/Options/CrudUtilisateur/EditAdministrateur";
import AddOrUpdateLocataire from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Locataires/CrudLocataire/AddOrUpdateLocataire";
import Categorie from "../components/TableauDebord/Comptable/Categories/Categorie";
import MobileDetailMessage from "../components/TableauDebord/Agence/Messagerie/MobileDetailMessage";
import LocataireRegister from "../components/auth/Locataire/LocataireRegister";
import AgencyRegister from "../components/auth/Agence/AgencyRegister";
import PropritaireRegister from "../components/auth/Proprietaire/PropritaireRegister";
import AccountManagerDetails from "../components/tables/Admin/AccountManagerTable/AccountManagerDetails";
import Problemes from "../components/TableauDebord/Admin/Problèmes/Problemes";
import FaqAdmin from "../components/TableauDebord/Admin/FaqAdmin/FaqAdmin";
import { IPersonnel } from "../utils/api/agence/agence.type";
import UnsubscribeNewsletter from "../components/landingPage/FrontFooter/UnsubscribeNewsletter";
import TypeRequetes from "../components/TableauDebord/Admin/TypeRequetes/TypeRequetes";
import ImmeublesProprietaire from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Immeubles/ImmeublesProprietaire";
import DetailsImmeubles from "../components/TableauDebord/Proprietaire/ProprietaireGererParAgence/Agences/DetailsImmeubles";
import AjoutImmeubles from "../components/TableauDebord/Agence/ProprietesAgence/AjoutPropriete/AjoutImmeubles";
import AjoutAppartement from "../components/TableauDebord/Agence/ProprietesAgence/AjoutAppartement/AjoutAppartement";
import MonAgenceOuProprietaire from "../components/TableauDebord/locataire/Dashboard/MonAgence/MonAgenceOuProprietaire";
import MonProprietaire from "../components/TableauDebord/locataire/Dashboard/MonAgence/MonProprietaire";
import DetailsLitiges from "../components/TableauDebord/Agence/Locataires/DetailsLitiges";
import Offres from "../components/user/PageFooter/Offres/Offres";
import ComptabilitePQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Comptabilite/ComptabilitePQGSB";
import MessagesPQGSB from "../components/TableauDebord/Proprietaire/ProprietaireGererParLeProprietaire/Messages/MessagesPQGSB";
import OffersList from "../components/TableauDebord/Admin/Offers/OffersList";
import SubscriberList from "../components/TableauDebord/Admin/subscriber/SubscriberList";
import PaymentProprietaire from "../components/TableauDebord/Agence/ProprietaireAgence/PaymentProprietaire";
import ValidationList from "../components/TableauDebord/Agence/validation/validationListe";

export const ProtectedRoutes = ({
  children,
  userType,
}: {
  children: ReactElement;
  userType: UserType[];
}) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  const is_AdminAgent = isAdminAgent(user);
  const isAccess = userType?.includes(user?.user_type) || is_AdminAgent;
  const auth = user?.id;
  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/connexion" state={{ from: location }} replace />
  );
};

export const LocataireRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isLocataire(user)) return children;
  if (isProprietaireOwner(user))
    return <Navigate to="/proprietaire/gerer-par-le-proprietaire/dashboard" />;
  if (isProprietaireAgence(user))
    return <Navigate to="/proprietes/gerer-par-agence/dashboard" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return <Navigate to="/agence/dashboard" />;
  if (isAgenceJuriste(user)) return <Navigate to="/agence/locataires" />;
  if (isAdminUser(user) || isAdminAgent(user))
    return <Navigate to="/admin/dashboard" />;
  if (isComptable(user)) return <Navigate to="/comptable/dashboard" />;
  return <Navigate to="/" />;
};

export const ProprietaireOwnerRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);

  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isProprietaireOwner(user) || isProprioJuriste(user)) return children;
  if (isProprietaireAgence(user))
    return <Navigate to="/proprietes/gerer-par-agence/dashboard" />;
  if (isLocataire(user)) return <Navigate to="/locataire/mon-loyer" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return <Navigate to="/agence/dashboard" />;
  if (isAgenceJuriste(user)) return <Navigate to="/agence/locataires" />;
  if (isAdminUser(user)) return <Navigate to="/admin/dashboard" />;
  if (isComptable(user)) return <Navigate to="/comptable/dashboard" />;
  return <Navigate to="/" />;
};

export const ProprietaireAgenceRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);

  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isProprietaireOwner(user) || isProprioJuriste(user))
    return <Navigate to="/proprietaire/gerer-par-le-proprietaire/dashboard" />;
  if (isProprietaireAgence(user)) return children;
  if (isLocataire(user)) return <Navigate to="/locataire/mon-loyer" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return <Navigate to="/agence/dashboard" />;
  if (isAgenceJuriste(user)) return <Navigate to="/agence/locataires" />;
  if (isAdminUser(user) || isAdminAgent(user))
    return <Navigate to="/admin/dashboard" />;
  if (isComptable(user)) return <Navigate to="/comptable/dashboard" />;
  return <Navigate to="/" />;
};

export const ComptableRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isComptable(user)) return children;
  if (isProprietaireOwner(user))
    return <Navigate to="/proprietaire/gerer-par-le-proprietaire/dashboard" />;
  if (isProprietaireAgence(user))
    return <Navigate to="/proprietes/gerer-par-agence/dashboard" />;
  if (isLocataire(user)) return <Navigate to="/locataire/mon-loyer" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return <Navigate to="/agence/dashboard" />;
  if (isAgenceJuriste(user)) return <Navigate to="/agence/locataires" />;
  if (isAdminUser(user) || isAdminAgent(user))
    return <Navigate to="/admin/dashboard" />;
  return <Navigate to="/" />;
};

export const AgenceRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isLocataire(user)) return <Navigate to="/locataire/mon-loyer" />;
  if (isProprietaireOwner(user))
    return <Navigate to="/proprietaire/gerer-par-le-proprietaire/dashboard" />;
  if (isProprietaireAgence(user))
    return <Navigate to="/proprietes/gerer-par-agence/dashboard" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return children;
  if (isAgenceJuriste(user)) return children;
  if (isAdminUser(user) || isAdminAgent(user))
    return <Navigate to="/admin/dashboard" />;
  if (isComptable(user)) return <Navigate to="/comptable/dashboard" />;
  return <Navigate to="/" />;
};
export const AdminRoutes = ({ children }: { children: any }) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);
  if (!user?.id)
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  if (isLocataire(user)) return <Navigate to="/locataire/mon-loyer" />;
  if (isProprietaireOwner(user))
    return <Navigate to="/proprietaire/gerer-par-le-proprietaire/dashboard" />;
  if (isProprietaireAgence(user))
    return <Navigate to="/proprietes/gerer-par-agence/dashboard" />;
  if (isAgence(user) || isAgenceAgent(user) || isAgenceMarketeur(user))
    return <Navigate to="/agence/dashboard" />;
  if (isAgenceJuriste(user)) return <Navigate to="/agence/locataires" />;
  if (isAdminUser(user) || isAdminAgent(user)) return children;
  if (isComptable(user)) return <Navigate to="/comptable/dashboard" />;
  return <Navigate to="/" />;
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path = "/";
  console.log("redirect");
  if (isLocataire(user)) {
    path = "/locataire/mon-loyer";
    if (user?.first_connexion) {
      path = "/locataire/parametre";
    }
  } else if (isProprietaireOwner(user) || isProprioJuriste(user)) {
    path = "/proprietaire/gerer-par-le-proprietaire/dashboard";
    if (user?.first_connexion) {
      path = "/proprietaire/gerer-par-le-proprietaire/parametres";
    }
  } else if (isProprietaireAgence(user)) {
    path = "/proprietes/gerer-par-agence/dashboard";
    if (user?.first_connexion) {
      path = "/proprietes/gerer-par-agence/parametres";
    }
  } else if (
    isAgence(user) ||
    isAgenceAgent(user) ||
    isAgenceJuriste(user) ||
    isAgenceMarketeur(user)
  ) {
    path = "/agence/dashboard";
    if (user?.first_connexion) {
      path = "/agence/option";
    }
  } else if (isComptable(user)) {
    path = "/comptable/dashboard";
    if (user?.first_connexion) {
      path = "/comptable/options";
    }
  } else if (
    user?.user_type === UserType.superAdmin ||
    user?.user_type === UserType.admin
  ) {
    path = "/admin/dashboard";
  }
  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

const IsAdminAllow = ({
  children,
  field,
}: {
  children: ReactElement;
  field: FieldType;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const navigate = useNavigate();
  if (!user[field] && !is_SuperAdmin) {
    setTimeout(() => {
      navigate(-1);
    }, 10);
  }
  return children;
};
// UserRoutes

export const AppRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/services",
    element: <LandingPageV2 />,
  },
  {
    path: "/offres",
    element: <Offres />,
  },
  {
    path: "/news",
    element: <NewsList />,
  },
  {
    path: "/news/:slug",
    element: <NewsItem />,
  },
  {
    path: "/conditions-generales",
    element: <Policy />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/nous-contacter",
    element: <ContactUs />,
  },
  {
    path: "/a-propos",
    element: <AboutUs />,
  },
  {
    path: "/connexion",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgetPassword />,
  },
  {
    path: "/modifier-mot-de-passe",
    element: <ResetPassword />,
  },
  //   {
  //     path: "/inscription",
  //     element: (
  //       <RedirectAuthRoute>
  //         <Register />
  //       </RedirectAuthRoute>
  //     ),
  //   },
  {
    path: "/inscription-locataire",
    element: <LocataireRegister />,
  },
  {
    path: "/inscription-agence",
    element: <AgencyRegister />,
  },
  {
    path: "/inscription-proprietaire",
    element: <PropritaireRegister />,
  },
  {
    path: "/inscription/admin",
    element: <AdminRegister />,
  },
  {
    path: "/inscription/comptable",
    element: <ComptableRegister />,
  },
  {
    path: "/inscription/proprietaire-gerer-par-agence",
    element: <ProprietaireGPARegister />,
  },
  {
    path: "/desabonner-newsletter/:email",
    element: <UnsubscribeNewsletter />,
  },
  {
    path: "agence/",
    element: (
      <AgenceRoutes>
        <AdminLayout />
      </AgenceRoutes>
    ),
    children: [
      { element: <Navigate to="/agence/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAgence />,
      },
      {
        path: "locataires",
        element: <ListeLocataires />,
      },
      {
        path: "ajouter-locataire",
        element: <AjouterLocataire />,
      },
      {
        path: "modifier-locataire",
        element: <AjouterLocataire />,
      },
      {
        path: "locataires/requete/:slug",
        element: <DetailsGestionService />,
      },
      {
        path: "liste-locataire/:slug",
        element: <DetailVillasImmeubles />,
      },
      {
        path: "litige/:slug",
        element: <DetailsLitiges />,
      },
      {
        path: "messagerie",
        element: <Messagerie />,
      },
      {
        path: "message/:slug",
        element: <MobileDetailMessage />,
      },
      {
        path: "comptabilite",
        element: <ComptabiliteAgence />,
      },
      {
        path: "proprietes",
        element: <ProprietesAgence />,
      },
      {
        path: "ajouter-propriete",
        element: <AjoutPropriete />,
      },
      {
        path: "ajouter-propriete-immeuble",
        element: <AjoutImmeubles />,
      },
      {
        path: "modifier-propriete-immeuble/:slug",
        element: <AjoutImmeubles />,
      },
      {
        path: "ajouter-appartement",
        element: <AjoutAppartement />,
      },
      {
        path: "modifier-propriete/:slug",
        element: <AjoutPropriete />,
      },
      {
        path: "proprietes/:slug",
        element: <DetailsAppartementPQNGPSB />,
      },
      {
        path: "immeuble/:slug",
        element: <DetailsImmeubles />,
      },
      {
        path: "proprietaires",
        element: <ProprietaireAgence />,
      },
      {
        path: "proprietaire/:slug",
        element: <DetailProprietaire />,
      },
      {
        path: "paiements-proprietaire/:slug",
        element: <PaymentProprietaire />,
      },
      {
        path: "ajouter-proprietaire",
        element: <AjouterProprietaire />,
      },
      {
        path: "modifier-proprietaire/:slug",
        element: <AjouterProprietaire />,
      },
      {
        path: "option",
        element: <OptionAgence />,
      },
      {
        path: "ajouter-utilisateur",
        element: <AjouterUtilisateurAgence />,
      },
      {
        path: "administratif",
        element: <AdministratifAgence />,
      },
      {
        path: "categorie",
        element: <Categorie />,
      },
      {
        path: "validationadminforgerant",
        element: <ValidationList />,
      },
    ],
  },
  {
    path: "locataire/",
    element: (
      <LocataireRoutes>
        <AdminLayout />
      </LocataireRoutes>
    ),
    children: [
      { element: <Navigate to="/locataire/mon-loyer" replace />, index: true },
      {
        path: "mon-loyer",
        element: <MonLoyer />,
      },
      {
        path: "requetes",
        element: <RequetesLocataire />,
      },
      {
        path: "requetes/:slug",
        element: <DetailsRequeteLocataire />,
      },
      {
        path: "administratif",
        element: <Administratif />,
      },
      {
        path: "administratif/detail-historique",
        element: <Locataire />,
      },
      {
        path: "litige/:slug",
        element: <DetailsLitiges />,
      },
      {
        path: "parametre",
        element: <ParametreLocataire />,
      },
      {
        path: "messages",
        element: <MessageLocataire />,
      },
      {
        path: "message/:slug",
        element: <MobileDetailMessage />,
      },
      {
        path: "mon-agence",
        element: <MonAgenceOuProprietaire />,
      },
      {
        path: "mon-proprietaire",
        element: <MonProprietaire />,
      },
    ],
  },
  {
    path: "proprietaire/gerer-par-le-proprietaire/",
    element: (
      <ProprietaireOwnerRoutes>
        <AdminLayout />
      </ProprietaireOwnerRoutes>
    ),
    children: [
      {
        element: (
          <Navigate
            to="/proprietaire/gerer-par-le-proprietaire/dashboard"
            replace
          />
        ),
        index: true,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/dashboard",
        element: <DashboardPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/proprietes",
        element: <ProprietesPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/proprietes/:slug",
        element: <DetailsAppartementPQNGPSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/immeuble/:slug",
        element: <DetailsImmeubles />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/ajouter-propriete",
        element: <AjoutProprietes />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/ajouter-immeuble",
        element: <AjoutImmeubles />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/modifier-propriete-immeuble/:slug",
        element: <AjoutImmeubles />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/modifier-propriete/:slug",
        element: <AjoutProprietes />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/ajouter-appartement",
        element: <AjoutAppartement />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/locataires",
        element: <LocatairesPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/litiges",
        element: <LocatairesPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/locataires/ajouter-locataire",
        element: <AddOrUpdateLocataire />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/locataires/modifier-locataire/:slug",
        element: <AddOrUpdateLocataire />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/service/:slug",
        element: <DetailsGestionServicesPQGSB />,
      },
      {
        path: "litige/:slug",
        element: <DetailsLitiges />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/locataire/:slug",
        element: <DetailVillasImmeubles />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/comptabilite",
        element: <ComptabilitePQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/parametres",
        // element: <ParametresPQGSB />,
        element: <OptionAgence />,
      },
      {
        path: "ajouter-collaborateur",
        element: <AjouterUtilisateurAgence />,
      },
      {
        path: "modifier-collaborateur/:slug",
        element: <AjouterUtilisateurAgence />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/messages",
        element: <MessagesPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/administratif",
        element: <AdministratifPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/ajouter-utilisateur",
        element: <AjouterUtilisateurPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/modifier-utilisateur/:slug",
        element: <AjouterUtilisateurPQGSB />,
      },
      {
        path: "/proprietaire/gerer-par-le-proprietaire/immeubles",
        element: <ImmeublesProprietaire />,
      },
      {
        path: "categorie",
        element: <Categorie />,
      },
    ],
  },
  {
    path: "proprietes/gerer-par-agence/",
    element: (
      <ProprietaireAgenceRoutes>
        <AdminLayout />
      </ProprietaireAgenceRoutes>
    ),
    children: [
      {
        element: (
          <Navigate to="/proprietes/gerer-par-agence/dashboard" replace />
        ),
        index: true,
      },
      {
        path: "dashboard",
        element: <DashboardPQNGPSB />,
      },
      {
        path: "messages",
        element: <MessagesPQNGPSB />,
      },
      {
        path: "proprietes",
        element: <ProprietesPQNGPSB />,
      },
      {
        path: "ajouter-propriete",
        element: <AjouterProprietesPQNGPSB />,
      },
      {
        path: "ajouter-immeuble",
        element: <AjoutImmeubles />,
      },
      {
        path: "ajouter-appartement",
        element: <AjoutAppartement />,
      },
      {
        path: "modifier-propriete-immeuble/:slug",
        element: <AjoutImmeubles />,
      },
      {
        path: "modifier-propriete/:slug",
        element: <AjouterProprietesPQNGPSB />,
      },
      {
        path: "proprietes/:slug",
        element: <DetailsAppartementPQNGPSB />,
      },
      {
        path: "parametres",
        element: <ParametrePQNGPSB />,
      },
      {
        path: "administratif",
        element: <AdministratifPQNGPSB />,
      },
      {
        path: "agences",
        element: <AgencesPQNGPSB />,
      },
      {
        path: "agence/:slug",
        element: <DetailsAgencePQNGPSB />,
      },
      {
        path: "appartement/:slug",
        element: <DetailsAppartementPQNGPSB />,
      },
      {
        path: "immeuble/:slug",
        element: <DetailsImmeubles />,
      },
      {
        path: "categorie",
        element: <Categorie />,
      },
    ],
  },
  {
    path: "comptable/",
    element: (
      <ComptableRoutes>
        <AdminLayout />
      </ComptableRoutes>
    ),
    children: [
      { element: <Navigate to="/comptable/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardComptable />,
      },
      {
        path: "messages",
        element: <MessagesComptable />,
      },
      {
        path: "mes-entrees",
        element: <MesEntrees />,
      },
      {
        path: "depenses",
        element: <Depenses />,
      },
      {
        path: "categorie",
        element: <Categorie />,
      },
      {
        path: "options",
        element: <OptionComptable />,
      },
    ],
  },
  {
    path: "admin/",
    element: (
      <ProtectedRoutes
        userType={[
          UserType.admin,
          UserType.superAdmin,
          UserType.juriste,
          UserType.marketeur,
        ]}
      >
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "messages",
        element: (
          <IsAdminAllow field="message">
            <MessagesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "mes-clients",
        element: (
          <IsAdminAllow field="client">
            <MesClients />
          </IsAdminAllow>
        ),
      },
      {
        path: "nos-offres",
        element: <OffersList />,
      },
      {
        path: "nos-abonnes",
        element: <SubscriberList />,
      },
      {
        path: "demande-suppression-compte/:slug",
        element: (
          <IsAdminAllow field="client">
            <AccountManagerDetails />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-client-proprietaire",
        element: (
          <IsAdminAllow field="client">
            <AjouterClient />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-client-proprietaire/:slug",
        element: (
          <IsAdminAllow field="client">
            <AjouterClient />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-client-agence",
        element: (
          <IsAdminAllow field="client">
            <AjouterAgence />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-client-agence/:slug",
        element: (
          <IsAdminAllow field="client">
            <AjouterAgence />
          </IsAdminAllow>
        ),
      },
      {
        path: "recettes",
        element: (
          <IsAdminAllow field="recette">
            <RecettesAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "administratif",
        element: (
          <IsAdminAllow field="administratif">
            <AdministratifAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "options",
        element: (
          <IsAdminAllow field="options">
            <OptionsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-administrateur",
        element: (
          <IsAdminAllow field="options">
            <AjouterAdministrateur />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-utilisateur",
        element: (
          <IsAdminAllow field="options">
            <AjouterUtilisateurAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-utilisateur/:slug",
        element: (
          <IsAdminAllow field="options">
            <AjouterUtilisateurAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "lead",
        element: (
          <IsAdminAllow field="lead">
            <Lead />
          </IsAdminAllow>
        ),
      },
      {
        path: "problemes",
        element: (
          <IsAdminAllow field="probleme">
            <Problemes />
          </IsAdminAllow>
        ),
      },
      {
        path: "details-agence/:slug",
        element: (
          <IsAdminAllow field="client">
            <DetailsAgence />
          </IsAdminAllow>
        ),
      },
      {
        path: "details-proprietaire/:slug",
        element: (
          <IsAdminAllow field="client">
            <DetailProprietaire />
          </IsAdminAllow>
        ),
      },
      {
        path: "details-administrateur/:slug",
        element: (
          <IsAdminAllow field="options">
            <DetailsAdministrateur />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-administrateur/:slug",
        element: (
          <IsAdminAllow field="options">
            <EditAdministrateur />
          </IsAdminAllow>
        ),
      },
      {
        path: "news",
        element: (
          <IsAdminAllow field="new">
            <NewsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "ajouter-news",
        element: (
          <IsAdminAllow field="new">
            <AjoutNewsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "modifier-news/:slug",
        element: (
          <IsAdminAllow field="new">
            <AjoutNewsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "details-news/:slug",
        element: (
          <IsAdminAllow field="new">
            <DetailsNewsAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "faq",
        element: (
          <IsAdminAllow field="faq">
            <FaqAdmin />
          </IsAdminAllow>
        ),
      },
      {
        path: "types-requetes",
        element: (
          <IsAdminAllow field="type_requete">
            <TypeRequetes />
          </IsAdminAllow>
        ),
      },
    ],
  },
];

export function isAdminUser(user?: IUser | IAdmin) {
  return (
    user && (user.user_type === "admin" || user.user_type === "superadmin")
  );
}

export function isAdminAgent(user?: IUser | IAdmin | IPersonnel) {
  return (
    user &&
    (user.user_type === "gerant" ||
      user.user_type === "comptable" ||
      user?.user_type === "marketeur" ||
      user?.user_type === "juriste") &&
    user?.agence_id === null
  );
}

export function isSuperAdmin(user?: IUser | IAdmin) {
  return user && user.user_type === "superadmin";
}
