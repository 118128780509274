/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IPropriete } from "../../../../../utils/api/propriete/propriete.type";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import ProprieteItem from "../ProprieteItem";
import { ProprieteCardSkeleton } from "../ProprietesAgence";

function VillaAppartement({
  proprietes,
  isLoading,
  handleFilterByTypePropriete,
  perPage,
  setPage,
  page,
  proprieteCount,
  handleFilterByName,
}: {
  handleFilterByTypeGerance: () => void;
  proprietes: IPropriete;
  isLoading: boolean;
  handleFilterByTypePropriete: () => void;
  perPage: any;
  setPage: any;
  page: any;
  proprieteCount: any;
  handleFilterByName: any;
}) {
  return (
    <div className="kanimmo-proprietes-container">
      <div className="proprietes-component-container">
        <div className="locataire-requete-filtre-container my-3">
          <form className="row g-3 locataire-requete-filtre-form">
            <div className="col-auto">
              <label
                htmlFor="filtreLogement"
                className="locataire-requete-filtre-label"
              >
                Filtrer
              </label>
            </div>
            <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select"
                onChange={handleFilterByTypePropriete}
              >
                {/* <option value="" selected disabled>Sélectionner une option </option> */}
                <option value="">Toutes mes propriétés</option>
                <option value="villa">Villas</option>
                <option value="appartement">Appartements</option>
              </select>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control locataire-requete-filtre-input"
                id="filtreLogement"
                placeholder="Entrer un nom"
                // value={query}
                onChange={handleFilterByName}
              />
            </div>
          </form>
        </div>
        <div className="content-proprietes locataire-requete-filtre-container mt-5">
          <div className="row">
            {!isLoading &&
              proprietes?.map((item: IPropriete) => (
                <ProprieteItem item={item} />
              ))}
            {isLoading &&
              [...Array(6)]?.map((item, i) => (
                <ProprieteCardSkeleton key={i} />
              ))}
            {!isLoading &&
              // proprietes &&
              (proprietes?.length === 0 || proprietes == undefined) && (
                <AlertInfo message="Aucune propriété trouvée" />
              )}
          </div>
          {/* {proprietes && proprietes?.length > perPage && ( */}
          <div className="flex-r">
            <Pagination
              page={page}
              total={proprieteCount}
              perPage={perPage}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default VillaAppartement;
