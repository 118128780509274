import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ReactQuill from "react-quill";
import { IRappel } from "../../../utils/api/rappel/rappel.type";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import UseAddOrUpdateRappel from "../../TableauDebord/Agence/OptionAgence/requestForm/UseAddOrUpdateRappel";

function AjoutRappelModal({
  rappel,
  modalId,
}: {
  rappel?: IRappel;
  modalId: string;
}) {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    setValue,
    handleChangeMessage,
    message,
  } = UseAddOrUpdateRappel(modalId, rappel);
  useEffect(() => {
    if (rappel) {
      setValue("slug", rappel?.slug);
      setValue("titre", rappel?.titre);
      setValue("message", rappel?.message);
    }
  }, [rappel]);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="titre"
                      className="form-label form-label-modal-custom"
                    >
                      Titre du rappel
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-modal-custom"
                      id="titre"
                      placeholder="Titre"
                      {...register("titre")}
                    />
                    {errors?.titre && (
                      <FormError error={errors?.titre?.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label form-label-modal-custom"
                    >
                      Message
                    </label>
                    <ReactQuill
                      className="editor-cgu"
                      value={message}
                      onChange={(value) => handleChangeMessage(value)}
                    />
                    {errors?.message && (
                      <FormError error={errors?.message?.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  <BtnSubmit
                    label={rappel ? "Modifier" : "Ajouter"}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutRappelModal;
