import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
// import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { useAppDispatch } from "../../../redux/hooks";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { cleannerError } from "../../../utils/Utils";
import { Color } from "../../../utils/theme";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { useLazyMeAdminQuery } from "../../../utils/api/admin/admin.api";
import moment from "moment";
import { useCountdown } from "../../../utils/UseCountDown";
import { AppLocalStorage, AppStorage } from "../../../utils/storage";
import { FaAppStore } from "react-icons/fa";
// import { AuthState } from "../../../utils/api/user/user.type";
// import { AppStorage } from "../../../utils/storage";

function useLoginForm() {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch();
  // let from = location?.state?.from?.pathname || location?.state?.pathname || '/'
  const validationSchema = yup.object().shape({
    email: yup.string().required().label("Email"),
    password: yup.string().required().label("Mot de passe"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  let nb: any = AppStorage.getItem("nbErr");
  const [nbErr, setNbErr] = useState(nb || 0);
  const [disabled, setDisabled] = useState(false);
  // const dispatch = useAppDispatch();

  //  Calling the login Mutation
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();
  let date = new Date();
  let endDate: any = new Date(date.getTime() + 5 * 60000);
  let time: any = AppLocalStorage.getItem("countDown");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const timerId = useRef<any>(null);

  useEffect(() => {
    // AppLocalStorage.setItem("countDown",new Date(date.getTime() + 5 * 60000))
    console.log({ data });
    if (data) {
      dispatch(onSetUserToken({ user: data.data, token: data.token }));
    }
  }, [data]);

  const getTwoDigitValue = (value: any) => {
    if (value < 10) {
      return "0" + value;
    }
    return "" + value;
  };

  const calculateCountdown = () => {
    const startDate: any = new Date();

    if (!time || time === undefined) {
      AppLocalStorage.setItem("countDown", endDate);
    }
    const timeRemaining: any = new Date(time).getTime() - startDate.getTime();

    // console.log("timeRemaining", timeRemaining);

    if (timeRemaining > 0) {
      const start_date = new Date(startDate);
      // const end_date = new Date(endDate);
      const start_millis = start_date.getTime(); // Get timestamp of start date
      const end_millis: any = new Date(time).getTime(); // Get timestamp of end date

      // Convert to seconds, 1 second = 1000 milli seconds
      const old_sec = start_millis / 1000;
      const current_sec = end_millis / 1000;

      // Get remaining seconds
      let seconds = current_sec - old_sec;

      let days = Math.floor(seconds / (24 * 60 * 60)); // 1 day is equal to 24 hours, each hour has 60 mins and each minute has 60 seconds
      seconds -= days * 24 * 60 * 60; // Get remaining seconds

      let hours = Math.floor(seconds / (60 * 60)); // 1 hour has 60 mins and each minute has 60 seconds
      seconds -= hours * 60 * 60; // Get remaining seconds

      let minutes = Math.floor(seconds / 60); // 1 minute is equal to 60 seconds
      seconds -= minutes * 60; // Get remaining seconds

      minutes = Math.abs(minutes);
      seconds = Math.floor(Math.abs(seconds));

      setMinutes(minutes);
      setSeconds(seconds);
      clearTimeout(timerId.current);
      timerId.current = setTimeout(calculateCountdown, 1000);
    } else {
      setDisabled(false);
      setNbErr(0);
      clearTimeout(timerId.current);
    }
  };

  useEffect(() => {
    // console.log(nbErr);
    if (nbErr === 3) {
      setDisabled(true);
      calculateCountdown();
    }

    return () => {
      // clearTimeout(timer)
      clearTimeout(timerId.current);
      timerId.current = null;
    };
  }, [nbErr]);
  const convertedMins = getTwoDigitValue(minutes);
  const convertedSeconds = getTwoDigitValue(seconds);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Connexion réussie!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      });
    }
    if (isError) {
      let date = new Date();
      let endDate = new Date(date.getTime() + 5 * 60000);
      const err = error as any;
      if (err?.data?.message?.includes("Your credentials are")) {
        setNbErr(nbErr + 1);
        AppStorage.setItem("nbErr", nbErr + 1);
        if (nbErr + 1 === 3) {
          AppLocalStorage.setItem("countDown", endDate);
        }
        // console.log("error", err);
      }

      const { message = "Une erreur inconnue s'est produite" } = err.data || {};
      setError("email", { message: message });
    }
  }, [isLoading]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = (data: LoginFormData) => {
    if (!disabled) {
      AppLocalStorage.removeItem("countDown");
      if (data?.email) {
        data["email"] = (data?.email).toLowerCase();
      }
      if (data?.username) {
        data["username"] = (data?.username).toLowerCase();
      }

      loginUser(data);
    }
  };
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    minutes: convertedMins,
    seconds: convertedSeconds,
    disabled,
  };
}

export default useLoginForm;
