import React from "react";
import dollardIcon from "../../../../assets/icons/dollardIcon.png";
import Users from "../../../../assets/icons/users.png";
import Paper from "../../../../assets/icons/paper.png";
import RecetteAdminTable from "../../../tables/Admin/Recettes/RecetteAdminTable";
import "./RecettesAdmin.css";
import { useGetAdminRecetteQuery } from "../../../../utils/api/admin/admin.api";
import { formatMontant } from "../../../../utils/Utils";
import { useSearchParams } from "react-router-dom";
import { BiExport } from "react-icons/bi";
import { ApiBaseUrl } from "../../../../utils/http";

import { useRef, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { CAFilterQuery } from "../../../../utils/api/admin/admin.type";
import { useGetAbonmentsQuery } from "../../../../utils/api/offer/offer.api";

function RecettesAdmin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetAbonmentsQuery({
    page,
    limit,
  });

  const handleFilterByType = (e: React.FormEvent<HTMLSelectElement>): void => {
    if (e.currentTarget.value) {
      setSearchParams({
        type: e.currentTarget.value,
      });
    } else {
      setSearchParams({});
    }
  };

  const [query, setQuery] = useState<CAFilterQuery>({
    start_date: "",
    end_date: "",
  });
  const onQueryChange = (
    field: keyof CAFilterQuery,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };
  // const { data, isLoading } = useGetAdminRecetteQuery({ type, ...query });
  return (
    <div className="dashboard-admin-component">
      <div className="row container-dashboard-page">
        <div className="col-md-12 pb-3">
          <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
            <div className="row px-4 d-flex align-items-center">
              <div className="col-xl-4 col-lg-5 mb-3 px-md-2">
                <div className="item-stat-dashboad-view custom-item-stat-dashboad-view">
                  <div className="content-stat-dashboard-view">
                    <div className="content-item-icon-stat-dashboard-view">
                      <img
                        src={dollardIcon}
                        alt="icon"
                        className="icon-item-stat-dashboad-view"
                      />
                    </div>
                    <div className="content-text-item-stat-dashboad-view ms-3">
                      <div className="content-item-stat-dashboad-view mt-2">
                        <p className="titre-item-stat-dashboad-view mb-1">
                          Chiffres d’Affaire de la période
                        </p>
                        {/* <p className="users-item-stat-dashboad-view mb-1">Cette semaine</p> */}
                      </div>
                      <div className="icon-item-stat-dashboad-view">
                        <p className="chiffre-item-stat-dashboad-view mb-1">
                          {/* {formatMontant(data?.total_entrees)} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 mb-3">
                <div className="d-flex align-items-center gap-3 custom-date-range">
                  <span className="custom-recette-admin-input-date-span">
                    Filtrer
                  </span>
                  <DateRange
                    start={query.start_date}
                    end={query.start_date}
                    onChange={(start, end) => {
                      onQueryChange("start_date", start);
                      onQueryChange("end_date", end);
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-md-3 mb-3">
                <div className="d-flex align-items-center gap-3">
                  <span className="custom-recette-admin-input-date-span">
                    Du
                  </span>
                  <input
                    type="date"
                    className="form-control custom-form-control"
                    id="boite_postale"
                    placeholder="Date de début"
                    // onChange={handleFilterByDateDebut}
                  />
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="d-flex align-items-center gap-3">
                  <span className="custom-recette-admin-input-date-span">
                    Au
                  </span>
                  <input
                    type="date"
                    className="form-control custom-form-control"
                    id="boite_postale"
                    placeholder="Date de fin"
                    // onChange={handleFilterByDateFin}
                  />
                </div>
              </div> */}
            </div>
            <div className="bg-white p-3 filter-and-table-container">
              <div className="">
                <form className="row g-3 locataire-requete-filtre-form">
                  <div className="col-auto">
                    <label
                      htmlFor="filtreLogement"
                      className="locataire-requete-filtre-label"
                    >
                      Filtrer
                    </label>
                  </div>
                  <div className="col-auto">
                    <select
                      className="form-select filtre-select custom-form-control"
                      aria-label="Default select example"
                      onChange={handleFilterByType}
                    >
                      <option value="" selected>
                        Sélectionnez une option
                      </option>
                      <option value="agence">Agences immobilières</option>
                      <option value="proprietaire">Proprietaire</option>
                    </select>
                  </div>
                </form>
                <div className="dropdown dis-flex mt-3">
                  <button
                    className="btn btn-see-more dropdown-toggle btn"
                    type="button"
                    onClick={(e) => {
                      e?.preventDefault();
                      if (query?.start_date != "" && query?.end_date != "") {
                        window
                          ?.open(
                            `${ApiBaseUrl}/api/export_recette?${query?.start_date}&${query?.end_date}`,
                            "_blank"
                          )
                          ?.focus();
                      } else {
                        window
                          ?.open(`${ApiBaseUrl}/api/export_recette`, "_blank")
                          ?.focus();
                      }
                    }}
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <BiExport style={{ fontSize: 22 }} />
                      <span className="ps-2">Exporter les recettes</span>
                    </div>
                  </button>
                </div>
              </div>
              <div>
                <RecetteAdminTable
                  data={data}
                  isLoading={isLoading}
                  page={page}
                  setPage={setPage}
                  setLimit={setLimit}
                  limit={limit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecettesAdmin;

type DateRangeProps = {
  onChange: (start: string, end: string) => any;
  start?: string | null;
  end?: string | null;
  isDayBlocked?: (day: moment.Moment) => boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  labels?: [string | undefined, string | undefined];
};
const isSame = (d1: moment.Moment, d2: moment.Moment) =>
  d1.format("L") === d2.format("L");
export function DateRange({
  start,
  end,
  onChange,
  isDayBlocked,
  labels,
}: DateRangeProps) {
  const [startDate, setStartDate] = useState<any>(start ? moment(start) : null);
  const [endDate, setEndDate] = useState<any>(end ? moment(end) : null);
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);

  const ref = useRef();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      ref={ref}
      endDateId="endDate"
      startDatePlaceholderText={(labels && labels[0]) || "Du"}
      endDatePlaceholderText={(labels && labels[1]) || "Au"}
      onDatesChange={({
        startDate,
        endDate,
      }: {
        startDate: any;
        endDate: any;
      }) => {
        onChange(
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        );
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      showClearDates={true}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => (start ? moment(start) : moment())}
      isOutsideRange={() => false}
    />
  );
}
