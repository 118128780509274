import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  AnnonceFormData,
  IAnnonce,
} from "../../../../../utils/api/annonces/annonce.type";
import {
  useAddOrUpdateAnnonceMutation,
  useDeleteAnnonceMutation,
} from "../../../../../utils/api/annonces/annonce.api";

function useNoteForm(modalId: string, note?: IAnnonce) {
  const validationSchema = yup.object().shape({
    envoiType: yup.string().label("Envoi type"),
    titre: yup.string().required().label("Titre"),
    content: yup.string().label("Content"),
  });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<AnnonceFormData>({
    resolver: yupResolver(validationSchema),
  });

  interface Locataire {
    nom: string;
    email: string;
    user_type: string;
    id: number;
  }

  const [locataires, setLocataires] = useState<Locataire[]>([]);
  const [envoiType, setEnvoiType] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [addOrUpdateAnnonce, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateAnnonceMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: note
          ? "Note modifiée avec succès !"
          : "Note ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!note) {
          reset();
          //   setPreviewImage(null);
          setContent("");
          setEnvoiType("");
          setLocataires([]);
        }
        onHide(modalId);
        // navigate("/agence/locataires");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);

  const [proprietaires, setProprietaires] = useState<string[]>([]);

  const onSubmit = (data: any) => {
    console.log(data);
    
    const destinataires = JSON.parse(data.destinataires || "[]");
    if (locataires.length === 0) {
      setError("envoiType", {
        type: "required",
        message: "Type est un champ obligatoire",
      });
      return;
    }

    if (!note) {
      data["auteur"] = user?.agence_id?.slug ? user?.agence_id?.id : user?.id;
      data["type"] = "note";
    }

    const locatairesEmails = locataires.map((locataire) => ({
      email: locataire.email,
    }));

    const locatairesJSON = JSON.stringify(locatairesEmails);
    data["destinataires"] = locatairesJSON;

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }

    // console.log("data submitted", data);
    addOrUpdateAnnonce({ slug: note?.slug, data: fd });
  };

  const handleReset = () => {
    if (!note) {
      reset();
    }
  };

  useEffect(() => {
    if (note?.id) {
      const fields: (keyof AnnonceFormData)[] = [
        "titre",
        "content",
        "envoiType",
        // "image",
      ];

      for (let field of fields) {
        if (field === "content") {
          setContent(note[field] || "");
        }
      }
    }
  }, [note]);

  const handleChangeContent = useCallback((value: string) => {
    setContent(value);
    setValue("content", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    envoiType,
    setEnvoiType,
    locataires,
    setLocataires,
    proprietaires,
    setProprietaires,
    handleChangeContent,
    handleReset,
    // handelImageChange,
    // previewImage,
  };
}

export default useNoteForm;

export function UseDeleteNote(slug: string, modalID: string) {
  const [deleteData] = useDeleteAnnonceMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette note ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        // console.log(modalID)
        Swal.fire({
          icon: "success",
          title: `Note supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          onHide("EnvoieSuppressionNoteModal");
        });
      }
    });
  };
  return onDelete;
}
