import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { MdModeEditOutline } from 'react-icons/md';
import { Pagination } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { UseDeleteRappel } from '../../../TableauDebord/Agence/OptionAgence/requestForm/UseAddOrUpdateRappel';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetRappelsQuery } from '../../../../utils/api/rappel/rappel.api';
import RappelAgenceSkeleton from './RappelAgenceSkeleton';
import { createMarkup } from '../../../../utils/Utils';
import AjoutRappelModal from '../../../modal/agence/AjoutRappelModal';
import { CustomPagination } from '../../../common/CustomPagination';
import { AlertInfo } from '../../../common/Alert';

function RappelAgenceTable() {

    const { user } = useAppSelector((s) => s?.user);
    const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data = { results: [] }, isLoading } = useGetRappelsQuery({
		page,
		limit: perPage,
        // slug: user?.slug
	});
  // console.log("donnée rappel", data)

    const actionFormatter = (cell: any, row: any) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex">
                    {/* <div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
                            data-tooltip-content="Voir"
						>
							<AiFillEye />
						</button>
					</div> */}
                    <div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
                            data-tooltip-content="Voir"
                            data-bs-toggle="modal" 
                            data-bs-target={`#AjouterRappelModal${cell}`}
						>
							<MdModeEditOutline />
						</button>
					</div>
                    <div className="container-btn-action-icon-modal">
						<DeleteLocataireBtn slug={row?.slug} />
					</div>

                    <div className="modal fade" id={`AjouterRappelModal${cell}`} aria-labelledby="AjouterRappelModalLabel" aria-hidden="true">
                        <AjoutRappelModal modalId={`AjouterRappelModal${cell}`} rappel={row} />
                    </div>
				</div>
			</>
		);
	};

    const messageFormatter = (cell: any, row: any) => {
        return(
            <div className="mb-3 custom-envoie-note-description"
                dangerouslySetInnerHTML={createMarkup(cell)}
            />
        )
    }

    const columns = [
		{
			dataField: "titre",
			text: "Titre",
            headerStyle: () => {
				return { width: "200px" };
			},
		},
		{
			dataField: "message",
			text: "Message",
            formatter: (cell: any, row: any) => messageFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "130px" };
			},
		},
	];

    return (
		<>
			{isLoading && <RappelAgenceSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => <AlertInfo message="Aucune donnée trouvéé" />}
                    />
					<CustomPagination
                        nbPages={8}
                        page={page}
                        onChange={(page, perPage) => {
                            setPerPage(perPage);
                            setPage(page);
                        }}
                        perPage={perPage}
                    />
				</>
			)}
		</>
	);
}

export default RappelAgenceTable

function DeleteLocataireBtn({ slug }: { slug: string }) {
	const onDelete = UseDeleteRappel(slug);

	return (
        <button
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Voir"
            onClick={() => onDelete()}
        >
            <BsTrash />
        </button>
	);
}