import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

function RappelAgenceSkeleton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
                width: "200px",
              }}
            >
              Titre
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              Message
            </th>
            <th
              scope="col"
              style={{
                textAlign: "justify",
                color: "#1F3CAD",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "400",
                width: "130px",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <VolkenoSkeleton height={20} variant="rect" />
            </th>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
            <td>
              <VolkenoSkeleton height={20} variant="rect" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RappelAgenceSkeleton;
