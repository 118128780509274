import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import DepenseComptabiliteTableSkeleton from "../../agence/DepenseComptabiliteTable/DepenseComptabiliteTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AlertInfo } from "../../../common/Alert";
import { formatMontant, formattedDate } from "../../../../utils/Utils";
import { ILocataire } from "../../../../utils/api/locataire/locataire.type";
import { useDeleteDepenseMutation } from "../../../../utils/api/depense/depense.api";
import DetailDepenseModal from "../../../modal/comptabilite/DetailDepenseModal";
import { IDepense } from "../../../../utils/api/depense/depense.type";
import AjouterDepenseModal from "../../../modal/comptabilite/AjouterDepenseModal";
import { CustomPagination } from "../../../common/CustomPagination";
import { IoMdTrash } from "react-icons/io";
import { useDelete } from "../../../../utils/helpers";

function DepensesTableComptable({
  data,
  isLoading,
  perPage,
  page,
  setPage,
  setPerPage,
  count,
}: {
  data?: any;
  isLoading: boolean;
  perPage: number;
  page: number;
  setPage: any;
  setPerPage: any;
  count?: number;
}) {
  const [item, setItem] = useState<IDepense>();
// console.log("data", data);

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              data-bs-toggle="modal"
              data-bs-target={`#ModifierDepenseModal${row?.slug}`}
            >
              <MdEdit />
            </button>
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#DetailDepenseModal${cell}`}
            >
              <AiFillEye />
            </button>
            <DeleteDepense item={row} />
          </div>
          <div>
            <DetailDepenseModal depense={row} />
          </div>
          <div
            className="modal fade"
            id={`ModifierDepenseModal${row?.slug}`}
            aria-labelledby="AjouterDepenseModalLabel"
            aria-hidden="true"
          >
            <AjouterDepenseModal
              modalId={`ModifierDepenseModal${row?.slug}`}
              depense={row}
            />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "numero",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string) => formattedDate(cell),
    },
    {
      dataField: "occupant",
      text: "Client",
      formatter: (cell: ILocataire) => cell?.prenom + " " + cell?.nom,
    },
    {
      dataField: "montant",
      text: "Montant",
      formatter: (cell: any) => formatMontant(cell),
    },
    {
      dataField: "charge",
      text: "Charge",
      formatter: (cell: any) => 
        cell == "agence"
          ? "Agence"
          : cell == "locataire"
          ? "Locataire"
          : "Propriétaire"
    },
    {
      dataField: "slug",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  // console.log('data?.length', data?.length)

  return (
    <>
      {isLoading && <DepenseComptabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          {!!data?.length && (
            <CustomPagination
              nbPages={count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          )}
        </>
      )}
    </>
  );
}

export default DepensesTableComptable;

export function DeleteDepense({ item }: { item: IDepense }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteDepenseMutation();
  const onDelete = useDelete<IDepense>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette dépense?",
    successMessage: "dépense supprimée",
  });
  return (
    <button
      className="btn btn-action-administratif-proprietaire with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={onDelete}
    >
      <IoMdTrash />
    </button>
  );
}
