import React from "react";
import "./ProprietesAgence.css";
import { AiFillPlusSquare } from "react-icons/ai";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { TypeGerance } from "../../../../utils/api/propriete/propriete.type";
import useProprieteAgence from "./requestPropriete/useProprieteAgence";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import VillaAppartement from "./proporieteTabs/VillaAppartement";
import Immeubles from "./proporieteTabs/Immeubles";
import AjouterProprietesModal from "../../../modal/agence/AjouterProprietesModal";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useCompareNpPropieteAndMaxOffreQuery } from "../../../../utils/api/offer/expireataabonnement.api";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import ChangeOfferModal from "../OptionAgence/OptionsStep/ChangeOfferModal";
import { showModal } from "../../../../utils/Utils";
import Swal from "sweetalert2";
import { onHide } from "../../../../utils/Utils";

const steps = [
  { id: "villa-appartement", Component: VillaAppartement },
  { id: "immeubles", Component: Immeubles },
];

const tabs = ["Villa et Appartement", "Immeubles"];

const callChangeOffreModal = () => {
  onHide("AjouterProprietesModal");
  Swal.fire({
    icon: "error",
    title:
      "Veuillez modifier votre abonnement pour pouvoir effectuer cette opération.",
    showConfirmButton: false,
    timer: 5000,
  });
  showModal("changeOfferModal");
};

function ProprietesAgence() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const { user } = useAppSelector((state) => state.user);

  const { data, error, refetch } = useCompareNpPropieteAndMaxOffreQuery(
    user.slug
  );

  console.log("teste add ", data);

  const {
    proprietes,
    isLoading,
    handleFilterByTypeGerance,
    handleFilterByTypePropriete,
    perPage,
    setPage,
    page,
    proprieteCount,
    immeubles,
    handleFilterByName,
    handleFilterByNameImmo,
    immoCount,
    query,
    setPageImmo,
    pageImmo,
  } = useProprieteAgence();
  const props = {
    handleFilterByTypeGerance,
    proprietes,
    isLoading,
    handleFilterByTypePropriete,
    perPage,
    setPage,
    page,
    proprieteCount,
    immeubles,
    handleFilterByName,
    handleFilterByNameImmo,
    query,
    setPageImmo,
    pageImmo,
    immoCount,
  };
  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <div className="custom-filter-titre-select-container">
            <h4 className="kanimmo-dash-locataire-requetes-header-titre">
              Mes Propriétés
            </h4>
            {/* <div className="col-auto">
              <select
                id="villa"
                className="form-select filtre-select-propriete"
                onChange={handleFilterByTypeGerance}
                value={`${type_gerance}`}
              >
              
                <option value="" selected>
                  Tous
                </option>
                <option value={TypeGerance.partiel}>Gérés partiellement</option>
                <option value={TypeGerance.total}>Gérés totalement</option>
              </select>
            </div> */}
          </div>
          <div className="container-btn-add">
            {data?.can_add_proprete && (
              <>
                {index === 1 ? (
                  <NavLink
                    to="/agence/ajouter-propriete-immeuble"
                    state={{ type_propriete: "immeuble" }}
                    className="btn btn-see-more"
                  >
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                    <span className="ps-2">Ajouter un immeuble</span>
                  </NavLink>
                ) : (
                  <Button
                    data-bs-toggle="modal"
                    data-bs-target="#AjouterProprietesModal"
                    className="btn btn-see-more"
                  >
                    <AiFillPlusSquare style={{ fontSize: 22 }} />
                    <span className="ps-2">Ajouter une propriété</span>
                  </Button>
                )}
              </>
            )}

            {!data?.can_add_proprete && (
              <Button
                data-bs-toggle="modal"
                // data-bs-target="#AjouterProprietesModald"
                className="btn btn-see-more"
                onClick={() => callChangeOffreModal()}
              >
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter une propriété</span>
              </Button>
            )}

            {/*  */}
          </div>
          <div
            className="modal fade"
            id="AjouterProprietesModal"
            aria-labelledby="AjouterProprietesModalLabel"
            aria-hidden="true"
          >
            <AjouterProprietesModal modalId="AjouterProprietesModal" />
          </div>
        </div>
        <div className="kanimmo-tabs-locataire-container">
          <ul className="tabs-container pt-5 tabs-container-agence">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changeOfferModal"
        aria-labelledby="changeOfferModalLabel"
        aria-hidden="true"
      >
        <ChangeOfferModal
          type={user?.user_type === "agence" ? "agence" : "proprietaire"}
        />
      </div>
    </div>
  );
}

export const ProprieteCardSkeleton = () => {
  return (
    <div className="col-lg-4 col-md-4 d-flex mb-3">
      <div className="proprietes-card-item card">
        <VolkenoSkeleton variant="rect" height="316px" width="100%" />
        <div className="card-body pb-0 mb-0">
          <VolkenoSkeleton variant="rect" height={10} width="100%" />
          <VolkenoSkeleton variant="rect" height={13} width="100%" />
          <div className="flex-sb mt-2">
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" height={10} width={100} />
            </div>
          </div>
          <VolkenoSkeleton variant="rect" height={12} width="30%" />
        </div>
        <div className="flex-r pe-2">
          <VolkenoSkeleton variant="circle" height="20px" width="20px" />
        </div>
      </div>
    </div>
  );
};
export default ProprietesAgence;
