import React from "react";
import { IoClose } from "react-icons/io5";
import { BtnSubmit } from "../../common/Button";
import { FormError } from "../../common/CustomInputCheckbox";
import useRappelPaiementProprietaire from "../../tables/agence/LocatairesNonPayeTable/useRapellePaiementProprietaire";
import "./AgenceModal.css";

function RappelPaiementProprietaireModal({
  locataireData,
  modalId,
}: {
  locataireData?: any;
  modalId: string;
}) {
  const { register, errors, onSubmit, isLoading } =
    useRappelPaiementProprietaire(modalId, locataireData);

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button
            className="close-modal auth-submit-annuler"
            data-bs-dismiss="modal"
            type="button"
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                {/* <div className="col-md-8">
                      <p>Nouvelle discussion</p>
                    </div> */}
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="message"
                        className="form-label form-label-modal-custom"
                      >
                        Message
                      </label>
                      <textarea
                        className="form-control form-control-modal-custom"
                        id="message"
                        rows={4}
                        {...register("message")}
                      ></textarea>
                      {<FormError error={errors.message} />}
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="lien"
                        className="form-label form-label-modal-custom"
                      >
                        Lien de paiment de la facture
                      </label>
                      <input
                        type="text"
                        placeholder="Lien de paiment de la facture"
                        className="form-control form-control-modal-custom"
                        id="lien"
                        {...register("url_paiement")}
                      />
                      {<FormError error={errors.url_paiement} />}
                    </div>
                  </div> */}
                </div>
                <div className="container-btn-modal row mt-5">
                  <div className="col-md-3">
                    <button
                      className="btn btn-cancelled"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-md-6 offset-md-3 flex-r">
                    <BtnSubmit label="Envoyer" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RappelPaiementProprietaireModal;
