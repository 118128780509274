import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAppSelector } from "../../../redux/hooks";
import { BtnCloseModal, BtnSubmit } from "../../common/Button";
import { InputPassword } from "../../common/Input";
import UseFormChangePassword from "../../TableauDebord/Admin/Options/CrudAdministrateur/useForm/UseFormChangePassword";

function ChangePasswordModal() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    passwordHandleReset,
  } = UseFormChangePassword();
  const {user} = useAppSelector((s) => s?.user)
  return (
    <div
      className="modal fade"
      id="ChangePasswordModal"
      data-bs-backdrop="static"
      aria-labelledby="ChangePasswordModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header px-2 py-1">
            <h5 className="modal-title" id="ChangePasswordModalLabel">
              Changer de mot de passe
            </h5>
            {!user?.first_connexion && (
              <BtnCloseModal label="" onClick={() => passwordHandleReset()} />
            )}
          </div>
          <form action="" onSubmit={onSubmit}>
            <div className="row py-3 px-2">
              <div className="col-12 mb-3">
                <InputPassword
                  label="Ancien mot de passe"
                  id="password"
                  placeholder="Ancien mot de passe"
                  {...register("old_password")}
                  error={errors?.old_password}
                />
              </div>
              <div className="col-12 mb-3">
                <InputPassword
                  label="Nouveau mot de passe"
                  id="new_password"
                  placeholder="Nouveau mot de passe"
                  // {...register("new_password")}
                  onChange={passwordHandleChange}
                  error={errors?.new_password}
                />
              </div>
              <div className="col-12 mb-4">
                <InputPassword
                  label="Confirmer le mot de passe"
                  id="confirm_password"
                  placeholder="Confirmer le mot de passe"
                  {...register("confirm_password")}
                  error={errors?.confirm_password}
                />
              </div>
              <div className="flex-r mb-3">
                <BtnSubmit label="Changer" isLoading={isLoading} />
              </div>
              <div className="col-md-12">
                <div className="input-group my-2 password-validation-terms-row">
                  <div className="password-validation-terms-container">
                    <h4 className="password-validation-terms-title">
                      Votre mot de passe doit contenir :{" "}
                    </h4>
                    <ul className="password-validation-terms-ul p-l-14">
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinCharacter && "text-success")
                        }
                      >
                        Au minimum 8 caractères
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinUppercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en majuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinLowercase && "text-success")
                        }
                      >
                        Au minimum 1 caractère en minuscule
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinNumber && "text-success")
                        }
                      >
                        Au minimum 1 nombre
                      </li>
                      <li
                        className={
                          "password-rule-item " +
                          (haveMinSpecialCharacter && "text-success")
                        }
                      >
                        Au minimum 1 caractère spéciale
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
