import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ILocataire } from "../../../../utils/api/locataire/locataire.type";
import { formatMontant, getName } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import RappelPaiementModal from "../../../modal/agence/RappelPaiementModal";
import RappelPaiementProprietaireModal from "../../../modal/agence/RappelPaiementProprietaireModal";
import LocatairesNonPayeTableSkeleton from "./LocatairesNonPayeTableSkeleton";

function LocatairesNonPayePQGSBTable({
  locataires,
  isLoading,
}: {
  locataires?: [];
  isLoading: boolean;
}) {
  console.log("locataires", locataires);
  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex">
          <div>
            <button
              className="btn btn-send-rappel"
              title="Envoyer un rappel"
              data-bs-toggle="modal"
              data-bs-target="#RappelPaiementModal"
            >
              Envoyer un rappel
            </button>
          </div>
          <div
            className="modal fade"
            id="RappelPaiementModal"
            aria-labelledby="RappelPaiementModalLabel"
            aria-hidden="true"
          >
            <RappelPaiementProprietaireModal
              modalId="RappelPaiementModal"
              locataireData={row}
            />
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "num",
      text: "N°",
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + 1,
    },
    {
      dataField: "non_locataire",
      text: "Nom",
      formatter: (cell: any, row: any) =>
        `${row.prenom_locataire} ${row.non_locataire}`,
    },
    {
      dataField: "nom_propriete",
      text: "Villa/Appartement",
    },
    {
      dataField: "prix_ttc",
      text: "Montant à payer",
      formatter: (cell: any, row: any) =>
        `${new Intl.NumberFormat("fr-FR").format(cell)} frs`,
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      // headerStyle: () => {
      // 	return { width: "130px" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <LocatairesNonPayeTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={locataires || []}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
        </>
      )}
    </>
  );
}

export default LocatairesNonPayePQGSBTable;
