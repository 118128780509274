import React, { useState, useRef, useEffect } from "react";
import { AiFillPlusSquare } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { ApiBaseUrl } from "../../../../utils/http";
import AjouterDepenseModal from "../../../modal/comptabilite/AjouterDepenseModal";
import DepensesTableComptable from "../../../tables/Comptables/MesEntrees/DepensesTableComptable";
import { DateFilterQuery } from "../../../../utils/api/admin/admin.type";
import { useGetDepenseByComptableBySlugQuery } from "../../../../utils/api/depense/depense.api";
import { DateRangePicker } from "react-dates";
import { useAppSelector } from "../../../../redux/hooks";
import { IDepense } from "../../../../utils/api/depense/depense.type";
import moment from "moment";

function Depenses() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const comptable = useAppSelector((s) => s.user.user);
  const [query, setQuery] = useState<DateFilterQuery>({
    date_debut: "",
    date_fin: "",
  });
  const { data = { results: [], count: 0 }, isLoading } =
    useGetDepenseByComptableBySlugQuery({
      slug: comptable?.slug,
      page: page,
      limit: perPage,
      ...query,
    });

  const onQueryChange = (
    field: keyof DateFilterQuery,
    value: string | number
  ) => {
    setQuery((old) => ({ ...old, [field]: value }));
  };

  // useEffect(() => {
  //   if (query?.date_debut != "" && query?.date_fin != "") {
  //     setDepenses(
  //       data?.results?.filter(
  //         (item) =>
  //           moment(item.created_at).isSameOrAfter(moment(query?.date_debut)) &&
  //           moment(query?.date_fin).isSameOrAfter(moment(item?.created_at))
  //       )
  //     );
  //   } else if (query?.date_debut != "") {
  //     setDepenses(
  //       data?.results?.filter((item) =>
  //         moment(item.created_at).isSameOrAfter(moment(query?.date_debut))
  //       )
  //     );
  //   } else if (query?.date_fin != "") {
  //     setDepenses(
  //       data?.results?.filter((item) =>
  //         moment(query?.date_fin).isSameOrAfter(moment(item?.created_at))
  //       )
  //     );
  //   } else {
  //     setDepenses(data?.results);
  //   }
  // }, [data, query]);

  return (
    <div className="landing-page-container">
      <div className="admin-page-title-header-container">
        <div className="kanimmo-dash-locataire-requetes-header mb-3">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Dépenses
          </h4>
          <button
            className="btn btn-see-more"
            title="Ajouter une dépense"
            data-bs-toggle="modal"
            data-bs-target="#AjouterDepenseModal"
          >
            <AiFillPlusSquare style={{ fontSize: 22 }} />
            <span className="ps-2">Ajouter une dépense</span>
          </button>
          <div
            className="modal fade"
            id="AjouterDepenseModal"
            aria-labelledby="AjouterDepenseModalLabel"
            aria-hidden="true"
          >
            <AjouterDepenseModal modalId="AjouterDepenseModal" />
          </div>
        </div>
        <div className="bg-white p-3 filter-and-table-container">
          <div className="p-0 p-md-4 ">
            <div className="locataire-requete-filtre-container my-4">
              <form className="row g-3 locataire-requete-filtre-form">
                <div className="col-auto">
                  <label
                    htmlFor="filtreLogement"
                    className="locataire-requete-filtre-label"
                  >
                    Filtrer
                  </label>
                </div>
                <div className="col-auto">
                  <DateRange
                    start={query.date_debut}
                    end={query.date_fin}
                    onChange={(start, end) => {
                      onQueryChange("date_debut", start);
                      onQueryChange("date_fin", end);
                    }}
                  />
                </div>
                {data?.count && data?.count > 0 ? (
                  <div className="col-auto">
                    <button
                      className="btn btn-see-more dropdown-toggle btn"
                      type="button"
                      onClick={(e) => {
                        e?.preventDefault();
                        if (query?.date_debut != "" && query?.date_fin != "") {
                          window
                            ?.open(
                              `${ApiBaseUrl}/api/agence/${comptable?.agence_id?.slug}/export_depense/?date_debut=${query?.date_debut}&date_fin=${query?.date_fin}`,
                              "_blank"
                            )
                            ?.focus();
                        } else {
                          window
                            ?.open(
                              `${ApiBaseUrl}/api/agence/${comptable?.agence_id?.slug}/export_depense`,
                              "_blank"
                            )
                            ?.focus();
                        }
                      }}
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <BiExport style={{ fontSize: 22 }} />
                        <span className="ps-2">Exporter les dépenses</span>
                      </div>
                    </button>
                  </div>
                ) : null}
              </form>
            </div>

            <div className="mb-3 px-4">
              <span className="kannimo-comptable-table-entree-titre">
                Dépenses
              </span>
            </div>
            <DepensesTableComptable
              data={data?.results}
              isLoading={isLoading}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
              count={data?.count}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Depenses;

type DateRangeProps = {
  onChange: (start: string, end: string) => any;
  start?: string | null;
  end?: string | null;
  isDayBlocked?: (day: moment.Moment) => boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  labels?: [string | undefined, string | undefined];
};
const isSame = (d1: moment.Moment, d2: moment.Moment) =>
  d1.format("L") === d2.format("L");
export function DateRange({
  start,
  end,
  onChange,
  isDayBlocked,
  labels,
  isOutsideRange,
}: DateRangeProps) {
  // const [blockeds, setBlockeds] = useState<moment.Moment[]>([]);
  // const [startDateObj, setStartDateObj] = useState<moment.Moment | null>(null);
  // const [reload, setReload] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(start ? moment(start) : null);
  const [endDate, setEndDate] = useState<any>(end ? moment(end) : null);
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  // const outside = (day: moment.Moment) => {
  //   return day < moment();
  // };
  // const isOutside = (day: moment.Moment) => {
  //   const ok = outside(day);
  //   if (isOutsideRange) return ok && isOutsideRange(day);
  //   return ok;
  // };
  // const blocked = (day: moment.Moment) => {
  //   if (isDayBlocked) {
  //     const bool = isDayBlocked(day);
  //     if (bool) {
  //       setBlockeds((old) => {
  //         const index = old.findIndex((b) => isSame(b, day));
  //         if (index === -1) return [...old, day];
  //         return old;
  //       });
  //     }
  //     return bool;
  //   }
  //   return false;
  // };

  const ref = useRef();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="startDate"
      endDate={endDate}
      ref={ref}
      endDateId="endDate"
      startDatePlaceholderText={(labels && labels[0]) || "Du"}
      endDatePlaceholderText={(labels && labels[1]) || "Au"}
      // isDayBlocked={blocked}
      onDatesChange={({
        startDate,
        endDate,
      }: {
        startDate: any;
        endDate: any;
      }) => {
        onChange(
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        );
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      showClearDates={true}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => (start ? moment(start) : moment())}
      isOutsideRange={() => false}
    />
  );
}
