import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../../utils/type";
import Contrat from "./AdministratifStep/Contrat";
import EtatDesLieux from "./AdministratifStep/EtatDesLieux";
import "./AdministratifPQGSB.css";
import { NavLink, useSearchParams } from "react-router-dom";
import { AiFillPlusSquare } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/hooks";
import NotesAnnonces from "../../../Admin/Administratif/AdministratifStep/NotesAnnonces";
import AjouterNoteModal from "../../../../modal/admin/AjouterNoteModal";
import AjouterAnnonceModal from "../../../../modal/admin/AjouterAnnonceModal";
import Factures from "./AdministratifStep/Factures";
import Quittance from "../../../Agence/Administratif/Administratiftabs/Quittance";
import { isProprioJuriste } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import AjoutEtatLieuxModalPQGSB from "../../../../modal/proprietaireQGSB/AjoutEtatLieuxModalPQGSB";

function AdministratifPQGSB() {
  const { user } = useAppSelector((s) => s?.user);
  // const userType = user?.user_type;
  const steps = [
    { id: "Avis d'échéance", Component: Factures },
    { id: "quittance", Component: Quittance },
    { id: "etat-des-lieux", Component: EtatDesLieux },
    { id: "contrat", Component: Contrat },
    // { id: "litige", Component: Litiges },
    // { id: "rappel", Component: RappelsPQGSB },
    // { id: "utilisateur", Component: Utilisateurs },
    { id: "notes-annonces", Component: NotesAnnonces },
  ];

  const tabs = [
    "Avis d'échéance",
    "Quittance",
    "Etat des lieux",
    "Contrat",
    // "Rappels",
    // "Utilisateurs",
    "Notes et Annonces",
  ];
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const [searchParams, setSearchParams] = useSearchParams();
  const props = { searchParams, setSearchParams };
  if (isProprioJuriste(user) && !user?.administratif) {
    return (
      <div className="dashboard-admin-component kanimmo-tabs-container container-form-add-locataire no-access-apge">
        <div className="row container-dashboard-page">
          <AlertInfo message="Vous n'avez pas accès au données de cette page !" />
        </div>
      </div>
    );
  }
  return (
    <div className="landing-page-container">
      <div className="container-fluid px-0">
        <div className="kanimmo-dash-locataire-requetes-header">
          <h4 className="kanimmo-dash-locataire-requetes-header-titre">
            Administratif
          </h4>
          {tabs.map((tab, key) => {
            // a_un_comptable

            if (
              index === key &&
              tab === "Utilisateurs" &&
              user?.a_un_comptable
            ) {
              return (
                <NavLink
                  to="/proprietaire/gerer-par-le-proprietaire/ajouter-utilisateur"
                  className="btn btn-see-more"
                >
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter un utilisateur</span>
                </NavLink>
              );
            }
            // if(index === key && tab === "Contrat") {
            //     return(
            //         <div className='container-btn-add'>
            //             <button
            //                 className='btn btn-see-more'
            //                 title="Ajouter une entrée"
            //                 data-bs-toggle="modal"
            //                 data-bs-target="#AjouterContratModal"
            //             >
            //                 <AiFillPlusSquare style={{ fontSize: 22 }} /><span className='ps-2'>Ajouter un contrat</span>
            //             </button>
            //             <div className="modal fade" id="AjouterContratModal" aria-labelledby="AjouterContratModalLabel" aria-hidden="true">
            //                 <AjouterContratModal  />
            //             </div>
            //         </div>
            //     )
            // }
          })}
          {index === 0 && !isProprioJuriste(user) && (
            <button
              className="btn btn-see-more"
              data-bs-toggle="modal"
              data-bs-target="#AjouterFactureModal"
            >
              <AiFillPlusSquare style={{ fontSize: 22 }} />
              <span className="ps-2">Générer</span>
            </button>
          )}
          {index === 2 && !isProprioJuriste(user) && (
            <button
              className="btn btn-see-more dropdown-toggle btn"
              type="button"
              data-bs-target="#AjouterEtatLieuModal"
              data-bs-toggle="modal"
            >
              <div className="user-acount-dashboard-header-display g-2">
                <AiFillPlusSquare style={{ fontSize: 22 }} />
                <span className="ps-2">Ajouter état des lieux</span>
              </div>
            </button>
          )}

          {index === 4 && !isProprioJuriste(user) && (
            <div className="dropdown dis-flex">
              <button
                className="btn btn-see-more dropdown-toggle btn"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-acount-dashboard-header-display g-2">
                  <AiFillPlusSquare style={{ fontSize: 22 }} />
                  <span className="ps-2">Ajouter</span>
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-custom"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="btn btn-see-more"
                  title="Note"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterNoteModal"
                >
                  <span className="ps-2">Note</span>
                </button>
                <button
                  className="btn btn-see-more"
                  title="Annonce"
                  data-bs-toggle="modal"
                  data-bs-target="#AjouterAnnonceModal"
                >
                  <span className="ps-2">Annonce</span>
                </button>
              </div>
              <div
                className="modal fade"
                id="AjouterNoteModal"
                aria-labelledby="AjouterNoteModalLabel"
                aria-hidden="true"
              >
                <AjouterNoteModal modalId="AjouterNoteModal" />
              </div>
              <div
                className="modal fade"
                id="AjouterAnnonceModal"
                aria-labelledby="AjouterAnnonceModalLabel"
                aria-hidden="true"
              >
                <AjouterAnnonceModal modalId="AjouterAnnonceModal" />
              </div>
            </div>
          )}
        </div>
        <div className="kanimmo-tabs-container py-3">
          <ul className="tabs-container custom-tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tabs-component-container">
            <Component {...props} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={"AjouterEtatLieuModal"}
        aria-labelledby="AjouterEtatLieuModalLabel"
        aria-hidden="true"
      >
        <AjoutEtatLieuxModalPQGSB modalId="AjouterEtatLieuModal" />
      </div>
    </div>
  );
}

export default AdministratifPQGSB;
