import React from 'react'
import { useSearchParams } from 'react-router-dom';
import OptionUsersTable from '../../../../tables/agence/OptionUsersTable/OptionUsersTable'

const Utilisateurs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
	const nom = searchParams.get("nom");
	
	const handleFilterByName = (e: React.FormEvent<HTMLInputElement>): void => {
		if (e.currentTarget.value) {
			setSearchParams({
				nom: e.currentTarget.value,
			});
		} else {
			setSearchParams({});
		}
	};
	
    return (
        <div className="bg-white p-3 filter-and-table-container">
			<div className="locataire-requete-filtre-container my-4">
				<form className="row g-3 locataire-requete-filtre-form">
					<div className="col-auto">
						<label
							htmlFor="filtreLogement"
							className="locataire-requete-filtre-label"
						>
							Filtrer
						</label>
					</div>
					<div className="col-auto">
						<input
							type="text"
							className="form-control locataire-requete-filtre-input"
							id="filtreLogement"
							placeholder="Entrer un nom"
							onChange={handleFilterByName}
						/>
					</div>
				</form>
			</div>
			<div>
                <OptionUsersTable nom={nom} />
			</div>
		</div>
    )
}

export default Utilisateurs