import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import {
  UserFormData,
  IUser,
  AbonnementFormData,
} from "../../../../../utils/api/user/user.type";
import {
  useAddOrEditUserMutation,
  useChangeOfferMutation,
  useMeQuery,
} from "../../../../../utils/api/user/user.api";
import { clean, cleannerError, onHide } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { ILocataire } from "../../../../../utils/api/locataire/locataire.type";
import { closeModal } from "../../../../../utils/Utils";

function UseEditUserFom(from: string) {
  const validationSchema = yup.object().shape({
    from: yup.string().nullable(),
    nom: yup
      .string()
      .when("from", {
        is: "account",
        then: yup.string().required().label("Nom"),
      })
      .nullable(),
    prenom: yup
      .string()
      .when("from", {
        is: "account",
        then: yup.string().required().label("Prénom"),
      })
      .nullable(),

    email: yup.string().email().required().label("Email").nullable(),
    telephone: yup
      .string()
      .when("from", {
        is: "account",
        then: yup.string().required().label("Téléphone"),
      })
      .nullable(),
    // username: yup
    //   .string()
    //   .when("from", {
    //     is: "account",
    //     then: yup.string().required().label("Nom d'utilisateur"),
    //   })
    //   .nullable(),
    nom_agence: yup
      .string()
      .when("from", {
        is: "personalize",
        then: yup.string().required().label("Nom de l'agence"),
      })
      .nullable(),
    adresse_agence: yup
      .string()
      .when("from", {
        is: "personalize",
        then: yup.string().required().nullable().label("Adresse de l'agence"),
      })
      .nullable(),
    adresse: yup.string().nullable().label("Adresse"),
    code_postal: yup.string().nullable().label("Code postale"),
    region: yup.string().nullable().label("Région"),
    pays: yup.string().nullable().label("Pays"),
    whatsapp: yup.string().nullable().label("Whatsapp"),
    site: yup
      .string()
      .when("from", {
        is: "personalize" || "proprio",
        then: yup.string().url().nullable().label("Site web"),
      })
      .nullable(),
    facebook: yup
      .string()
      .when("from", {
        is: "personalize" || "proprio",
        then: yup.string().url().nullable().label("Lien facebook"),
      })
      .nullable(),
    youtube: yup
      .string()
      .when("from", {
        is: "personalize" || "proprio",
        then: yup.string().url().nullable().label("Lien youtube"),
      })
      .nullable(),
    linkedin: yup
      .string()
      .when("from", {
        is: "personalize" || "proprio",
        then: yup.string().url().nullable().label("Lien linkedin"),
      })
      .nullable()
      .label("Lien linkedin"),
    twitter: yup
      .string()
      .when("from", {
        is: "personalize" || "proprio",
        then: yup.string().url().nullable().label("Lien twitter"),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useAppDispatch();

  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditUserMutation();
  let user = useAppSelector((s) => s?.user?.user);
  const [phone, setPhone] = useState<string>();
  const [phoneWhatsapp, setPhoneWhatsapp] = useState<string>();
  const [address, setAddress] = useState("");
  const [addressAgence, setAddressAgence] = useState("");

  const onChangeAddress = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  const onChangeAddressAgence = (val: {
    rue: string;
    latitude: string;
    longitude: string;
    ville: string;
    country: string;
  }) => {
    setAddressAgence(val?.rue);
    setValue("adresse_agence", val?.rue);
  };

  useEffect(() => {
    if (from === "account") {
      setValue("from", "account");
    }

    if (from === "personalize") {
      setValue("from", "personalize");
    }

    if (from === "proprio") {
      setValue("from", "proprio");
    }
    // console.log("from", from);
  }, [from]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(onSetUser(data));
      Swal.fire({
        icon: "success",
        title: "Informations modifiées avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status} est survenue`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (user?.id) {
      const fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "email",
        "telephone",
        "adresse",
        "region",
        "pays",
        "code_postal",
        "whatsapp",
        "username",
        "adresse_agence",
        "nom_agence",
        "facebook",
        "twitter",
        "youtube",
        "linkedin",
        "site",
        "description",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
      setAddressAgence(user?.adresse_agence);
      setAddress(user?.adresse);
      if (
        (user?.telephone?.substring(0, 2) === "77" ||
          user?.telephone?.substring(0, 2) === "76" ||
          user?.telephone?.substring(0, 2) === "78" ||
          user?.telephone?.substring(0, 2) === "70" ||
          user?.telephone?.substring(0, 2) === "75") &&
        !user?.telephone?.includes("221")
      ) {
        setPhone("221" + user?.telephone);
      } else {
        setPhone(user?.telephone);
      }

      if (user?.whatsapp) {
        if (
          (user?.whatsapp?.substring(0, 2) === "77" ||
            user?.whatsapp?.substring(0, 2) === "76" ||
            user?.whatsapp?.substring(0, 2) === "78" ||
            user?.whatsapp?.substring(0, 2) === "70" ||
            user?.whatsapp?.substring(0, 2) === "75") &&
          !user?.whatsapp?.includes("221")
        ) {
          setPhoneWhatsapp("221" + user?.whatsapp);
        } else {
          setPhoneWhatsapp(user?.whatsapp);
        }
      }
    }
  }, [user]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);
  const onSubmit = (data: any) => {
    // clean(data)
    // console.log("data", data);
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key] !== "null") {
        fd.append(key, data[key]);
      }
    }

    sendData({ slug: user?.slug, userType: user?.user_type, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    onChangeAddress,
    address,
    onChangeAddressAgence,
    addressAgence,
    phone,
    setPhone,
    phoneWhatsapp,
    setPhoneWhatsapp,
  };
}

export default UseEditUserFom;

export function UseActiveOrDesactive(item: ILocataire | IUser) {
  const dispatch = useAppDispatch();
  const [sendData] = useAddOrEditUserMutation();
  const { data, isSuccess } = useMeQuery();

  const fd = new FormData();
  let question = "";
  let notif = "";

  useEffect(() => {
    if (isSuccess) {
      // console.log("data",data)
      dispatch(onSetUser(data));
    }
  }, [isSuccess]);

  const onActive = async () => {
    // console.log(item);
    if (item?.app_notification == true) {
      fd.append("app_notification", "false");
      question = "Êtes-vous sûr de vouloir désactiver les notification ?";
      notif = "Notification desactivé avec succès !";
    } else {
      fd.append("app_notification", "true");
      question = "Êtes-vous sûr de vouloir activer les notification ? ";
      notif = "Notification activé avec succès ! ";
    }

    let propriete: any = item?.propriete
      ? item?.propriete?.id
        ? item?.propriete?.id
        : item?.propriete
      : null;

    await Swal.fire({
      title: question,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          userType: item?.user_type,
          data: {
            app_notification: !item?.app_notification,
            propriete: propriete,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log("der", result);
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: notif,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.showValidationMessage(
            err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.originalStatus} est survenue`
          );
        }
      }
    });
  };
  return onActive;
}

export function UseChangeOffer(user: any, offre: any) {
  let data = {
    user: user,
    offre: offre,
  };
  let fd = new FormData()
  fd.append("user", user);
  fd.append("offre",offre)
  const [sendData] = useChangeOfferMutation();
  const onChange = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir changer votre abonnement ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.bleu,
      confirmButtonColor: Color.bleu,
      preConfirm: () => {
        return sendData(fd);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      let err = result?.value?.error as any;
      if (result.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          console.log(result);
          Swal.fire({
            icon: "success",
            title: `Abonnement modifié avec succès!`,

            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            closeModal("changeOfferModal");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onChange;
}
