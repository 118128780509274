import React from "react";
import { IContrat } from "../../../utils/api/contrat/contrat.type";
import { ILocataire } from "../../../utils/api/locataire/locataire.type";
import { ApiBaseUrl } from "../../../utils/http";
import { get_url_extension } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import { BtnCloseModal } from "../../common/Button";
import { GoogleDocs } from "../agence/DocumentLitigeModal";

function ViewDocModal({ item }: { item?: any }) {
  // console.log("item",item)
  return (
    <div
      className="modal fade"
      // id="ViewDocModal"
      id={`ViewDocModal${item?.slug}`}
      aria-labelledby="ViewDocModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ViewDocModalLabel">
              Contrat de location
            </h5>
            <BtnCloseModal label="" />
          </div>
          {!item?.locataire?.contrat && (
            <div className="py-5">
            <AlertInfo message="Le fichier est indisponible!" />
            </div>
          )}
          {item?.locataire?.contrat &&
            (get_url_extension(item?.locataire?.contrat) === "pdf" ? (
              <iframe
                src={ApiBaseUrl + item?.locataire?.contrat}
                frameBorder="0"
                width={`100%`}
                height={600}
                allowFullScreen
                seamless
                title="doc"
              ></iframe>
            ) : (
              // <iframe
              //   src={`https://docs.google.com/gview?url=${
              //     ApiBaseUrl + item?.locataire?.contrat
              //   }&embedded=true`}
              //   frameBorder="0"
              //   width={`100%`}
              //   height={600}
              //   title="doc"
              // ></iframe>
              <GoogleDocs
                file={ApiBaseUrl + item?.locataire?.contrat}
                height={"600px"}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ViewDocModal;
