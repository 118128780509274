import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { useGetImmeublesByUserQuery } from "../../../utils/api/immeubles/immeubles.api";
import { IImmeubles } from "../../../utils/api/immeubles/immeubles.type";
import {
  IPropriete,
  TypePropriete,
} from "../../../utils/api/propriete/propriete.type";
import { IUser } from "../../../utils/api/user/user.type";
import {
  isAgence,
  isAgenceAgent,
  isProprietaireAgence,
  isProprietaireOwner,
  isProprioJuriste,
  onHide,
} from "../../../utils/Utils";
import { BtnSubmit } from "../../common/Button";
import ErrorMessage from "../../common/ErrorMessage";
import { SelectInput } from "../../common/SelectInput";
function getRoute(user: IUser, data: any) {
  // console.log("data data data", data);
  let route = "/";
  // if (data?.type_propriete !== "immeuble") {
  // }
  if (
    (isAgence(user) || isAgenceAgent(user)) &&
    data?.type_propriete !== "immeuble" &&
    data?.lier_a_immeuble == false
  ) {
    return (route = `/agence/ajouter-propriete`); // villa, appartement sans immeuble
  } else if (
    (isAgence(user) || isAgenceAgent(user)) &&
    data?.type_propriete === "appartement" &&
    data?.lier_a_immeuble == true
  ) {
    return (route = `/agence/ajouter-appartement`); // appartement lier à un immeubles
  } else if (
    (isAgence(user) || isAgenceAgent(user)) &&
    data?.type_propriete === "immeuble"
  ) {
    return (route = `/agence/ajouter-propriete-immeuble`); //immeuble
  }

  if (
    (isProprietaireOwner(user) || isProprioJuriste(user)) &&
    data?.type_propriete !== "immeuble" &&
    data?.lier_a_immeuble == false
  ) {
    return (route = `/proprietaire/gerer-par-le-proprietaire/ajouter-propriete`); // villa, appartement sans immeuble
  } else if (
    (isProprietaireOwner(user) || isProprioJuriste(user)) &&
    data?.type_propriete === "appartement" &&
    data?.lier_a_immeuble == true
  ) {
    return (route = `/proprietaire/gerer-par-le-proprietaire/ajouter-appartement`); // appartement lier à un immeubles
  } else if ((isProprietaireOwner(user) || isProprioJuriste(user)) && data?.type_propriete === "immeuble") {
    return (route = `/proprietaire/gerer-par-le-proprietaire/ajouter-immeuble`); //immeuble
  }

  if (
    isProprietaireAgence(user) &&
    data?.type_propriete !== "immeuble" &&
    data?.lier_a_immeuble == false
  ) {
    return (route = `/proprietes/gerer-par-agence/ajouter-propriete`); // villa, appartement sans immeuble
  } else if (
    isProprietaireAgence(user) &&
    data?.type_propriete === "appartement" &&
    data?.lier_a_immeuble == true
  ) {
    return (route = `/proprietes/gerer-par-agence/ajouter-appartement`); // appartement lier à un immeubles
  } else if (
    isProprietaireAgence(user) &&
    data?.type_propriete === "immeuble"
  ) {
    return (route = `/proprietes/gerer-par-agence/ajouter-immeuble`); //immeuble
  }

  return route;
}
const typesProprietesOptions = [
  {
    label: "Villa",
    value: TypePropriete.villa,
  },
  {
    label: "Appartement",
    value: TypePropriete.appartement,
  },
  {
    label: "Immeuble",
    value: TypePropriete.immeuble,
  },
];

function AjouterProprietesModal({
  modalId,
  proprieteItem,
}: {
  modalId: string;
  proprieteItem?: IPropriete;
}) {
  const { register, handleSubmit, formState, clearErrors, reset, setValue } =
    useForm();

  const navigate = useNavigate();
  const [immeublesList, setImmeublesList] = useState<
    { label: string; value: any }[] | undefined
  >();
  const [showImmeubles, setShowImmeubles] = useState(false);
  const [showLierOuNon, setShowLierOuNon] = useState(false);

  const { user } = useAppSelector((s) => s?.user);
  const { data: immeubles } = useGetImmeublesByUserQuery({
    slug: user?.slug,
  });

  useEffect(() => {
    setValue("lier_a_immeuble", false);
  }, []);

  // setLocataires(locataires);

  const toggleShowLierOuNon = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value === "appartement") {
      setShowLierOuNon(true);
    } else {
      setShowLierOuNon(false);
    }
  };
  const toggleShowImmeubles = (e: any) => {
    // setShowImmeubles(e.target.value);
    if (e.target.value) {
      if (e.target.value === "true") {
        setValue("lier_a_immeuble", true);
        setShowImmeubles(true);
      } else {
        setValue("lier_a_immeuble", false);
        setShowImmeubles(false);
      }
    }
  };

  useEffect(() => {
    if (immeubles) {
      const ListeImb = immeubles?.results?.map((item) => {
        return {
          label: item.nom,
          value: item.id,
        };
      });
      setImmeublesList(ListeImb);
    }
  }, [immeubles]);

  console.log({immeubles});
  

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  const onSubmit = async (data: any) => {
  // console.log("data", data);
    const immos = immeubles?.results?.find(
      (item) => item?.id === parseInt(data?.immeuble)
    );
  
    let route: string = await getRoute(user, data);
    if (route) {
      await navigate(route, {
        state: {
          type_propriete: data.type_propriete,
          lier_a_immeuble: data.lier_a_immeuble,
          immeuble: data.immeuble,
          immo: immos,
        },
      });
    }

    onHide(modalId);
    reset();
  };

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <SelectInput
                      label="Choisissez le type de propriété"
                      required
                      options={typesProprietesOptions}
                      {...register("type_propriete")}
                      // error={formState?.errors?.type_propriete}
                      onChange={toggleShowLierOuNon}
                    />
                  </div>
                </div>
                {showLierOuNon && (
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="form-group auth-form-group">
                        <label className="form-label form-label-login">
                          Cet appartement est-il lié à un immeuble ?
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="d-flex align-items-center">
                          <div className="checkbox">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="true"
                              onChange={(e) => toggleShowImmeubles(e)}
                              // {...register("lier_a_immeuble")}
                              id="flexCheckDefaultoui"
                              // onClick={toggleShowImmeubles}
                              name="appart_immeuble"
                            />
                            <label
                              className="form-check-label form-label-login ms-2"
                              htmlFor="flexCheckDefaultoui"
                            >
                              OUI
                            </label>
                          </div>
                          <div className="checkbox ms-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="false"
                              id="flexCheckDefaultnon"
                              onChange={(e) => toggleShowImmeubles(e)}
                              // {...register("lier_a_immeuble")}
                              // onClick={toggleShowImmeubles}
                              name="appart_immeuble"
                              defaultChecked
                            />
                            <label
                              className="form-check-label form-label-login ms-2"
                              htmlFor="flexCheckDefaultnon"
                            >
                              NON
                            </label>
                          </div>
                        </div>
                        {formState?.errors?.lier_a_immeuble && (
                          <ErrorMessage
                            message={
                              formState?.errors?.lier_a_immeuble?.message
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showImmeubles && (
                  <div className="col-md-12">
                    <SelectInput
                      label="Sélectionné l'immeuble"
                      // required
                      options={immeublesList}
                      {...register("immeuble")}
                      // error={formState?.errors.immeuble}
                    />
                  </div>
                )}
              </div>
              <div className="container-btn-modal row mt-5">
                <div className="col-md-3">
                  <button
                    className="btn btn-cancelled"
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-md-6 offset-md-3 flex-r">
                  {/* <NavLink to="/agence/ajouter-propriete" className="btn auth-submit-btn" onClick={() => onHide(modalId)}>Continuer</NavLink> */}
                  <BtnSubmit label={"Suivant"} isLoading={false} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjouterProprietesModal;
