import React, { useEffect, useState, useRef } from "react";
import { FiEdit } from "react-icons/fi";
import Facebook from "../../../../../assets/icons/facebook.png";
import Twitter from "../../../../../assets/icons/twitter.png";
import Linkedin from "../../../../../assets/icons/linkedin.png";
import Youtube from "../../../../../assets/icons/youtube.png";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import UseEditUserFom from "../requestForm/UseEditUserFom";
import {
  getAvatar,
  getImage,
  isProprietaireOwner,
  onHide,
} from "../../../../../utils/Utils";
import { FormError } from "../../../../common/CustomInputCheckbox";
import GoogleInput from "../../../../common/GoogleInput";
import { BtnSubmit } from "../../../../common/Button";
import ChangeLogoModal from "../ChangeLogoModal";
import { useAddOrEditUserMutation } from "../../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import ChangeBannerModal from "../ChangeBannerModal";
import SignatureUpload from "../AddSignatureModal";
import PhoneInput from "react-phone-input-2";
import { showModal } from "../../../../../utils/Utils";
import { useAddSignatureMutation } from "../../../../../utils/api/signature/signature.api";

function Personnalisation() {
  const user = useAppSelector((s) => s?.user?.user);
  const [avatar, setAvatar] = useState<any>(null);
  console.log("user connect", user);

  const {
    register,
    errors,
    onSubmit,
    onChangeAddressAgence,
    addressAgence,
    address,
    isLoading,
    setValue,
    phone,
    setPhone,
  } = UseEditUserFom(!isProprietaireOwner(user) ? "personalize" : "proprio");
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditUserMutation();
  const [uploadSignature] = useAddSignatureMutation(); // Modification ici
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (done) {
      // console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Image modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
      onHide("editLogoModal");
      onHide("editBannerModal");
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);
  return (
    <div className="kanimmo-dash-locataire-param-main-page-container py-3">
      {!isProprietaireOwner(user) && (
        <div className="container-img-option-agence position-relative mb-4">
          <div className="container-img-couverture-option-agence">
            <div className="content-img-couverture">
              <img
                src={getImage(user?.banniere)}
                alt="pp couverture"
                className="img-couverture-option-agence"
              />
            </div>
            <div className="content-btn-edit-img-couverture">
              <button
                className="btn-edit-img-option-agence"
                data-bs-toggle="modal"
                data-bs-target="#editBannerModal"
              >
                <FiEdit />
              </button>
            </div>
          </div>
          <div className="container-logo-option-agence">
            <div className="content-btn-edit-logo mb-2">
              <button
                className="btn-edit-img-option-agence"
                data-bs-toggle="modal"
                data-bs-target="#editLogoModal"
              >
                <FiEdit />
              </button>
            </div>
            <div className="content-logo">
              <img
                src={getAvatar(user?.logo)}
                alt="pp couverture"
                className="logo-agence-custome"
              />
            </div>
          </div>
        </div>
      )}

      <form onSubmit={onSubmit}>
        {!isProprietaireOwner(user) && (
          <>
            <div className="row my-5 agence-infos-mine-container">
              <div className="col-md-6 mb-3">
                <div className="">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-form-custom"
                  >
                    Nom de l’agence
                  </label>
                  <div className="input-group input-group-personnalisation">
                    <input
                      type="text"
                      className="form-control py-3"
                      placeholder="Nom de l’agence"
                      aria-label="Nom de l’agence"
                      aria-describedby="basic-addon2"
                      {...register("nom_agence")}
                    />
                  </div>
                  <FormError error={errors?.nom_agence?.message} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="">
                  <label
                    htmlFor="nom"
                    className="form-label form-label-form-custom"
                  >
                    Description
                  </label>
                  <textarea
                    className="form-control input-group-personnalisation"
                    rows={5}
                    {...register("description")}
                  />
                </div>
                <FormError error={errors?.description?.message} />
              </div>
            </div>
            <div className="container-conctact-option-agence mb-5">
              <div className="content-title-contact-option-agence">
                <h4 className="title-contact-option-agence">Contact</h4>
              </div>
              <div className="row my-5">
                <div className="col-md-6 mb-3">
                  <div className="">
                    <label
                      htmlFor="telephone"
                      className="form-label form-label-form-custom"
                      ref={ref}
                    >
                      Téléphone
                    </label>
                    <div className="">
                      {/* <input
                    type="text"
                    className="form-control py-3"
                    placeholder="Téléphone"
                    aria-label="Téléphone"
                    aria-describedby="basic-addon2"
                    {...register("telephone")}
                  /> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        containerClass="form-control auth-form-control mb-3 p-0"
                        inputClass="form-control form-control-modal-custom"
                        country={"sn"}
                        placeholder=""
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        countryCodeEditable={false}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country: any) => {
                          setPhone(phone);

                          if (
                            country?.format?.match(/\./g)?.length ===
                            phone?.length
                          ) {
                            setValue("telephone", phone);
                          }
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="">
                    <label
                      htmlFor="adresse"
                      className="form-label form-label-form-custom"
                    >
                      Adresse physique
                    </label>
                    <div className="input-group input-group-personnalisation">
                      <GoogleInput
                        className="form-control py-3"
                        // value={addressAgence}
                        value={address ? address : addressAgence}
                        onChange={onChangeAddressAgence}
                      />
                    </div>
                    <FormError error={errors?.adresse_agence?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="">
                    <label
                      htmlFor="siteWeb"
                      className="form-label form-label-form-custom"
                    >
                      Site web
                    </label>
                    <div className="input-group input-group-personnalisation">
                      <input
                        type="url"
                        className="form-control py-3"
                        placeholder="Site web"
                        aria-label="Site web"
                        aria-describedby="basic-addon2"
                        {...register("site")}
                      />
                    </div>
                    <FormError error={errors?.site?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="">
                    <label
                      htmlFor="mail"
                      className="form-label form-label-form-custom"
                    >
                      Adresse mail
                    </label>
                    <div className="input-group input-group-personnalisation">
                      <input
                        type="email"
                        className="form-control py-3"
                        placeholder="Adresse mail"
                        aria-label="Adresse mail"
                        aria-describedby="basic-addon2"
                        {...register("email")}
                      />
                    </div>
                    <FormError error={errors?.email?.message} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="container-conctact-option-agence">
          <div className="content-title-contact-option-agence">
            <h4 className="title-contact-option-agence">Réseaux sociaux</h4>
          </div>
          <div className="row my-5">
            <div className="col-md-6 mb-3">
              <div className="">
                <label
                  htmlFor="facebook"
                  className="form-label form-label-form-custom"
                >
                  Facebook
                </label>
                <div className="input-group input-group-personnalisation">
                  <span className="input-group-text input-group-text-img">
                    <img
                      src={Facebook}
                      alt="social media"
                      className="social-media-contact"
                    />
                  </span>
                  <input
                    type="url"
                    placeholder="Lien facebook"
                    className="form-control py-3"
                    aria-label="Amount (to the nearest dollar)"
                    {...register("facebook")}
                  />
                </div>
                <FormError error={errors?.facebook?.message} />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="">
                <label
                  htmlFor="twitter"
                  className="form-label form-label-form-custom"
                >
                  Twitter
                </label>
                <div className="input-group input-group-personnalisation">
                  <span className="input-group-text input-group-text-img">
                    <img
                      src={Twitter}
                      alt="social media"
                      className="social-media-contact"
                    />
                  </span>
                  <input
                    type="url"
                    placeholder="Lien twitter"
                    className="form-control py-3"
                    aria-label="Amount (to the nearest dollar)"
                    {...register("twitter")}
                  />
                </div>
                <FormError error={errors?.twitter?.message} />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="">
                <label
                  htmlFor="youtube"
                  className="form-label form-label-form-custom"
                >
                  Youtube
                </label>
                <div className="input-group input-group-personnalisation">
                  <span className="input-group-text input-group-text-img">
                    <img
                      src={Youtube}
                      alt="social media"
                      className="social-media-contact"
                    />
                  </span>
                  <input
                    type="url"
                    placeholder="Lien youtube"
                    className="form-control py-3"
                    aria-label="Amount (to the nearest dollar)"
                    {...register("youtube")}
                  />
                </div>
                <FormError error={errors?.youtube?.message} />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="">
                <label
                  htmlFor="linkedin"
                  className="form-label form-label-form-custom"
                >
                  Linkedin
                </label>
                <div className="input-group input-group-personnalisation">
                  <span className="input-group-text input-group-text-img">
                    <img
                      src={Linkedin}
                      alt="social media"
                      className="social-media-contact"
                    />
                  </span>
                  <input
                    type="url"
                    placeholder="Lien linkedin"
                    className="form-control py-3"
                    aria-label="Amount (to the nearest dollar)"
                    {...register("linkedin")}
                  />
                </div>
                <FormError error={errors?.linkedin?.message} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-btn-modal row my-4">
          <div className="col-md-12">
            <div className="d-flex gap-3 justify-content-end">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              {/* <button className="btn auth-submit-btn" type="submit">
                      Enregistrer
                    </button> */}
            </div>
          </div>
        </div>
      </form>
      <ChangeLogoModal sendData={sendData} isLoading={load} />
      <ChangeBannerModal sendData={sendData} isLoading={load} />
      <h4 className="title-contact-option-agence">
        Veuiller uploader votre signature{" "}
      </h4>
      <div>
        <button className="btn btn-see-more">
          <SignatureUpload userSlug={user.slug} />
          <span className="ps-2">Signature</span>
        </button>
      </div>
    </div>
  );
}

export default Personnalisation;
